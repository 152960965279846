<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        <span v-if="!is_show_new_work_flow">
          Work flows
        </span>
        <span v-if="is_show_new_work_flow">
          Create New work flow
        </span>
      </div>
    </div>
    <div class="modal__body">
      <div class="new-accordion-wrap" v-if="is_show_new_work_flow">
        <div class="new-accordion-header">
          <label class="new-accordion-label">
            Work flow name
          </label>
          <div class="new-accordion-input-wrap">
            <input type="text" name="new_work_flow_name" class="inpt new-accordion-input" v-model="new_work_flow.name" placeholder="Enter a name">
          </div>
        </div>
        <div class="new-accordion-body">
          <div class="new-accordion-body-header">
            <h4>
              Work flow steps
            </h4>
            <div class="new-accordion-body-btn" @click="addStep(new_work_flow)">
              Add step
            </div>
          </div>
          <draggable tag="ul" :list="new_work_flow.steps" class="new-accordion-steps list-group" handle=".handle">
            <li
              class="new-accordion-step list-group-item"
              v-for="(step, idx) in new_work_flow.steps"
              :key="step.sequence"
              :style="{ 'border-color': step.color }"
            >
              <div class="step-row">
                <div class="step-drag handle">
                  <AlignJustify class="img" />
                </div>
                <div class="step-body">
                  <div class="step-input-wrap">
                    <label>
                      Step name
                    </label>
                    <input type="text" class="inpt" v-model="step.name" />
                  </div>
                  <div class="step-input-wrap">
                    <label>
                      Step description
                    </label>
                    <textarea class="inpt" v-model="step.description" /></textarea>
                  </div>
                  <div class="step-input-wrap double">
                    <div>
                      <label>
                        Color
                      </label>
                      <input type="color" class="inpt" v-model="step.color" />
                    </div>
                    <div>
                      <label>
                        Manager
                      </label>
                      <div>
                        <select name="manager_name" class="inpt" v-model="step.manager_name">
                          <option :value="null">Select manager</option>
                          <option 
                            v-for="(manager, i) in managers" 
                            :value="manager.name"
                          >
                            {{ manager.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="step-delete" @click="removeStep(step, idx, new_work_flow)">
                  <span>
                    <Close class="img" />
                  </span>
                </div>
              </div>
            </li>
          </draggable>
        </div>
      </div>
      <div class="accordion-wrap" v-for="(flow, index) in work_flows" v-if="!is_show_new_work_flow">
        <div class="accordion-header">
          <h4 class="accordion-title">
            {{ flow.name }}
          </h4>
          <span class="accordion-btns">
            <div class="accordion-delete-btn"  @click="deleteWorkFlow(index)">
              <span>
                <Close class="img" />
              </span>
            </div>
            <span class="accordion-toggle-btn" @click="openWorkFlow(index)">
              <span v-if="opened_flow == index">
                -
              </span>
              <span v-else>
                +
              </span>
            </span>
          </span>
        </div>
        <div class="accordion-body" :class="{'opened': opened_flow == index}">
          <div class="accordion-body-header">
            <div class="accordion-body-header-input-wrap">
              <label class="new-accordion-label">
                Work flow name
              </label>
              <div class="new-accordion-input-wrap">
                <input type="text" name="flow_name" class="inpt new-accordion-input" v-model="flow.name" placeholder="Enter a name">
              </div>
            </div>
          </div>
          <div class="accordion-body-header">
            <h4>
              Work flow steps
            </h4>
            <div class="new-accordion-body-btn" @click="addStep(flow)">
              Add step
            </div>
          </div>
          <draggable tag="ul" :list="flow.steps" class="new-accordion-steps list-group" handle=".handle">
            <li
              class="new-accordion-step list-group-item"
              v-for="(step, idx) in flow.steps"
              :key="step.sequence"
              :style="{ 'border-color': step.color }"
            >
              <div class="step-row">
                <div class="step-drag handle">
                  <AlignJustify class="img" />
                </div>
                <div class="step-body">
                  <div class="step-input-wrap">
                    <label>
                      Step name
                    </label>
                    <input type="text" class="inpt" v-model="step.name" />
                  </div>
                  <div class="step-input-wrap">
                    <label>
                      Step description
                    </label>
                    <textarea class="inpt" v-model="step.description" /></textarea>
                  </div>
                  <div class="step-input-wrap double">
                    <div>
                      <label>
                        Color
                      </label>
                      <input type="color" class="inpt" v-model="step.color" />
                    </div>
                    <div>
                      <label>
                        Manager
                      </label>
                      <div>
                        <select name="manager_name" class="inpt" v-model="step.manager_name">
                          <option :value="null">Select manager</option>
                          <option 
                            v-for="(manager, i) in managers" 
                            :value="manager.name"
                          >
                            {{ manager.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="step-delete"  @click="removeStep(step, idx, flow)">
                  <span>
                    <Close class="img" />
                  </span>
                </div>
              </div>
            </li>
          </draggable>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn">
        <span v-if="opened_flow != null" @click="saveWorkFlow(null)">
          Save work flow
        </span>
        <span v-else-if="opened_flow == null && !is_show_new_work_flow" @click="addWorkFlow">
          Add work flow
        </span>
        <span v-else-if="opened_flow == null && is_show_new_work_flow" @click="saveWorkFlow(new_work_flow)">
          Save new work flow
        </span>
      </div>
      <span class="back-btn" v-if="!opened_flow && is_show_new_work_flow" @click="is_show_new_work_flow = false">
        Show all work flows
      </span>
    </div>
  </div>
</template>

<script>
import Close from '../img/Close.vue'
import AlignJustify from '../img/AlignJustify.vue'
import BlueFile from '../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from '../img/BlueDownload.vue'
import Pen from '@/components/img/Pen.vue'

export default {
  components: {
    Pen,
    Close,
    BlueDownload,
    BlueFile,
    draggable,
    AlignJustify
  },
  data(){
    return{
      managers: [],
      work_flows: [],
      is_show_new_work_flow: false,
      new_work_flow: {
        name: '',
        steps: [],
      },
      opened_flow: null,
    }
  },
  async mounted () {
    await this.getWorkFlows()
    await this.getManagers()
  },
  methods: {
    openWorkFlow(index){
      if(this.opened_flow == index){
        this.opened_flow = null
      }
      else{
        this.opened_flow = index
      }
    },
    addWorkFlow(){
      this.is_show_new_work_flow = true
    },
    addStep(flow){
      let sequence = flow.steps.length + 1

      flow.steps.push({
        name: '',
        description: '',
        manager_name: '',
        color: '',
        sequence: sequence,
        upload: {
          documents: [{name: null}]
        }
      })
    },
    async removeStep(step, index, flow){
      if(step.hasOwnProperty('id')){
        await axios.delete('admin/steps/' + step.id)
        .then(function (response) {
          if(response.data.success){
            that.$noty.success('Step was successfully deleted.')
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }

      flow.steps.splice(index, 1)
    },
    async deleteWorkFlow(index){
      if(this.work_flows[index].hasOwnProperty('id')){
        let that = this
        
        await axios.delete('admin/work-flows/' + this.work_flows[index].id)
        .then(function (response) {
          if(response.data.success){
            that.$noty.success('Work flow was successfully deleted.')

            that.work_flows.splice(index, 1)
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    async getWorkFlows(){
      const that = this

      await axios.get('admin/work-flows')
      .then(function (response) {
        that.work_flows = response.data
      })
      .catch(function (error) {
        console.log(error);
      })
	  },
    async getManagers () {
      const that = this
      await axios.get('admin/data/managers')
        .then(function (response) {
          that.managers = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
	  },
    async saveWorkFlow(flow = null){
      if(!flow){
        flow = this.work_flows[this.opened_flow]
        console.log(flow);
      }

      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/work-flows'

      if (flow.hasOwnProperty('id')) {
        url += '/' + flow.id
      }

      let that = this

      await axios.post(url, flow)
      .then((response) => {
        that.$noty.success('Work flow was successfully processed.')

        that.opened_flow = null
        that.is_show_new_work_flow = false
        that.new_work_flow = {
          name: '',
          steps: [],
        }

        that.getWorkFlows()
        that.getManagers()
      })
      .catch((errors) => {
        console.dir(errors)
      })
    },
    close() {
      this.$emit("close");
    },
  }
}
</script>

<style lang="scss" scoped>
.accordion{
  &-wrap{
    width: 100%;
    margin-bottom: 20px;
  }
  &-title{
    flex-grow: 1;
  }
  &-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-btns{
    display: flex;
  }
  &-delete-btn{
    width: 14px;
    height: 14px;
    margin-right: 10px;
    ::v-deep svg.img path {
      fill: red;
    }
  }
  &-toggle-btn{
    width: 24px;
    height: 24px;
    border: 1px solid #1763fb;
    border-radius: 50%;
    color: #1763fb;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-body{
    display: none;
    &.opened{
      display: block;
    }
    .inpt{
      width: 100%;
    }
  }
  &-body{
    margin-top: 20px;
    &-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      &-input-wrap{
        width: 100%;
        input{
          width: 100%;
        }
      }
    }
    &-btn{
      border: 1px solid #1763fb;
      background: #1763fb;
      border-radius: 6px;
      padding: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
  }
  &-label{
    margin-top: 12px;
    display: block;
  }
}
.new-accordion{
  &-wrap{
    width: 100%;
    .inpt{
      width: 100%;
    }
  }
  &-body{
    margin-top: 20px;
    &-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    &-btn{
      border: 1px solid #1763fb;
      background: #1763fb;
      border-radius: 6px;
      padding: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
  }
  &-label{
    margin-top: 12px;
    display: block;
  }
}
.new-accordion, .accordion{
  &-step{
    border: 1px dashed #1763fb;
    border-bottom: 0;
    padding: 8px;
    background: #fff;
    &:last-child{
      border-bottom: 1px dashed #1763fb;
    }
    .step-row{
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      max-width: 100%;
      flex: 0 0 100%;
    }
    .step-drag, .step-delete{
      width: 32px;
      max-width: 32px;
      padding: 8px;
    }
    .step-body{
      flex-grow: 1;
    }
    .step-drag{
      padding-left: 0;
      svg.img{
        fill: #1763fb !important;
        path{
          fill: #1763fb !important;
        }
      }
    }
    .step-delete{
      padding-right: 0;
      ::v-deep svg.img path {
        fill: red;
      }
    }
    .step-input-wrap{
      margin-bottom: 8px;
      &.double{
        display: flex;
        gap: 8px;
        &>*{
          flex: 0 0 calc(50% - 4px);
        }
      }
    }
  }
}
.modal {
  &__wrapper {
    width: 40rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
        &.link{
          text-decoration: underline;
        }
      }
      .file-data{
        flex-grow: 1;
        .inpt {
          width: 100%;
          color: #000000;
        }
      }
      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
        .btn {
          padding: 0.5rem;
          background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
          border-radius: 6px;
          color: #fff;
          font-size: 13px;
        }
        .delete-btn{
          margin-left: auto;
          width: 24px;
          height: 24px;
          background-color: #B03636;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg{
            fill: #fff;
            max-width: 16px;
            max-height: 16px;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #333333;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
  &__footer {
    padding: 1rem 1.5rem 1.5rem;
    flex-wrap: wrap;
    .btn{
      margin: 0;
      padding: .5rem 1.5rem;
    }
  }
  textarea{
    height: 5rem;
  }
}
.gap-12{
  gap: 12px;
}
input::placeholder {
  color: #090900;
  opacity: 1;
}

input::-ms-input-placeholder {
  color: #090900;
}
.inpt{
  color: #000;
}
.back-btn{
  display: block;
  width: 100%;
  margin-top: 8px;
  text-align: center;
}
</style>
