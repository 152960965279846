<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">Task comments</div>
    </div>
    <div class="modal__body">
      <div class="row info">
        <div class="label">
          #
        </div>
        <div class="data">
          Comment
        </div>
      </div>
      <div class="row" v-for="(comment, i) in form.task_comments" v-if="form.task_comments.length" style="width:100%; margin: 0; padding: 0">
        <div class="row info" v-if="comment.hasOwnProperty('id')" style="margin: 0; padding: 0">
          <div class="label">
            Comment # {{ i + 1 }}
            <br />
            {{ getFormattedDate(comment.created_at) }}  
            <br />
            <div class="delete-btn" @click="deleteTaskComment(i)">
              <Close class="img"/>
            </div>
          </div>
          <div class="data" :style="{ whiteSpace: 'pre-line' }">
            {{ comment.comment }}
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="label">
              Comment № {{ i + 1 }}
            </div>
            <div class="data">
              <div class="delete-btn" @click="deleteTaskComment(i)">
                <Close class="img"/>
              </div>
            </div>
          </div>
          <div class="row gap-12">
            <div class="col-12">
              <textarea class="inpt" name="service_name" v-model="comment.comment" placeholder="Comment"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="data">
            <span class="btn" @click="addTaskComment">
              Add task comment
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save">Save</div>
    </div>
  </div>
</template>

<script>
import Close from '../img/Close.vue'
import Tmblr from '../elem/Tmblr.vue'
import BlueFile from '../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from '../img/BlueDownload.vue'

export default {
  components: {
    Close,
    Tmblr,
    BlueDownload,
    BlueFile,
    draggable,
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data(){
    return{
      form: {
        task: null,
        client_name: null,
        tasked_at: null,
        task_description: null,
        status: null,
        is_manager_can: false,
        responsible_manager_name: null,
        control_manager_name: null,
        asystent_name: null,
        deadline_at: null,
        task_links: [],
        task_links_to_delete: [],
        responsible_managers: [],
        control_managers: [],
        task_comments: [],
      },
      error: null,
    }
  },
  async created () {
    await this.getTask()
  },
  methods: {
    addTaskComment(){
      this.form.task_comments.push({
        comment: null,
      })
    },
    deleteTaskComment(index){
      if(this.form.task_comments[index].hasOwnProperty('id')){
        this.form.task_comments_to_delete.push(this.form.task_comments[index])
      }

      this.form.task_comments.splice(index, 1)
    },
    async getTask(){
      const that = this
      await axios.get('admin/tasks/' + this.options.id)
      .then(function (response) {
        that.form = response.data
        that.form.task_comments_to_delete = []
        console.log(that.form.task_comments)
      })
      .catch(function (error) {
        console.log(error);
      })
	  },
    getFormattedDate(value){
      if(value){
        const date = new Date(value);
        
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });
        
        return formattedDate;
      }
    },
    async save(){
      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/tasks/' + this.options.id + '/comments'
      
      await axios.post(url, this.form)
        .then((response) => {
          this.close();
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .col-12{
    width: 100%;
    textarea{
      width: 100%;
      color: #000;
    }
  }
  &__wrapper {
    width: 40rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
        max-width: 160px;
        .delete-btn{
          width: 24px;
          height: 24px;
          background-color: #B03636;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg{
            fill: #fff;
            max-width: 16px;
            max-height: 16px;
          }
        }
      }

      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
        .btn {
          padding: 0.5rem;
          background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
          border-radius: 6px;
          color: #fff;
          font-size: 13px;
        }
        .delete-btn{
          margin-left: auto;
          width: 24px;
          height: 24px;
          background-color: #B03636;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg{
            fill: #fff;
            max-width: 16px;
            max-height: 16px;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          width: 30%;
          //width: calc(50% - .75rem);
          //margin: .75rem 0;
          //padding: 1rem 1.75rem;
          //background: #22252B;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #333333;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
  textarea{
    height: 5rem;
  }
}
.gap-12{
  gap: 12px;
}
.row.info{
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  border-bottom: 1px dashed #ababb2;
  margin-top: 0 !important;
  .label{
    font-weight: 700;
    padding: 12px 12px 12px 0;
    max-width: 35%;
    flex: 0 0 35%;
    border-right: 1px dashed #ababb2;
  }
  .data{
    display: flex;
    max-width: 65%;
    flex: 0 0 65%;
    padding: 12px 0 12px 12px;
  }
}
</style>
