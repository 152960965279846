<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="sbttl">
        Delete {{ this.options.type }}
      </div>
    </div>
    <div class="modal__body" style="flex-direction: column">
        Are you sure you want to delete the selected {{ this.options.type }}?
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Yes, delete</div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      message: '',
      error: ''
    };
  },
  methods: {
    async submit() {
      if (this.options.table === 'software-offers') {
        await axios.get(`admin/software-offers/delete/${this.options.id}`)
          .then(res => {
            this.$store.dispatch('software_net/getOffers')
            this.close()
          })
      } else if (this.options.table === 'software-options') {
        await axios.get(`admin/software-options/delete/${this.options.id}`)
          .then(res => {
            this.$store.dispatch('software_net/getOptions')
            this.close()
          })
      } else if (this.options.table === 'software-add-services') {
        await axios.get(`admin/software-add-services/delete/${this.options.id}`)
          .then(res => {
            this.$store.dispatch('software_net/getAddServices')
            this.close()
          })
      } else if (this.options.table === 'legal-providers') {
        await axios.post(`admin/legal-provider/delete/${this.options.id}`)
          .then(res => {
            this.$store.dispatch('legal_providers/getLegalProviders')
            this.close()
          })
      } else {
        const that = this
        axios.delete(this.options.url, {
            message: this.message,
          })
          .then(function (response) {
            if (response.data.success) {
              that.close()
              that.$noty.success(that.options.successfullMessage)
              if (that.options.table === 'business-offers') {
                that.$store.dispatch('bos_business/getOffers')
              } else if (that.options.table === 'software-offers') {
                that.$store.dispatch('software_net/getOffers')
              } else if (that.options.table === 'software-options') {
                that.$store.dispatch('software_net/getOptions')
              } else if (that.options.table === 'software-add-services') {
                that.$store.dispatch('software_net/getAddServices')
              } else if (that.options.table === 'payment-atlases') {
                that.$store.dispatch('payment_atlas/getPaymentAtlases')
              } else {
                that.$store.dispatch('deleteItemFromTable', {
                  id: that.options.id,
                  table: that.options.table,
                  actionType: that.options.actionType,
                })
              }
            }
          })
          .catch((errors) => {
            console.dir(errors)
          })
      }
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>

.modal__wrapper {
  height: 25rem;
}

.invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.form-control {
    width: 100%;
    height: 100%;
    color: #ffffff;
    background-color: #1C2126;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin: auto 0rem;
    border: 1px solid #2F363D;
    border-radius: 0.75rem;
    box-shadow: 0px 11px 23px rgb(0 0 0 / 2%);
    outline: none;
    -webkit-appearance: none;
}

.modal__footer {
  .btn {
    font-size: 18px;
    padding: 1rem 3rem;
    margin: 0.25rem 1rem;
    background: linear-gradient(113.24deg, #1763fb 16.01%, #1763fb 106.71%);
    box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
    border-radius: 6px;
  }
}
</style>
