import { render, staticRenderFns } from "./CustomerReferrals.vue?vue&type=template&id=a30845e6&scoped=true&"
import script from "./CustomerReferrals.vue?vue&type=script&lang=js&"
export * from "./CustomerReferrals.vue?vue&type=script&lang=js&"
import style0 from "./CustomerReferrals.vue?vue&type=style&index=0&id=a30845e6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a30845e6",
  null
  
)

export default component.exports