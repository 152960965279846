<template>
  <overlay-scrollbars class="page page-offer">
    <div class="box box_selectedAcc">
      <div class="header">
      </div>
      <div class="content">
        <div class="block" ref="bankOffers">
          <div class="hdr">
            <div class="hdr__l">
              <Bank2 class="img" /><div class="ttl">Bank account</div>
              <div @click="createBankAccount" class="btn create-account-btn">Create</div>
            </div>
            <div class="hdr__r">
              <div class="top-left-actions-btns">
                <div
                  class="btn" :class="{ 'btn--active': showProvidersAccs }"
                  @click="toggleAccs"
                >
                  Providers
                </div>
                <div v-if="user.is_super_admin">
                  <select v-model="selectedRisk" class="btn select-risk">
                    <option v-for="(filterRisk, index) in filterRisks" :value="filterRisk.id" :key="filterRisk.id">{{ filterRisk.name }}</option>
                  </select>
                </div>
                <div
                  class="btn excel-btn" @click="downloadExcel('bank')"
                >
                  Download to Excel
                </div>
              </div>
            </div>
          </div>
          <Table
            class="bank-offer"
            :options="bankAcc" :key="bankAccKey"
            @editItem="editBankOffer"
            @deleteItem="deleteBankOffer"
            @change-page="getBankOffers"
            @scroll-top="toBankOffersTop"
            @sort="sortBy"
          />
        </div>
        <div class="block" ref="merchantOffers">
          <div class="hdr">
            <div class="hdr__l">
              <CreditCards class="img" /><div class="ttl">Merchant account</div>
              <div class="btn create-account-btn" @click="createMerchantAccount">Create</div>
            </div>
            <div class="hdr__r">
              <!-- <div
                class="btn" :class="{ 'btn--active': showMerchantAcc }"
                @click="toggleMerchantAcc"
              >
                Providers
              </div> -->
              <div
                  class="btn excel-btn" @click="downloadExcel('merchant')"
                >
                  Download to Excel
                </div>
            </div>
          </div>
          <Table
            class="merchant-offer"
            :options="merchantAcc" :key="merchantAccKey"
            @editItem="editMerchantOffer"
            @deleteItem="deleteMerchantOffer"
            @change-page="getMerchantOffers"
            @scroll-top="toMerchantOffersTop"
            @sort="sortBy"
          />
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Info from './../components/elem/Info.vue'
import Compliance from './../components/elem/Compliance.vue'
import Table from './../components/elem/Table.vue'
import Bank2 from './../components/img/Bank2.vue'
import CreditCards from './../components/img/CreditCards.vue'
import Pagination from 'laravel-vue-pagination'
import { mapState } from 'vuex';

export default {
  components: {
    Info,
    Compliance,
    Table,
    Bank2,
    CreditCards,
    Pagination,
  },
  data: () => ({
    bankAccKey: 0,
    showProvidersAccs: false,
    filterRisks: [],
    selectedRisk: null,
    // showProvidersBankAcc: false,
    bankAcc: {
      objName: 'bankAcc',
      colsWidth: ['3%', '10%', '10%', '11%', '12%', '15%', '15%', '12%', '10%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Name', info: null, sort: 'type_of_license' },
        { ttl: 'Type of Account', info: null, sort: 'account_type' },
        { ttl: 'Open/Integration fee', info: null, sort: 'open_integration_fee' },
        { ttl: 'Incoming fee', info: null, sort: 'incoming_fee' },
        { ttl: 'Outgoing fee', info: null, sort: 'outgoing_fee' },
        { ttl: 'Onboarding time', info: null, sort: 'onboarding_time' },
        { ttl: '', info: null },
      ],
      body: [],
      paginationData: null,
      sort: {
        name: '',
        order: 'asc',
        loading: false
      }
    },
    merchantAccKey: 0,
    // showMerchantAcc: false,
    merchantAcc: {
      objName: 'merchantAcc',
      colsWidth: ['3%', '10%', '10%', '11%', '12%', '15%', '15%', '12%', '10%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Name', info: null, sort: 'type_of_license' },
        { ttl: 'Type of Zone', info: null, sort: 'account_type' },
        { ttl: 'Open/Integration fee', info: null, sort: 'open_integration_fee' },
        { ttl: 'Incoming fee', info: null, sort: 'incoming_fee' },
        { ttl: 'Outgoing fee', info: null, sort: 'outgoing_fee' },
        { ttl: 'Onboarding time', info: null, sort: 'onboarding_time' },
        { ttl: '', info: null },
      ],
      body: [],
      paginationData: null,
      sort: {
        name: '',
        order: 'asc',
        loading: false
      },
    },
  }),
  created () {
    this.$store.dispatch('setUser')
    this.getBankOffers()
    this.getMerchantOffers()
    this.getRisks()
    this.risk = (this.$store.state.user.risk) ? this.$store.state.user.risk : '';
    this.paid = (this.$store.state.user.is_paid == 1) ? 'Paid' : 'Unpaid';
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
  methods: {
    toBankOffersTop () {
      //      this.$refs.bankOffers.scroll({ el: el, margin: 10 }, 1500)
    },
    toMerchantOffersTop () {
      //this.$refs.merchantOffers.scroll({ el: el, margin: 10 }, 1500)
    },
    getFinName (el) {
      return !el.provider_id && !el.is_legal_company
        ? el.financial_institution_name
        : {
            type: 'info',
            html: el.financial_institution_name,
            info: {
              pos: 'tr',
              txt: 'Provided by Legal company'
            },
          }
    },
    getOif (el) {
      return el.oif_min_max && el.open_integration_fee_max
        ? this.number_format(el.open_integration_fee, 2, '.', ' ') +' - '+ this.number_format(el.open_integration_fee_max, 2, '.', ' ') +' €'
        : this.number_format(el.open_integration_fee, 2, '.', ' ') +' €'
    },
    toggleAccs () {
      this.showProvidersAccs = !this.showProvidersAccs
      this.getBankOffers(1)
      this.getMerchantOffers(1)
    },
    // toggleBankAcc () {
    //   this.showProvidersBankAcc = !this.showProvidersBankAcc
    //   this.getBankOffers(1)
    // },
    getBankOffers (page = 1) {
      const that = this
      const basePath = 'admin/offers/bank-accounts?page=' + page
      const selectedRiskParam = this.selectedRisk ? this.selectedRisk : ''
      const sortParams = '&sort=' + this.bankAcc.sort.name + '&order=' + this.bankAcc.sort.order + '&providers=' + this.showProvidersAccs + '&risk=' + selectedRiskParam
      axios.get(basePath + sortParams)
        .then(function (response) {
          that.bankAcc.paginationData = response.data
          that.bankAcc.body = []
          that.bankAcc.paginationData.data.forEach(el => {
            that.bankAcc.body.push([
              el.serial_number, that.getFinName(el), el.account_type,
              that.getOif(el),
              el.incoming_fee, el.outgoing_fee, el.onboarding_time_text, { type: 'edit-delete-actions', id: el.id}
            ])
          })
          if (that.bankAcc.sort.name) that.bankAcc.sort.loading = false
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    // toggleMerchantAcc () {
    //   this.showMerchantAcc = !this.showMerchantAcc
    //   this.getMerchantOffers(1)
    // },
    getMerchantOffers (page = 1) {
      const that = this
      const basePath = 'admin/offers/merchant-accounts?page=' + page
      const selectedRiskParam = this.selectedRisk ? this.selectedRisk : ''
      const sortParams = '&sort=' + this.merchantAcc.sort.name + '&order=' + this.merchantAcc.sort.order + '&providers=' + this.showProvidersAccs + '&risk=' + selectedRiskParam
      axios.get(basePath + sortParams)
        .then(function (response) {
          that.merchantAcc.paginationData = response.data
          that.merchantAcc.body = []
          that.merchantAcc.paginationData.data.forEach(el => {
            that.merchantAcc.body.push([
              el.serial_number, that.getFinName(el), el.account_type, that.getOif(el), el.incoming_fee, el.outgoing_fee, el.onboarding_time_text, { type: 'edit-delete-actions', id: el.id}
            ])
          })
          if (that.merchantAcc.sort.name) that.merchantAcc.sort.loading = false
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    createBankAccount () {
      this.$store.commit('setModal', {
        template: 'offer',
        options: {
          id: null,
          actionType: 'create',
          table: 'bank-offer',
          objectType: 'bank account',
          url: 'admin/offers',
          successfullMessage: 'You successfully created bank account',
        }
      })
    },
    createMerchantAccount () {
      this.$store.commit('setModal', {
        template: 'offer',
        options: {
          id: null,
          actionType: 'create',
          table: 'merchant-offer',
          objectType: 'merchant account',
          url: 'admin/offers',
          successfullMessage: 'You successfully created merchant account',
        }
      })
    },
    editBankOffer (obj) {
      this.$store.commit('setModal', {
        template: 'offer',
        options: {
          id: obj.id,
          actionType: 'edit',
          table: 'bank-offer',
          objectType: 'bank account',
          url: 'admin/offers/update/' + obj.id,
          successfullMessage: 'You successfully updated bank account',
        }
      })
    },
    deleteBankOffer (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'bank-offer',
          type: 'offer',
          url: 'admin/offers/' + obj.id,
          successfullMessage: 'You successfully deleted offer',
        }
      })
    },
    editMerchantOffer (obj) {
      this.$store.commit('setModal', {
        template: 'offer',
        options: {
          id: obj.id,
          actionType: 'edit',
          table: 'merchant-offer',
          objectType: 'merchant account',
          url: 'admin/offers/update/' + obj.id,
          successfullMessage: 'You successfully updated merchant account',
        }
      })
    },
    deleteMerchantOffer (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'merchant-offer',
          type: 'merchant',
          url: 'admin/offers/' + obj.id,
          successfullMessage: 'You successfully deleted offer',
        }
      })
    },
    updateCurrentPage(tableName, actionType) {
      if (tableName == 'bank-offer' || tableName == 'merchant-offer') {
          let currentPage = 1
          if (actionType == 'edit' || actionType == 'delete') {
            currentPage = document.getElementsByClassName(tableName)[0]
            .getElementsByClassName('pagination')[0]
            .getElementsByClassName('active')[0]
            .getElementsByClassName('page-link')[0]
            .innerText.trim().replace( /\D+/g, '')
          }
          if (tableName == 'bank-offer') {
            this.getBankOffers(currentPage)
          } else if (tableName == 'merchant-offer') {
            this.getMerchantOffers(currentPage)
          }
      }
    },
    sortBy (data) {
      this[data.obj].sort.loading = true
      this[data.obj].sort.order = this[data.obj].sort.name === data.sort && this[data.obj].sort.order === 'asc'
        ? 'desc' : 'asc'
      this[data.obj].sort.name = data.sort

      switch (data.obj) {
        case 'bankAcc':
          this.getBankOffers(1)
          break;
        case 'merchantAcc':
          this.getMerchantOffers(1)
          break;
      }
    }, 
    downloadExcel (type) {
      axios.post('admin/offers/create-excel-report/' + type + '/' + this.showProvidersAccs)
        .then(function (response) {
          const url = response.data
          const link = document.createElement('a')
          link.href = url
          document.body.appendChild(link)
          link.click()
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getRisks () {
      const that = this
      axios.get('admin/data/risks')
        .then(function (response) {
          response.data.sort((a, b) => a.order < b.order ? -1 : (a.order > b.order ? 1 : 0))
          that.filterRisks = response.data
          that.filterRisks.unshift({ id: null, name: 'Select risk'})
        })
        .catch(function (error) {
          console.log(error);
        })
    },
  },
  watch: {
    '$store.state.deleteItemFromTableData': function(data) {
        this.updateCurrentPage(data.table, data.actionType)
    },
    '$store.state.editItemFromTableData': function(data) {
        this.updateCurrentPage(data.table, data.actionType)
    },
    selectedRisk: function(selectedRisk) {
      this.getBankOffers()
      this.getMerchantOffers()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-offer {
  .box {
    &_selectedAcc {
      .content {
        padding: 0 0 1rem;

        .block {
          margin-bottom: 1.5rem;

          .hdr {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: .75rem;
            margin-bottom: .5rem;

            &__l {
              display: flex;
              align-items: center;
            }

            &__r {
              .btn {
                border-radius: .75rem;
                padding: .5rem 1rem;
                background-color: #3D444B;

                &--active {
                  background-color: #1763fb;
                }
              }
            }

            svg.img {
              width: 1.75rem;
              height: 1.75rem;
              margin-right: .5rem;
            }

            .ttl {
              padding: .25rem .5rem;
            }
          }
        }
      }
    }
  }
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
}

.page-link span {
    color: white;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    border: 1px solid #dee2e6;
    color: white !important;
}

.create-account-btn {
  border-radius: 1rem;
  padding: 10px;
  margin-left: 30px;
  background-color: #1763fb;
}

.top-left-actions-btns {
  display: flex;
}

.excel-btn {
  border-radius: 0.5rem;
  padding: 10px;
  margin-left: 1rem;
  border: 1px solid #1763fb;
  display: inline-block;
}

.select-risk {
  margin-left: 1rem;
}

</style>