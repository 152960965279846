<template>
  <div @click="openChat" class="msg-wrapper">
    <div class="btn btn-msg">
      <div class="btn btn-notif btn_mark">
        <div v-if="messagesCount && messagesCount !==0" class="puls-btn number">{{ messagesCount }}</div>
        <div>
          <div class="chat-img">
            <img src="/img/hand.png"></img>
          </div>
        </div>
      </div>
    </div>
    <div class="msg-text">CHAT</div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CircleLoad from '@/components/img/CircleLoad'

export default {
  name: 'notifications',
  components: {
      CircleLoad
  },
  computed: {
    ...mapGetters({
      newNotificationsCount: 'notifications/unreadCount'
    }),
    ...mapState({
      messagesCount: state => state.messagesCount,
    }),
  },
  data () {
    return {
      menu: false,
      isLoaded: false,
      viewList: [],
      viewedList: [],
      viewTimeout: null,
      loading: false,
      notifications: [],
      dropdown: false
    }
  },
  methods: {
    gotoAllNotifications () {
      this.$store.dispatch('notifications/gotoAllNotifications')
    },
  	getUnreadNotifications () {
      if (this.loading) {
        return false
      }
      this.loading = true
      return axios.get(process.env.VUE_APP_BACKEND_API_URL + 'notifications-unread', { params: { limit: 10 } })
        .then(response => {
          this.$store.dispatch('notifications/clearNotifications')
          this.notifications = response.data
        })
        .catch(error => {
          console.error('error get unread notifications', error)
        })
        .finally(() => {
          this.loading = false
        })
	  },
    openChat () {
      if (this.$route.name != 'Chat') {
        this.$router.push({ name: "Chat" })
      } else {
        this.$router.go()
      }
    },
	  dropdownClose() {
        this.dropdown = false
      },
      textNotification (notification) {
      	if (notification.notification.data.type === 'new-individual-offer') {
      		return 'You have received new individual offer'
      	}

        if (notification.notification.data.type === 'update-profile') {
          const updatedFields = notification.notification.data.updated_field_labels.join(', ')
          return 'Your profile was updated: ' + updatedFields
        }

        if (notification.notification.data.type === 'update-business-information') {
          const updatedFields = notification.notification.data.updated_field_labels.join(', ')
          return 'Your business information was updated: ' + updatedFields
        }

        if (notification.notification.data.type === 'update-request') {
          return 'You request was updated'
        }

      	return ''
      },
      viewIndividualOffer (notification) {
      	this.$store.dispatch('notifications/newIndividualOffer', notification)
      },
      viewUpdateProfile (notification) {
        this.$store.dispatch('notifications/updateProfile', notification)
      },
      viewUpdateRequest (notification) {
        this.$store.dispatch('notifications/updateRequest', notification)
      }
  },
  watch: {
    dropdown (isOpenedMenu) {
      if (isOpenedMenu === true) {
        this.getUnreadNotifications()
      } else {
        this.notifications = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn, a.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: .75rem;
  margin: auto .5rem;
  background-color: #2F363D;

  .img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.number {
  content: "";
  position: absolute;
  top: -4px;
  right: -4px;
  width: 1rem;
  height: 1rem;
  background-color: #1763fb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
}

.puls-btn {
  animation: btnPulse-2 2.5s infinite alternate ease-in-out;
}

@keyframes btnPulse-2 {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
    background: #21b184;
  }
  50% {
    transform: scale(1);
    background: #8ad4bd;
  }
}

.chat-img {
  display: flex;
  justify-content: center;
}

.btn {
  width: auto;
  height: 2rem;
}

.msg-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.msg-text {
  text-align: center;
}

.chat-img img {
  width: 30px;
  height: auto;
}

.btn-msg {
  margin-top: 5px;
  margin-bottom: 3px;
}
</style>
