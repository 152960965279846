<template>
    <div class="modal__wrapper">
      <div class="modal__close">
        <div class="btn" @click="close">
          <Close class="img"/>
        </div>
      </div>
      <div class="modal__header">
        <div class="ttl">Edit license offer other service</div>
      </div>
      <div class="modal__body">
        <div class="row">
          <div class="label">Name</div>
          <div class="data">
            <input type="text" name="name" class="inpt" v-model="form.name">
          </div>
        </div>
        <div class="row">
          <div class="label">Price</div>
          <div class="data">
            <input type="number" name="price" class="inpt" v-model="form.price">
          </div>
        </div>
        <div class="row">
          <div class="label">Is visible</div>
          <div class="data c-tmblr">
            <Tmblr :active="!!form.is_visible" :class="'c-tmblr'" :text="'Visible'" @changeState="changeState('is_visible')" />
          </div>
        </div>
        <span v-if="error && error.length > 0" class="invalid-feedback">{{ error }}</span>
      </div>
      <div class="modal__footer">
        <div class="btn" @click="save">Save</div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions } from 'vuex'
  import Close from './../img/Close.vue'
  import Tmblr from '../elem/Tmblr.vue'
  
  export default {
    components: {
      Close,
      Tmblr,
    },
    props: {
      options: {
        type: Object,
        default: null
      }
    },
    data: () => ({
      form: {
        name: null,
        price: null,
        is_visible: null,
      },
      error: null,
      message: '',
    }),
    created () {
      this.updateForm()
    },
    computed: {
      isEdit(){
        return this.options && this.options.id && this.options.form
      },
    },
    methods: {
      changeState(property){
        this.form[property] = !this.form[property]
      },
      updateForm () {
        if (!this.isEdit) return
  
        this.form.name = this.options.form.name
        this.form.price = this.options.form.price
        this.form.is_visible = this.options.form.is_visible
      },
      async save () {
        if (this.isEdit) {
          try {
            const url = process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers/other-service/' + this.options.id
            
            const response = await axios.post(url, {
              name: this.form.name,
              price: this.form.price,
              is_visible: this.form.is_visible,
            });

            this.$store.dispatch('deleteItemFromTable', {
              id: this.options.id,
              table: this.options.table,
              actionType: this.options.actionType,
            })

            this.close()
          }
          catch (error) {
            console.log(error);
          }
        } else {
          try {
            const url = process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers/other-service'
            
            const response = await axios.post(url, {
              name: this.form.name,
              price: this.form.price,
              is_visible: this.form.is_visible,
            });
            
            let id = response.data.otherService.id

            this.$store.dispatch('deleteItemFromTable', {
              id: id,
              table: this.options.table,
              actionType: this.options.actionType,
            })

            this.close()
          }
          catch (error) {
            console.log(error);
          }
        }
      },
      close() {
        this.$emit("close");
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .modal {
    &__wrapper {
      width: 40rem;
    }
  
    &__header {
      padding: 2.5rem 0.75rem 1.5rem;
    }
  
    &__body {
      flex-direction: column;
      align-content: flex-start;
  
      .row {
        width: 100%;
        display: flex;
        align-items: center;
        padding: .25rem 0;
        margin: .25rem 0 0;
  
        .label {
          min-width: 10rem;
          padding-right: .75rem;
          font-weight: bold;
        }
  
        .data {
          flex-grow: 1;
  
          .inpt {
            width: 100%;
            color: #000000;
  
            &__ta {
              height: 7rem;
            }
          }
        }
  
        &__price {
          .data {
            display: flex;
  
            input {
              margin-right: .75rem;
            }
  
            select {
              color: white;
              background-color: var(--c_more-black);
              padding: .25rem .5rem;
              border-radius: 6px;
            }
          }
        }
      }
      .row{
        &_files {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 8px;
  
          .box_addFiles {
            width: 30%;
            //width: calc(50% - .75rem);
            //margin: .75rem 0;
            //padding: 1rem 1.75rem;
            //background: #22252B;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            display: flex;
            flex-direction: column;
  
            .ttl {
              padding-bottom: .25rem;
            }
  
            .separ {
              width: 3rem;
              height: .25rem;
              background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
              box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
              border-radius: 14px;
              margin: .25rem 0;
            }
  
            .content {
              padding: 1rem;
              flex-grow: 1;
              //margin-top: 1rem;
              border: 1px dashed #1763fb;
              box-sizing: border-box;
              filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
              border-radius: 14px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
  
              .choose {
                text-align: center;
  
                .btn {
                  padding: 0.5rem;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  border-radius: 6px;
                  color: #fff;
                  font-size: 13px;
                }
  
                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
  
              &_many {
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: space-between;
                padding: 0.75rem 6px;
  
                .draggable {
                  display: flex;
                  padding: 0;
                  flex-wrap: wrap;
                }
  
                .file {
                  position: relative;
                  text-align: center;
                  width: 4.5rem;
                  max-width: 4.5rem;
  
                  svg.img {
                    width: 3rem;
                    height: 3rem;
                    margin: 0 auto;
                    cursor: pointer;
                    fill: #1763fb;
                    path{
                      fill: #1763fb;
                    }
                  }
                  .btn {
                    &_del {
                      position: absolute;
                      top: 0;
                      right: 1rem;
                      width: 1.25rem;
                      height: 1.25rem;
                      border-radius: 50%;
                      background-color: #B03636;
                      display: flex;
                      align-items: center;
                      justify-content: center;
  
                      svg.img {
                        width: 40%;
                        height: 40%;
                      }
                    }
  
                    &_download {
                      position: absolute;
                      top: 1.7rem;
                      right: 1rem;
                      width: 1.25rem;
                      height: 1.25rem;
                      border-radius: 50%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
  
                      svg.img {
                        width: 90%;
                        height: 90%;
                      }
                    }
                  }
  
                  .desc {
                    font-size: 10px;
                    color: #333333;
                    word-break: break-word;
                  }
                }
  
                .add {
                  text-align: center;
                  width: 2.5rem;
                  max-width: 2.5rem;
  
                  .btn {
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: 50%;
                    background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                    display: flex;
                    align-items: center;
                    justify-content: center;
  
                    svg.img {
                      width: 45%;
                      height: 45%;
                      transform: rotate(45deg);
                    }
                  }
  
                  .desc {
                    font-size: 12px;
                    color: rgba(255, 255, 255, 0.3);
                  }
                }
              }
            }
          }
        }
      }
    }
    &__footer {
      padding: 1rem 1.5rem 1.5rem;
    }
    textarea{
      height: 5rem;
    }
  }
  </style>
  