export default {
  methods: {
  	textNotification (notification) {
	        let authorName = 'User - ' + notification.author.full_name ? notification.author.full_name : notification.author.member_login

			authorName += ' | Cust ID: ' + notification.author.id + ' | ' + notification.author.email;

	      	if (notification.notification.data.type === 'update-profile') {
	          const updatedFields = notification.notification.data.updated_field_labels.join(', ')
	      		return authorName + ' updated ' + updatedFields + ' in his profile'
	      	}

	        if (notification.notification.data.type === 'update-business-information') {
	          const updatedFields = notification.notification.data.updated_field_labels.join(', ')
	          return authorName + ' updated ' + updatedFields + ' in his business information'
	        }

	        if (notification.notification.data.type === 'update-logo') {
	          return authorName + ' updated logo'
	        }

	        if (notification.notification.data.type === 'update-request') {
	          const updatedFields = notification.notification.data.updated_field_labels.join(', ')
	          return authorName + ' updated ' + updatedFields
	        }

	        if (notification.notification.data.type === 'apply-offer') {
	          return authorName + ' applied offer'
	        }

	        if (notification.notification.data.type === 'cancel-offer') {
	          return authorName + ' canceled offer'
	        }

	        if (notification.notification.data.type === 'update-compliance') {
	          const updatedFields = notification.notification.data.updated_fields.join(', ')
	          return authorName + ' updated ' + updatedFields
	        }

	        if (notification.notification.data.type === 'apply-legal-atlas') {
	          return authorName + ' applied legal atlas'
	        }

			if (notification.notification.data.type === 'license-offer-request') {
			  return authorName + ' new license offer request'
			}

	        if (notification.notification.data.type === 'cancel-legal-atlas') {
	          return authorName + ' canceled legal atlas'
	        }

	        if (notification.notification.data.type === 'chat-select-offer') {
	          return authorName + ' selected ' + notification.notification.data.offer_type + ' in chat with ' + notification.notification.data.participant_names
	        }

	        if (notification.notification.data.type === 'chat-change-offer-status') {
	          return authorName + ' changed offer status in chat with ' + notification.notification.data.participant_names
	        }

	        if (notification.notification.data.type === 'create-service-chat') {
	          return authorName + ' applied service and created chat'
	        }

	        if (notification.notification.data.type === 'create-offer-chat') {
	          return authorName + ' applied offer and created chat'
	        }

	      	return ''
      },
  }
}