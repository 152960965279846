<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">Strategy</div>
    </div>
    <div class="modal__body">
      <div class="row" v-if="$store.state.user.is_super_admin">
        <div class="label">Manager can view</div>
        <div class="data c-tmblr">
          <Tmblr :active="!!form.is_manager_can" :class="'c-tmblr'" :text="'Manager can view'" @changeState="changeState('is_manager_can')" />
        </div>
      </div>
      <div class="row">
        <div class="label">Country</div>
        <div class="data">
          <select name="country_id" class="inpt" v-model="form.country_id">
            <option :value="null">Select country</option>
            <option
              v-for="country in countries" :key="country.id"
              :selected="country.id === form.country_id"
              :value="country.id"
            >
              {{ country.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Managers</div>
        <div class="data">
          <div v-for="(formManager, formManagerIndex) in form.managers">
            <div>
              <div class="row">
                <div class="data">
                  <div class="row">
                    <div class="label">
                      Manager № {{ formManagerIndex + 1 }}
                    </div>
                    <div class="data">
                      <div class="delete-btn" @click="deleteManager(formManagerIndex)">
                        <Close class="img"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <select name="manager_name" class="inpt" v-model="formManager.name">
                <option :value="null">Select manager</option>
                <option 
                  v-for="(manager, i) in managers" 
                  :value="manager.name"
                >
                  {{ manager.name }}
                </option>
              </select>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="data">
                <span class="btn" @click="addManager">
                  Add manager
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label">License type</div>
        <div class="data">
          <select name="license_type" class="inpt" v-model="form.license_type">
            <option :value="null">Select license type</option>
            <option
              v-for="type in licenseTypes" :key="type.id"
              :selected="type === form.license_type"
              :value="type"
            >
              {{ type }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Amount by REG</div>
        <div class="data">
          <input type="number" name="amount_by_reg" class="inpt" v-model="form.amount_by_reg">
        </div>
      </div>
      <div class="row">
        <div class="label">Register link</div>
        <div class="data">
          <input type="text" name="register_link" class="inpt" v-model="form.register_link">
        </div>
      </div>
      <div class="row">
        <div class="label">Priority</div>
        <div class="data">
          <select name="status" class="inpt" v-model="form.priority">
            <option :value="null">Select priority</option>
            <option
              v-for="priority in priorities" :key="priority"
              :selected="priority === form.priority"
              :value="priority"
            >
              {{ priority }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Plan</div>
        <div class="data">
          <input type="text" name="plan" class="inpt" v-model="form.plan">
        </div>
      </div>
      <div class="row">
        <div class="label">Current closed amount</div>
        <div class="data">
          <input type="number" name="current_amount" class="inpt" v-model="form.current_amount">
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save">Save</div>
    </div>
  </div>
</template>

<script>
import Close from '../img/Close.vue'
import Tmblr from '../elem/Tmblr.vue'
import BlueFile from '../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from '../img/BlueDownload.vue'

export default {
  components: {
    Close,
    Tmblr,
    BlueDownload,
    BlueFile,
    draggable,
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data(){
    return{
      countries: [],
      managers: [],
      priorities: [
        'High',
        'Middle',
        'Low',
      ],
      licenseTypes: [
          "Crypto/VASP/CASP",
          "Money service/Remittee",
          "MSO",
          "MSB",
          "MPI",
          "Small Payment Institution",
          "Payment Institution",
          "Small EMI",
          "EMI",
          "PSP (AISP, PISP)",
          "Bank",
          "VC",
          "Fund",
          "Asset management",
          "Under management",
          "Broker/Dealer/Forex"
      ],
      form: {
        country_id: null,
        priority: null,
        manager_name: null,
        is_manager_can: true,
        amount_by_reg: 0,
        current_amount: 0,
        license_type: '',
        register_link: '',
        plan: '',
        managers: [],
      },
      error: null,
    }
  },
  computed: {
    isEdit () {
      return this.options && this.options.id && this.options.form
    }
  },
  async created () {
    if(this.isEdit){
      await this.getStrategy()
      await this.updateFormData()
    }

    await this.getCountries()
    await this.getManagers()
  },
  methods: {
    addManager(){
      this.form.managers.push({
        name: null
      })
    },
    deleteManager(index){
      this.form.managers.splice(index, 1)
    },
    updateFormData(){
      let arrManagers = [];
      
      if(this.form.manager_name){
        arrManagers = this.form.manager_name.split('|').map(name => {
          return { name: name };
        });
      }
      
      this.$set(this.form, 'managers',  arrManagers)
    },
    async getStrategy(){
      const that = this
      await axios.get('admin/strategies/' + this.options.id)
      .then(function (response) {
        that.form = response.data
      })
      .catch(function (error) {
        console.log(error);
      })
	  },
    async getCountries () {
      await axios.get('admin/data/countries')
      .then(({data}) => {
        this.countries = data
      })
      .catch((error) => {
        console.log(error);
      })
    },
    async getManagers () {
      const that = this
      await axios.get('admin/data/managers')
        .then(function (response) {
          that.managers = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
	  },
    async save(){
      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/strategies'

      if (this.isEdit) {
        url += '/' + this.options.id
      }

      this.form.manager_name = this.form.managers.map(obj => obj.name).join('|');

      await axios.post(url, this.form)
        .then((response) => {
          this.close();

          if(this.isEdit){
            this.$store.dispatch('editItemFromTable', {
              id: this.options.id,
              table: this.options.table,
              actionType: this.options.actionType,
            })
          }
          else{
            this.$store.dispatch('createItemInTable', {
              table: this.options.table,
              actionType: this.options.actionType,
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    changeState(property){
      this.form[property] = !this.form[property]
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
        max-width: 160px;
      }

      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
        .btn {
          padding: 0.5rem;
          background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
          border-radius: 6px;
          color: #fff;
          font-size: 13px;
        }
        .delete-btn{
          margin-left: auto;
          width: 24px;
          height: 24px;
          background-color: #B03636;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg{
            fill: #fff;
            max-width: 16px;
            max-height: 16px;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          width: 30%;
          //width: calc(50% - .75rem);
          //margin: .75rem 0;
          //padding: 1rem 1.75rem;
          //background: #22252B;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #333333;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
  textarea{
    height: 5rem;
  }
}
.gap-12{
  gap: 12px;
}
</style>
