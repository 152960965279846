import { render, staticRenderFns } from "./AccountRequestDocumentTemplatesModal.vue?vue&type=template&id=73356dba&scoped=true&"
import script from "./AccountRequestDocumentTemplatesModal.vue?vue&type=script&lang=js&"
export * from "./AccountRequestDocumentTemplatesModal.vue?vue&type=script&lang=js&"
import style0 from "./AccountRequestDocumentTemplatesModal.vue?vue&type=style&index=0&id=73356dba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73356dba",
  null
  
)

export default component.exports