<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        {{ options.actionType | capitalize }} {{ options.objectType }}
      </div>
    </div>
    <div class="modal__body">
      <div class="form">
        <div class="form-group" v-if="$store.state.user.is_super_admin">
          <label class="label">Manager can view</label>
          <div class="data c-tmblr">
            <Tmblr :active="!!form.is_manager_can" :class="'c-tmblr'" :text="'Manager can view'" @changeState="changeState('is_manager_can')" />
          </div>
        </div>
        <div class="form-group">
          <label class="label">Validated</label>
          <div class="data c-tmblr">
            <Tmblr :active="!!form.is_validated" :class="'c-tmblr'" :text="'Validated'" @changeState="changeState('is_validated')" />
          </div>
        </div>
        <div class="form-group half">
          <label>Name of Legal Co.</label>
          <input v-model="form.legal_name" type="text" class="form-control" placeholder="Legal Firm">
          <span v-show="formErrors.legal_name" class="invalid-feedback">{{ formErrors.legal_name }}</span>
        </div>
        <div class="form-group half">
          <label>Date of service</label>
          <input v-model="form.date_of_service" type="datetime-local" class="form-control" placeholder="Date of service"">
          <span v-show="formErrors.date_of_service" class="invalid-feedback">{{ formErrors.date_of_service }}</span>
        </div>
        <div class="form-group half">
          <label>Type of Services</label>
          <div class="inpt-block">
            <select class="form-control" style="width: 100%;" v-model="form.services_type">
              <option value="null">Select service type</option>
              <option v-for="(service, i) in Object.keys(services)" :key="i" :value="service">{{ service }}</option>
            </select>
          </div>
          <!--<input v-model="form.services_type" type="text" class="form-control" placeholder="Incorporation">-->
          <span v-show="formErrors.services_type" class="invalid-feedback">{{ formErrors.services_type }}</span>
        </div>
        <div class="form-group half">
          <label>Name of Services</label>
          <div class="inpt-block">
            <select class="form-control" style="width: 100%;" v-model="form.service_name">
              <option value="null">
                <span v-if="!form.services_type">
                  Select service type firstly
                </span>
                <span v-else>
                  Select service name
                </span>
              </option>
              <option v-for="(item, i) in services[form.services_type]" :key="i" :value="item">{{ item }}</option>
            </select>
          </div>
          <!--<input v-model="form.srvice_name" type="text" class="form-control" placeholder="Incorporation">-->
          <span v-show="formErrors.srvice_name" class="invalid-feedback">{{ formErrors.srvice_name }}</span>
        </div>
        <div class="form-group half">
          <label>Provider Price</label>
          <div class="inpt-block">
            <input v-model="form.price" type="text" class="form-control" placeholder="2.500.00">
            <div class="currency-symbol">€</div>
          </div>
          <span v-show="formErrors.price" class="invalid-feedback">{{ formErrors.price }}</span>
        </div>
        <div class="form-group half">
          <label>Service time</label>
          <div class="inpt-block">
            <input v-model="form.service_time" type="text" class="form-control" placeholder="3">
            <div class="desc">works day</div>
          </div>
          <span v-show="formErrors.service_time" class="invalid-feedback">{{ formErrors.service_time }}</span>
        </div>
        <div class="form-group">
          <label>Public price</label>
          <div class="inpt-block">
            <input v-model="form.public_price" type="text" class="form-control" placeholder="2.500.00">
            <div class="currency-symbol">€</div>
          </div>
          <span v-show="formErrors.public_price" class="invalid-feedback">{{ formErrors.public_price }}</span>
        </div>
        <div class="form-group">
          <label>Country of service</label>
          <div class="inpt-block">
            <select class="form-control" style="width: 100%;" v-model="form.country_id">
              <option value="null">Select country</option>
              <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
            </select>
          </div>
          <span v-show="formErrors.country_id" class="invalid-feedback">{{ formErrors.country_id }}</span>
        </div>
        <div class="form-group few-el">
          <label>Offer countries</label>
          <div>
            <div v-for="(country, index) in form.countries">
              <div>
                <div class="row">
                  <div class="data">
                    <div class="row">
                      <div class="label">
                        Country № {{ index + 1 }}
                      </div>
                      <div class="data">
                        <div class="delete-btn" @click="deleteCountry(index)">
                          <Close class="img"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="inpt-block">
                <select class="form-control" name="country" v-model="country.name">
                  <option :value="null">Select country</option>
                  <option 
                    v-for="(item, i) in countries" 
                    :value="item.name"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="data">
                  <span class="btn" @click="addCountry">
                    Add country
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group few-el">
          <label>Managers</label>
          <div>
            <div v-for="(formManager, formManagerIndex) in form.managers">
              <div>
                <div class="row">
                  <div class="data">
                    <div class="row">
                      <div class="label">
                        Manager № {{ formManagerIndex + 1 }}
                      </div>
                      <div class="data">
                        <div class="delete-btn" @click="deleteManager(formManagerIndex)">
                          <Close class="img"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="inpt-block">
                <select class="form-control" name="country" v-model="formManager.name">
                  <option :value="null">Select manager</option>
                  <option 
                    v-for="(manager, i) in managers" 
                    :value="manager.name"
                  >
                    {{ manager.name }}
                  </option>
                </select>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="data">
                  <span class="btn" @click="addManager">
                    Add manager
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Description of Benefit</label>
          <div class="inpt-block">
            <textarea style="width: 100%" v-model="form.benefit_description" type="text" class="form-control" placeholder="Description of Benefit" rows="3"></textarea>
          </div>
          <span v-show="formErrors.benefit_description" class="invalid-feedback">{{ formErrors.benefit_description }}</span>
        </div>
        <div class="form-group">
          <label>Internal comment</label>
          <div class="inpt-block">
            <textarea style="width: 100%" v-model="form.internal_comment" type="text" class="form-control" placeholder="Internal comment" rows="3"></textarea>
          </div>
          <span v-show="formErrors.internal_comment" class="invalid-feedback">{{ formErrors.internal_comment }}</span>
        </div>
        <div class="form-group half">
          <label>Web site</label>
          <input v-model="form.web_site" type="text" class="form-control" placeholder="Web site">
          <span v-show="formErrors.web_site" class="invalid-feedback">{{ formErrors.web_site }}</span>
        </div>
        <div class="form-group half">
          <label>Work link</label>
          <input v-model="form.work_link" type="text" class="form-control" placeholder="Work Link">
          <span v-show="formErrors.work_link" class="invalid-feedback">{{ formErrors.work_link }}</span>
        </div>
        <SelectProvider :provider-id="form.provider_id" @selectProvider="selectProvider" />
        <div class="form-group">
          <label>Provider contact</label>
          <input v-model="form.provider_contact" type="text" class="form-control" placeholder="Provider contact">
          <span v-show="formErrors.provider_contact" class="invalid-feedback">{{ formErrors.provider_contact }}</span>
        </div>
        <div class="row files">
          <div class="form-group files">
            <div class="row row_files">
            <div class="box box_addFiles">
              <div v-if="getUploadedFiles('agreements').length" class="content content_many">
              <draggable v-model="upload.agreements" tag="div" class="draggable">
              <div v-for="(file, key) in getUploadedFiles('agreements')" :key="key" class="file">
                <BlueFile class="img" />
                <div class="btn btn_del" @click="delFile('agreements', key, file)"><Close class="img" /></div>
                <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'agreements')"><BlueDownload class="img" /></div>
                <div class="desc">{{ file.name }}</div>
              </div>
              </draggable>
              <div class="add">
                <div class="btn" @click="$refs.agreements[upload.agreements.length - 1].click()"><Close class="img" /></div>
              </div>
              </div>
              <div v-else class="content">
              <div class="choose">
                <div class="btn" @click="$refs.agreements[0].click()">Agreements</div>
              </div>
              </div>
              <input
                v-for="(i, key) in upload.agreements"
                :key="key"
                type="file"
                :name="`agreements_${key}`"
                class="hide"
                ref="agreements"
                @change="addInput($event, 'agreements', key)"
              >
            </div>
            </div>
          </div>
          <div class="form-group files">
            <div class="row row_files">
            <div class="box box_addFiles">
              <div v-if="getUploadedFiles('offer').length" class="content content_many">
              <draggable v-model="upload.offer" tag="div" class="draggable">
              <div v-for="(file, key) in getUploadedFiles('offer')" :key="key" class="file">
                <BlueFile class="img" />
                <div class="btn btn_del" @click="delFile('offer', key, file)"><Close class="img" /></div>
                <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'offer')"><BlueDownload class="img" /></div>
                <div class="desc">{{ file.name }}</div>
              </div>
              </draggable>
              <div class="add">
                <div class="btn" @click="$refs.offer[upload.offer.length - 1].click()"><Close class="img" /></div>
              </div>
              </div>
              <div v-else class="content">
              <div class="choose">
                <div class="btn" @click="$refs.offer[0].click()">Offer</div>
              </div>
              </div>
              <input
                v-for="(i, key) in upload.offer"
                :key="key"
                type="file"
                :name="`offer_${key}`"
                class="hide"
                ref="offer"
                @change="addInput($event, 'offer', key)"
              >
            </div>
            </div>
          </div>
          <div class="form-group files">
            <div class="row row_files">
              <div class="box box_addFiles">
                <div v-if="getUploadedFiles('documents').length" class="content content_many">
                  <draggable v-model="upload.documents" tag="div" class="draggable">
                  <div v-for="(file, key) in getUploadedFiles('documents')" :key="key" class="file">
                    <BlueFile class="img" />
                    <div class="btn btn_del" @click="delFile('documents', key, file)"><Close class="img" /></div>
                    <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'documents')"><BlueDownload class="img" /></div>
                    <div class="desc">{{ file.name }}</div>
                  </div>
                  </draggable>
                  <div class="add">
                    <div class="btn" @click="$refs.documents[upload.documents.length - 1].click()"><Close class="img" /></div>
                  </div>
                </div>
                <div v-else class="content">
                  <div class="choose">
                    <div class="btn" @click="$refs.documents[0].click()">Documents</div>
                  </div>
                </div>
                  <input
                    v-for="(i, key) in upload.documents"
                    :key="key"
                    type="file"
                    :name="`documents_${key}`"
                    class="hide"
                    ref="documents"
                    @change="addInput($event, 'documents', key)"
                  >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Save data</div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'
import SelectProviderMixin from '@/mixins/selectProvider.js'
import SelectProvider from './../provider/SelectProvider.vue'
import BlueFile from '../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from '../img/BlueDownload.vue'
import Tmblr from '../elem/Tmblr.vue'

export default {
  mixins: [
    SelectProviderMixin 
  ],
  components: {
    Close,
    SelectProvider,
    BlueDownload,
    BlueFile,
    Tmblr,
    draggable,
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  created () {
    this.getCountries()
    this.getManagers()
    if (this.options.actionType == 'edit' ) {
      this.getLegalAtlas(this.options.id)
      this.getDocuments()
      this.getAgreements()
      this.getOffer()
    }
  },
  data() {
    return {
      message: '',
      error: '',
      countries: [],
      managers: [],
      service_types: [
        'License incorporation',
        'Out-staffing',
        'Accounting & Audit',
        'Other legal services',
        'Escrow services',
      ],
      services: {
        'License incorporation': [
          'Crypto',
          'Money service/remittee',
          'Small Payment Institution',
          'Payment Institution',
          'Small EMI',
          'EMI',
          'Bank',
          'VC',
          'Fund',
          'Asset management',
          'Broker'
        ],
        'Out-staffing': [
          'Nominal',
          'Director',
          'AML Officer',
          'UBO n.',
          'All Staff'
        ],
        'Accounting & Audit': [
          'Accounting',
          'Audit',
          'Reporting',
          'Statement',
          'All'
        ],
        'Other legal services': [
          'Legal opinion',
          'Legal business Support',
          'Legal structuring',
          'Legal SOF',
          'Legal Represent',
          'All Legal'
        ],
        'Escrow services': [
          'Escrow License',
          'Escrow License & Insurance',
          'Escrow Bank'
        ]
      },
      form: {
        countries: [],
        legal_name: '',
        services_type: '',
        price: '',
        public_price: '',
        service_time: '',
        benefit_description: '',
        web_site: '',
        work_link: '',
        country_id: '',
        is_manager_can: true,
        is_validated: false,
        provider_contact: '',
        internal_comment: '',
        offer_countries: '',
        manager_name: '',
        date_of_service: '',
        service_name: '',
        managers: [],
      },
      formErrors: {
        legal_name: '',
        services_type: '',
        price: '',
        public_price: '',
        service_time: '',
        benefit_description: '',
        web_site: '',
        work_link: '',
        country_id: ''
      },
      upload: {
        documents: [{ name: null }],
        offer: [{ name: null }],
        agreements: [{ name: null }],
      }
    };
  },
  methods: {
    changeState(property){
      this.form[property] = !this.form[property]
    },
    addCountry(){
      this.form.countries.push({
        name: null
      })
    },
    deleteCountry(index){
      this.form.countries.splice(index, 1)
    },
    getCountries() {
      axios.get('admin/data/countries')
        .then(({ data }) => {
          this.countries = data
        })
        .catch((error) => console.log(error))
    },
    async getManagers () {
      const that = this
      await axios.get('admin/data/managers')
        .then(function (response) {
          that.managers = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    addManager(){
      this.form.managers.push({
        name: null
      })
    },
    deleteManager(index){
      this.form.managers.splice(index, 1)
    },
    updateFormData(){
      let arrCountries = [];
      let arrManagers = [];
      
      if(this.form.offer_countries){
        arrCountries = this.form.offer_countries.split('|').map(name => {
          return { name: name };
        });
      }
      
      if(this.form.manager_name){
        arrManagers = this.form.manager_name.split('|').map(name => {
          return { name: name };
        });
      }
      
      this.$set(this.form, 'countries',  arrCountries)
      this.$set(this.form, 'managers',  arrManagers)
    },
    clearErrors() {
      for (let key in this.form) {
        this.formErrors[key] = ''
      }
    },
    getLegalAtlas () {
      const that = this
      axios.get('admin/legal-atlas/' + this.options.id, this.form)
      .then(function (response) { 
        that.form = response.data
        
        that.updateFormData()
      })
      .catch((errors) => {
          console.dir(errors)
        })
    },
    async getDocuments () {
      const that = this
      const id = this.options.id
      
      if(id){
        await axios.get('admin/legal-atlas/' + id + '/get-file/documents')
        .then((response) => {
          let docsData = response.data

          if(docsData.length > 0) {
            that.upload.documents = docsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
      }
    },
    async getOffer () {
      const that = this
      const id = this.options.id
      
      if(id){
      await axios.get('admin/legal-atlas/' + id + '/get-file/offer')
      .then((response) => {
        let docsData = response.data
    
        if(docsData.length > 0) {
        that.upload.offer = docsData.map((el) => {
          return {
          id: el.id,
          uuid: el.uuid,
          name: el.name,
          type: 'server',
          url: el.original_url,
          order: el.order
          }
        })
        }
      })
      .catch((errors) => {
        console.dir(errors)
      })
      }
    },
    async getAgreements () {
      const that = this
      const id = this.options.id
      
      if(id){
      await axios.get('admin/legal-atlas/' + id + '/get-file/agreements')
      .then((response) => {
        let docsData = response.data
    
        if(docsData.length > 0) {
        that.upload.agreements = docsData.map((el) => {
          return {
          id: el.id,
          uuid: el.uuid,
          name: el.name,
          type: 'server',
          url: el.original_url,
          order: el.order
          }
        })
        }
      })
      .catch((errors) => {
        console.dir(errors)
      })
      }
    },
    getUploadedFiles(el) {
      return this.upload[el].filter(e => e.name !== null)
    },
    delFile(name, key, file) {
      if (this.upload[name].length > 1) {
        this.upload[name].splice(key, 1)
      } else {
        this.upload[name][0].name = null
      }

      if(file.id && this.options.id){
        axios.post('admin/legal-atlas/' + this.options.id +'/delete-file', {
        collection: name,
        media_id: file.id,
      })
        .then(function () {
          
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    addInput(event, name, key) {
      if (this.$refs[name][key].files.length) {
        const file_name = this.$refs[name][key].files[0].name
        if (this.getUploadedFiles(name).some(e => e.name == file_name)) {
          this.$noty.error('Error! A file with this name has already been added.')
          return false
        }

        const extension = this.$refs[name][key].files[0].name.split('.').pop()
        const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx']
        if (!availableExtensionsArr.includes(extension.toLowerCase())) {
          this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
          return false
        }

        if (this.$refs[name][key].files[0].size / 1024 / 1024 > 10) {
          this.$noty.error('File is too large. Maximum - 10MB.')
          return false
        }

        this.upload[name] = this.upload[name].filter(e => e.name !== null)
        if (this.$refs[name].length < 10) {
          this.upload[name].push({ name: file_name, file: event.target.files[0]})
        } else {
          this.$noty.error('Maximum - 10 files')
        }
      }
    },
    downloadFile(file, type) {
      const id = this.options.id
      if(file.url){
        this.openFile(file.url)
      }
      else if(id){
        axios.post('admin/legal-atlas/' + id +'/get-file-link', {
        collection: type,
        media_id: file.id,
      })
        .then(function (response) {
          if (response.data) {
            const url = response.data
            var fileURL = response.data;
            this.openFile(fileUrl);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    openFile(url){
      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.setAttribute('target', '_blank')
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async submit() {
      const that = this
      this.clearErrors()
      this.form.provider_id = this.providerId
      
      this.form.offer_countries = this.form.countries.map(obj => obj.name).join('|');
      this.form.manager_name = this.form.managers.map(obj => obj.name).join('|');
      
      axios.post(this.options.url, this.form)
      .then(function (response) { 
        if (response.data.errors) {
          const errors = response.data.errors
          for (let key in errors) {
            that.formErrors[key] = errors[key][0]
          }
        }
        if (response.data.success) {
          that.processFiles(response.data.legal_service.id)
          //that.close()
          that.$noty.success(that.options.successfullMessage)
          that.$store.dispatch('editItemFromTable', {
            id: that.options.id,
            table: that.options.table,
            actionType: that.options.actionType
          })
        }
      })
      .catch((errors) => {
          console.dir(errors)
        })
    },
    processFiles(id){
      let data = new FormData()
      const uploadKeys = Object.keys(this.upload)

      for (let z = 0; z < uploadKeys.length; z++) {
        const key = uploadKeys[z]
        for (let i = 0; i < this.upload[key].length; i++) {
          if (this.upload[key][i].name !== null) {
            let f = {}
            if (this.upload[key][i].type == 'server') {
              f = JSON.stringify(this.upload[key][i])
            } else {
              data.append(key + '_names[' + i + ']', this.upload[key][i].name)
              f = this.upload[key][i].file
            }
            data.append(key + '[' + i + ']', f)
          }
        }
      }
      
      axios.post('admin/legal-atlas/' + id + '/files', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((response) => {
        this.close()
      })
      .catch((errors) => {
        console.dir(errors)
      })
    },
    close() {
      this.$emit("close");
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
};
</script>

<style lang="scss" scoped>
.few-el{
  flex-direction: column;
  align-content: flex-start;
  .row {
    width: 100%;
    display: flex;
    align-items: center;
    padding: .25rem 0;
    margin: .25rem 0 0;
  
    .label {
      min-width: 10rem;
      padding-right: .75rem;
      font-weight: bold;
      max-width: 160px;
    }
  
    .data {
      flex-grow: 1;
  
      .inpt {
        width: 100%;
        color: #000000;
  
        &__ta {
          height: 7rem;
        }
      }
      .btn {
        padding: 0.5rem;
        background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
        border-radius: 6px;
        color: #fff;
        font-size: 13px;
      }
      .delete-btn{
        margin-left: auto;
        width: 24px;
        height: 24px;
        background-color: #B03636;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg{
          fill: #fff;
          max-width: 16px;
          max-height: 16px;
        }
      }
    }
  
    &__price {
      .data {
        display: flex;
  
        input {
          margin-right: .75rem;
        }
  
        select {
          color: white;
          background-color: var(--c_more-black);
          padding: .25rem .5rem;
          border-radius: 6px;
        }
      }
    }
  }
}
.modal {
  &__wrapper {
    width: 40rem;
  }
  .form-group.files{
    flex-direction: column;
  }
  .row.files{
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
    .form-group.files{
      max-width: 100%;
      width: 150px;
      flex: 0 0 150px;
    }
  }
  .row{
    &_files {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 8px;

      .box_addFiles {
        width: 150px;
        //width: calc(50% - .75rem);
        //margin: .75rem 0;
        //padding: 1rem 1.75rem;
        //background: #22252B;
        box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
        display: flex;
        flex-direction: column;

        .ttl {
          padding-bottom: .25rem;
        }

        .separ {
          width: 3rem;
          height: .25rem;
          background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          border-radius: 14px;
          margin: .25rem 0;
        }

        .content {
          padding: 1rem;
          flex-grow: 1;
          //margin-top: 1rem;
          border: 1px dashed #1763fb;
          box-sizing: border-box;
          filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
          border-radius: 14px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          .choose {
            text-align: center;

            .btn {
              padding: 0.5rem;
              background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
              border-radius: 6px;
              color: #fff;
              font-size: 13px;
            }

            .desc {
              font-size: 12px;
              color: rgba(255, 255, 255, 0.3);
            }
          }

          &_many {
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            padding: 0.75rem 6px;

            .draggable {
              display: flex;
              padding: 0;
              flex-wrap: wrap;
            }

            .file {
              position: relative;
              text-align: center;
              width: 4.5rem;
              max-width: 4.5rem;

              svg.img {
                width: 3rem;
                height: 3rem;
                margin: 0 auto;
                cursor: pointer;
                fill: #1763fb;
                path{
                  fill: #1763fb;
                }
              }
              .btn {
                &_del {
                  position: absolute;
                  top: 0;
                  right: 1rem;
                  width: 1.25rem;
                  height: 1.25rem;
                  border-radius: 50%;
                  background-color: #B03636;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 40%;
                    height: 40%;
                  }
                }

                &_download {
                  position: absolute;
                  top: 1.7rem;
                  right: 1rem;
                  width: 1.25rem;
                  height: 1.25rem;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 90%;
                    height: 90%;
                  }
                }
              }

              .desc {
                font-size: 10px;
                color: #333333;
                word-break: break-word;
              }
            }

            .add {
              text-align: center;
              width: 2.5rem;
              max-width: 2.5rem;

              .btn {
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 50%;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                display: flex;
                align-items: center;
                justify-content: center;

                svg.img {
                  width: 45%;
                  height: 45%;
                  transform: rotate(45deg);
                }
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }
          }
        }
      }
    }
  }
}
</style>
