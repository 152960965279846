<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Edit Offer
      </div>
    </div>
    <div class="modal__body page-request main">
      <div class="box box_payments">
        <div class="content">
          <div class="box-group">
            <div class="box box_left box_tmblers">
              <div class="box_ttl">Bank Type</div>
              <div class="box_list" :key="bankTypesIndex">
                <div v-for="(item, key) in bankTypes" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.name" @changeState="bankTypesChangeState(item)" />
                </div>
              </div>
              <div v-if="showOtherBankType">
                <div class="box_ttl">Bank type</div>
                <input v-model="request.other_bank_type" type="text" class="inpt inpt_acc" placeholder="Enter bank type">
              </div>
            </div>
            <div class="box box_left box_tmblers">
              <div class="box_ttl">Country</div>
              <div class="box_list" :key="countriesIndex">
                <div v-for="(item, key) in countries" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.name" @changeState="countriesChangeState(item)" />
                </div>
              </div>
              <div v-if="showOtherCountry">
                <div class="box_ttl">Country</div>
                <select class="inpt" style="width: 100%;" v-model="request.other_country" @change="selectOtherCountry(request.other_country)">
                  <option value="null">Select country</option>
                  <option v-for="item in allCountries" :key="item.id" :value="item">{{ item.name }}</option>
                </select>
              </div>
            </div>
            <div class="box box_left box_tmblers">
              <div class="box_ttl">License Type</div>
              <div class="box_list" :key="licenseTypesIndex">
                <div class="list_item" v-for="(item, key) in licenseTypes" :key="key">
                  <Tmblr 
                    :active="item.active" 
                    :text="item.name" 
                    :price="item.pivot && item.pivot.price ? +item.pivot.price : +item.price" 
                    @changeState="licenseTypesChangeState(item)" />
                  <span v-if="item.active" class="edit-price" @click.prevent="editPrice(item)">
                    <Pen />
                  </span>
                  <span v-if="item.active" class="add-dataset" @click.prevent="processDataset('licenseTypes', item)">
                    <Info />
                  </span>
                </div>
                <div v-if="showOtherLicenseType">
                  <div class="box_ttl">Other License Type</div>
                  <input v-model="request.other_license_type" type="text" class="inpt inpt_acc" placeholder="Enter license type">
                </div>
                <div v-for="(item, key) in licenseTypes" :key="'l_c_p'+key" v-if="item.active && item.edit_price">
                  <div class="box_ttl">
                    <small>
                      {{ item.name + ' Price'}}
                    </small>
                  </div>
                  <input v-model="item.custom_price" @input="calculateTotalPrice(true)" type="number" class="inpt inpt_acc" :placeholder="item.name + ' Price'">
                </div>
                <!--<div>
                  <div class="box_ttl">License Price</div>
                  <input v-model="request.license_price" @input="calculateTotalPrice()" type="number" class="inpt inpt_acc" placeholder="License Price">
                </div>-->
              </div>
            </div>
          </div>
          <div class="box-group">
            <div class="box box_left box_tmblers box_tmblers--full">
              <div class="box_ttl">Software Type</div>
              <div class="box_list row-list" :key="softwareTypesIndex">
                <div v-for="(item, key) in softwareTypes" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.name" :price="item.price ? +item.price : 0" @changeState="softwareTypesChangeState(item)" />
                  <span v-if="item.active" class="edit-price" @click.prevent="editPrice(item)">
                    <Pen />
                  </span>
                  <span v-if="item.active" class="add-dataset" @click.prevent="processDataset('softwareTypes', item)">
                    <Info />
                  </span>
                </div>
              </div>
              <div v-if="showOtherSoftwareType">
                <div class="box_ttl">Software type</div>
                <input v-model="request.other_software_type" type="text" class="inpt inpt_acc" placeholder="Enter software type">
              </div>
              <div v-for="(item, key) in softwareTypes" :key="'s_c_p'+key" v-if="item.active && item.edit_price">
                <div class="box_ttl">
                  <small>
                    {{ item.name + ' Price'}}
                  </small>
                </div>
                <input v-model="item.custom_price" @input="calculateTotalPrice(true)" type="number" class="inpt inpt_acc" :placeholder="item.name + ' Price'">
              </div>
              <!--<div>
                <div class="box_ttl">Software Price</div>
                <input v-model="request.software_price" @input="calculateTotalPrice()" type="number" class="inpt inpt_acc" placeholder="Software Price">
              </div>-->
            </div>
          </div>
          <div class="box-group">
            <div class="box box_left box_tmblers">
              <div class="box_ttl">Account Type</div>
              <div class="box_list" :key="accountTypesIndex">
                <div v-for="(item, key) in accountTypes" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.name" :price="item.price ? +item.price : 0" @changeState="accountTypesChangeState(item)" />
                  <span v-if="item.active" class="edit-price" @click.prevent="editPrice(item)">
                    <Pen />
                  </span>
                  <span v-if="item.active" class="add-dataset" @click.prevent="processDataset('accountTypes', item)">
                    <Info />
                  </span>
                </div>
              </div>
              <div v-if="showOtherAccountType">
                <div class="box_ttl">Account type</div>
                <input v-model="request.other_account_type" type="text" class="inpt inpt_acc" placeholder="Enter account type">
              </div>
              <div v-for="(item, key) in accountTypes" :key="'a_c_p'+key" v-if="item.active && item.edit_price">
                <div class="box_ttl">
                  <small>
                    {{ item.name + ' Price'}}
                  </small>
                </div>
                <input v-model="item.custom_price" @input="calculateTotalPrice(true)" type="number" class="inpt inpt_acc" :placeholder="item.name + ' Price'">
              </div>
              <div>
                <div class="box_ttl">
                  <small>
                    Account type price
                  </small>
                </div>
                <input v-model="request.account_price" @input="calculateTotalPrice()" type="number" class="inpt inpt_acc" placeholder="Enter account price">
              </div>
            </div>
            <div class="box box_left box_tmblers">
              <div class="box_ttl">Membership Type</div>
              <div class="box_list" :key="membershipTypesIndex">
                <div v-for="(item, key) in membershipTypes" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.name" :price="item.price ? +item.price : 0" @changeState="membershipTypesChangeState(item)" />
                  <span v-if="item.active" class="edit-price" @click.prevent="editPrice(item)">
                    <Pen />
                  </span>
                  <span v-if="item.active" class="add-dataset" @click.prevent="processDataset('membershipTypes', item)">
                    <Info />
                  </span>
                </div>
              </div>
              <div v-if="showOtherMembershipType">
                <div class="box_ttl">Membership type</div>
                <input v-model="request.other_membership_type" type="text" class="inpt inpt_acc" placeholder="Enter membership type">
              </div>
              <div v-for="(item, key) in membershipTypes" :key="'m_c_p'+key" v-if="item.active && item.edit_price">
                <div class="box_ttl">
                  <small>
                    {{ item.name + ' Price'}}
                  </small>
                </div>
                <input v-model="item.custom_price" @input="calculateTotalPrice(true)" type="number" class="inpt inpt_acc" :placeholder="item.name + ' Price'">
              </div>
              <div>
                <div class="box_ttl">
                  <small>
                    Membership type price
                  </small>
                </div>
                <input v-model="request.membership_price" @input="calculateTotalPrice()" type="number" class="inpt inpt_acc" placeholder="Enter membership price">
              </div>
            </div>
            <div class="box box_left box_tmblers">
              <div class="box_ttl">Operetional Type</div>
              <div class="box_list" :key="operetionalTypesIndex">
                <div v-for="(item, key) in operetionalTypes" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.name" :price="item.price ? +item.price : 0" @changeState="operetionalTypesChangeState(item)" />
                  <span v-if="item.active" class="edit-price" @click.prevent="editPrice(item)">
                    <Pen />
                  </span>
                  <span v-if="item.active" class="add-dataset" @click.prevent="processDataset('operetionalTypes', item)">
                    <Info />
                  </span>
                </div>
              </div>
              <div v-if="showOtherOperetionalType">
                <div class="box_ttl">Operetional type</div>
                <input v-model="request.other_operetional_type" type="text" class="inpt inpt_acc" placeholder="Enter operetional type">
              </div>
              <div v-for="(item, key) in operetionalTypes" :key="'o_c_p'+key" v-if="item.active && item.edit_price">
                <div class="box_ttl">
                  <small>
                    {{ item.name + ' Price'}}
                  </small>
                </div>
                <input v-model="item.custom_price" @input="calculateTotalPrice(true)" type="number" class="inpt inpt_acc" :placeholder="item.name + ' Price'">
              </div>
              <div>
                <div class="box_ttl">
                  <small>
                    Operetional type price
                  </small>
                </div>
                <input v-model="request.operetional_price" @input="calculateTotalPrice()" type="number" class="inpt inpt_acc" placeholder="Enter operetional price">
              </div>
            </div>
          </div>
          <div class="box-group">
            <div class="box box_left box_tmblers box_tmblers--full">
              <div class="box_ttl">
                Other services
              </div>
              <div class="row">
                <div class="col-other-services">
                  <input 
                    class="inpt flexible"
                    type="text" 
                    name="other_service" 
                    v-model="otherServiceName" 
                    @keyup.enter="crateOtherService" 
                  />
                  <button 
                    type="button" 
                    class="btn btn_submit" 
                    @click.prvent="crateOtherService"
                  >
                    Add
                  </button>
                </div>
                <div class="col-other-services">
                  <select 
                    class="inpt" 
                    name="other_service_select" 
                    v-model="otherServicesSelect"
                    @change="handleSelectChange"
                  >
                    <option 
                      :value="null" 
                      selected
                    >
                      Select other service
                    </option>
                    <option 
                      v-for="option in otherServicesList"
                      :key="option.id" 
                      :value="option"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="box_list row-list flex-wrap" :key="otherServicesIndex">
                <div v-for="(item, key) in otherServices" :key="key" class="list_item other-services-tmblr-col">
                  <Tmblr :class="{'pointer-events-none': +request.progress_stage >= 20}"
                    :active="item.active" 
                    :text="item.name" 
                    :price="item.price ? +item.price : 0" 
                    :salePrice="+item.sale_price"
                    @changeState="otherServicesChangeState(item)" />
                  <span v-if="item.active" class="edit-price" @click.prevent="editPrice(item)">
                    <Pen />
                  </span>
                  <span v-if="item.active" class="add-dataset" @click.prevent="processDataset('otherServices', item)">
                    <Info />
                  </span>
                </div>
              </div>
              <div v-for="(item, key) in otherServices" :key="'o_s_p'+key" v-if="item.active && item.edit_price">
                <div class="box_ttl">
                  <small>
                    {{ item.name + ' Price'}}
                  </small>
                </div>
                <input v-model="item.custom_price" @input="calculateTotalPrice(true)" type="number" class="inpt inpt_acc" :placeholder="item.name + ' Price'">
              </div>
              <div>
                <div class="box_ttl">
                  <small>
                    Other services price
                  </small>
                </div>
                <input v-model="request.other_price" @input="calculateTotalPrice()" type="number" class="inpt inpt_acc" placeholder="Enter operetional price">
              </div>
              <!--<div class="box-icon_wrap software">
                <SoftwareTypeIcon class="box-icon" />
              </div>-->
            </div>
          </div>
          <div class="box-group">
            <div class="box box_left box_tmblers box_tmblers--full">
              <div class="box_ttl">License Include</div>
              <div class="box_list row-list" :key="licenseOfferDescriptionsIndex">
                <div v-for="(item, key) in licenseOfferDescriptions" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.name" @changeState="licenseOfferDescriptionsChangeState(item)" />
                </div>
              </div>
            </div>
          </div>
          <div class="box box__other-info">
            <textarea
                v-model="request.admin_note"
                placeholder="Enter a note"
                ></textarea>
          </div>
          <div class="box box__other-info flex-wrap">
            <div class="box-col">
              <div class="box_ttl">Progress stage</div>
              <input 
                list="progress-stage-list" 
                v-model="request.progress_stage" 
                type="number" 
                min="0" 
                max="100"
                class="inpt inpt_acc" 
                placeholder="Enter progress stage" />
                <datalist id="progress-stage-list">
                  <option v-for="(item, key) in stages" :key="key" :value="item"></option>
                </datalist>
            </div>
            <div class="box-col">
              <div class="box_ttl">Deadline at</div>
              <input 
                v-model="request.implementation_period" 
                type="number" 
                min="0" 
                class="inpt inpt_acc" 
                placeholder="Enter implementaion period"
                @input="calculateEndDate" />
                <div>
                  {{ request.deadline_at | formatDate }}
                </div>
            </div>
            <div class="box-col">
              <div class="box_ttl">Request total price</div>
              <input v-model="request.price" type="number" min="0" class="inpt inpt_acc" placeholder="Enter request price">
            </div>
            <div class="box-col">
              <div class="box_ttl">Customer name</div>
              <input v-model="request.customer_name" type="text" class="inpt inpt_acc" placeholder="Enter customer name">
            </div>
            <div class="box-col wide">
              <div class="statuses-list">
                <div class="statuses-list__item" v-for="(item, key) in request.statuses" :key="key">
                  <div>
                    <h3>{{ item.name }}</h3>                    
                  </div>
                  <div class="d-flex statuses-list__item--info">
                    <span class="statuses-list__item--user">
                      {{ item.pivot.user_data ? item.pivot.user_data.name : '' }}
                    </span>
                    <span class="statuses-list__item--date">
                      {{  item.pivot.created_at | formatDateTime }}
                    </span>                  
                  </div>
                </div>
              </div>
              <select class="form-control" v-model="request.license_offer_status_id" @change="selectStatus(request.license_offer_status_id)">
                <option value="null">Set Status</option>
                <option v-for="status in statuses" :key="status.id" :value="status.id">{{ status.name }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__footer footer">
      <div class="btn btn_submit" @click="submit">
        Save Data
      </div>
    </div>
    <div v-if="isShowDatasetModal" class="inner-modal__wrapper" @click.self="closeDataset">
      <div class="inner-modal">
        <div class="modal__close">
          <div class="btn" @click.prevent="closeDataset">
            <Close class="img"/>
          </div>
        </div>
        <div class="modal__header">
          <div class="ttl">
            Add additional info
          </div>
        </div>
        <div class="modal__body page-request main">
          <div class="row">
            <div class="label">Note</div>
            <div class="data">
              <textarea name="note" rows="5" class="inpt inpt__ta" v-model="currentDataset.note"/>
            </div>
          </div>
          <div class="row">
            <div class="label">Progress Stage</div>
            <div class="data">
              <input type="text" name="progress_stage" class="inpt" v-model="currentDataset.progress_stage">
            </div>
          </div>
          <div class="row row_files">
            <div class="box box_addFiles">
              <div v-if="getUploadedFiles('docs').length" class="content content_many">
                <draggable v-model="currentDataset.datasetUpload.docs" tag="div" class="draggable">
                <div v-for="(file, key) in getUploadedFiles('docs')" :key="key" class="file">
                  <BlueFile class="img" />
                  <div class="btn btn_del" @click="delFile('docs', key, file)"><Close class="img" /></div>
                  <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'docs')"><BlueDownload class="img" /></div>
                  <div class="desc">{{ file.name }}</div>
                </div>
                </draggable>
                <div class="add">
                  <div class="btn" @click="$refs.docs[currentDataset.datasetUpload.docs.length - 1].click()"><Close class="img" /></div>
                </div>
              </div>
              <div v-else class="content">
                <div class="choose">
                  <div class="btn" @click="$refs.docs[0].click()">Documents</div>
                </div>
              </div>
                <input
                  v-for="(i, key) in currentDataset.datasetUpload.docs"
                  :key="key"
                  type="file"
                  :name="`docs_${key}`"
                  class="hide"
                  ref="docs"
                  @change="addInput($event, 'docs', key)"
                >
            </div>
          </div>
        </div>
        <div class="modal__footer footer">
          <div class="btn btn_submit" @click.prevent="submitDataset">
            Save Data
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
import Close from '../img/Close.vue'
import Tmblr from '../elem/Tmblr.vue'
import Pen from '../../components/img/Pen.vue'
import Info from '../../components/img/Info.vue'
import Vue from 'vue'
import moment from 'moment-business-days';
import BlueFile from './../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from './../img/BlueDownload.vue'

export default {
  components: {
    Close,
    Tmblr,
    Pen,
    Info,
    BlueFile,
    draggable,
    BlueDownload
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      message: '',
      error: '',
      userBankTypes: [],
      userLicenseTypes: [],
      userAccountTypes: [],
      userMembershipTypes: [],
      userOperetionalTypes: [],
      userSoftwareTypes: [],
      userOtherServices: [],
      userCountries: [],
      userLicenseOfferDescriptions: [],

      bankTypes: [],
      bankTypesIndex: 0,
      licenseTypes: [],
      licenseTypesIndex: 0,
      allLicenseTypes: [],
      allLicenseTypesIndex: 0,
      membershipTypes: [],
      membershipTypesIndex: 0,
      operetionalTypes: [],
      operetionalTypesIndex: 0,
      accountTypes: [],
      accountTypesIndex: 0,
      softwareTypes: [],
      softwareTypesIndex: 0,
      countries: [],
      countriesIndex: 0,
      allCountries: [],
      allCountriesIndex: 0,
      statuses: [],
      statusesIndex: 0,
      datasets: [],
      isShowDatasetModal: false,
      currentDataset: this.getEmptyDataset(),
      currentDatasetStep: null,
      currentDatasetStepId: null,
      otherServices: [],
      allOtherServices: [],
      otherServicesList: [],
      otherServicesSelect: null,
      otherServicesIndex: 0,
      otherServiceName: '',
      licenseOfferDescriptions: [],
      licenseOfferDescriptionsIndex: 0,

      showOtherAccountType: false,
      showOtherBankType: false,
      showOtherMembershipType: false,
      showOtherLicenseType: false,
      showOtherOperetionalType: false,
      showOtherSoftwareType: false,
      showOtherCountry: false,
      isAllLicenseTypesFetchedBlock: false,

      totalPrice: 0,
      originalTotalPrice: 0,

      request: {
        id: '',
        other_bank_type: '',
        other_license_type: '',
        other_membership_type: '',
        other_operetional_type: '',
        other_account_type: '',
        other_software_type: '',
        other_country: '',
        status: '',
        progress_stage: 0,
        license_offer_status_id: '',
        user_id: null,
      },
      original_request: {},
      errors: {
        other_bank_type: '',
        other_license_type: '',
        other_membership_type: '',
        other_operetional_type: '',
        other_account_type: '',
        other_software_type: '',
      },
      stages: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    };
  },
  
  async mounted(){

    try {
      await this.getRequest();
      //await this.getCountries();
      await this.getLicenseTypes();
      await this.getBankTypes();
      await this.getAccountTypes();
      await this.getMembershipTypes();
      await this.getOperetionalTypes();
      await this.getSoftwareTypes();
      await this.getAllCountries();
      await this.getOtherServices();
      await this.setOtherServices();
      await this.getStatuses();
      await this.getLicenseOfferDescriptions();

      setTimeout(() => {
        this.setRequestPrices();
      }, 1000);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  },
  methods: {
    async processDataset(step, item){
      let datasetId = null;

      this.currentDatasetStep = step
      this.currentDatasetStepId = item.id

      if(this.request[step].length){
        for (let i = 0; i < this.request[step].length; i++) {
          const el = this.request[step][i];
          
          if(el.id == item.id){
            datasetId = el.pivot.license_offer_dataset_id
          }
        }
      }

      this.currentDataset = await this.getDataset(step, item, datasetId);

      this.isShowDatasetModal = true;
    },
    setPivotDatasetId(datasetId){
      let step = this.currentDatasetStep;
      let id = this.currentDatasetStepId;
      
      if(this[step].length){
        for (let i = 0; i < this[step].length; i++) {
          const el = this[step][i];
          
          if(el.id == id){
            el.license_offer_dataset_id = datasetId
          }
        }
      }
    },
    closeDataset(){
      this.currentDataset = this.getEmptyDataset();
      this.currentDatasetStep = null;
      this.currentDatasetStepId = null;

      this.isShowDatasetModal = false;
    },
    async submitDataset(){
      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers/dataset'

      if(this.currentDataset.id){
        url += '/' + this.currentDataset.id
      }

      try {
        const response = await axios.post(url, {
          note: this.currentDataset.note,
          progress_stage: this.currentDataset.progress_stage,
        });
        let datasetId = response.data.id;

        await this.processFiles(datasetId)

        this.setPivotDatasetId(datasetId)

        this.closeDataset()
      }
      catch (error) {
        console.log(error);
      }
    },
    async processFiles(id){
      let data = new FormData()
      const uploadKeys = Object.keys(this.currentDataset.datasetUpload)

      for (let z = 0; z < uploadKeys.length; z++) {
        const key = uploadKeys[z]

        for (let i = 0; i < this.currentDataset.datasetUpload[key].length; i++) {
          if (this.currentDataset.datasetUpload[key][i].name !== null) {
            let f = {}
            if (this.currentDataset.datasetUpload[key][i].type == 'server') {
              f = JSON.stringify(this.currentDataset.datasetUpload[key][i])
            } else {
              data.append(key + '_names[' + i + ']', this.currentDataset.datasetUpload[key][i].name)
              f = this.currentDataset.datasetUpload[key][i].file
            }
            data.append(key + '[' + i + ']', f)
          }
        }
      }
      
      axios.post('admin/license-offers/dataset/' + id + '/files', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((response) => {
          
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    async getDataset(step, item, datasetId){
      if(datasetId == null){
        return this.getEmptyDataset()
      }

      try {
        const response = await axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers/dataset/' + datasetId);
        let dataset = response.data;
        
        dataset.datasetUpload = {docs: await this.getDatasetDocs(datasetId)};

        return dataset;
      }
      catch (error) {
        console.log(error);
      }
    },
    async getDatasetDocs(id) {
      if(id) {
        try {
          const response = await axios.get('admin/license-offers/dataset/' + id + '/get-file/docs');
          
          let docsData = response.data
          
          if (docsData.length > 0) {
            return docsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              };
            });
          }

          return [{
            id: null,
            uuid: null,
            name: null,
            type: '',
            url: null,
            order: null
          }]
        }
        catch (error) {
          console.dir(error);
        }
      }
    },
    getEmptyDataset(){
      return {
        progress_stage: 0,
        note: '',
        datasetUpload: {
          docs: [{ name: null }],
        }
      }
    },
    getUploadedFiles(el) {
      return this.currentDataset.datasetUpload[el].filter(e => e.name !== null)
    },
    delFile(name, key, file) {
      if (this.currentDataset.datasetUpload[name].length > 1) {
        this.currentDataset.datasetUpload[name].splice(key, 1)
      } else {
        this.currentDataset.datasetUpload[name][0].name = null
      }

      if(file.id && this.currentDataset.id){
        axios.post('admin/license-offers/dataset/' + this.currentDataset.id +'/delete-file', {
        collection: name,
        media_id: file.id,
      })
        .then(function () {
          
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    addInput(event, name, key) {
      if (this.$refs[name][key].files.length) {
        const file_name = this.$refs[name][key].files[0].name
        if (this.getUploadedFiles(name).some(e => e.name == file_name)) {
          this.$noty.error('Error! A file with this name has already been added.')
          return false
        }

        const extension = this.$refs[name][key].files[0].name.split('.').pop()
        const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx', 'zip', 'rar', '7z', 'tar']

        if (!availableExtensionsArr.includes(extension.toLowerCase())) {
          this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
          return false
        }

        if (this.$refs[name][key].files[0].size / 1024 / 1024 > 5) {
          this.$noty.error('File is too large. Maximum - 5MB.')
          return false
        }

        this.currentDataset.datasetUpload[name] = this.currentDataset.datasetUpload[name].filter(e => e.name !== null)
        if (this.$refs[name].length < 2) {
          this.currentDataset.datasetUpload[name].push({ name: file_name, file: event.target.files[0]})
        } else {
          this.$noty.error('Maximum - 2 files')
        }
      }
    },
    downloadFile(file, type) {
      const id = this.currentDataset.id

      if(file.url){
        this.openFile(file.url)
      }
      else if(id){
        axios.post('admin/license-offers/dataset/' + id +'/get-file-link', {
        collection: type,
        media_id: file.id,
      })
        .then(function (response) {
          if (response.data) {
            const url = response.data
            var fileURL = response.data;
            this.openFile(fileUrl);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    openFile(url){
      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.setAttribute('target', '_blank')
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    calculateEndDate() {
      const currentDate = moment();
      const remainingWorkDays = this.request.implementation_period;
      const endDate = currentDate.businessAdd(remainingWorkDays)._d;
      const formattedEndDate = moment(endDate).format('YYYY-MM-DD HH:mm:ss');

      this.request.deadline_at = formattedEndDate;
    },
    setRequestPrices(){
      const parts = ['licenseTypes', 'softwareTypes', 'accountTypes', 'membershipTypes', 'operetionalTypes', 'otherServices'];
      let self = this;
      
      parts.forEach((part) => {
        if(this.request.hasOwnProperty(part) && this.hasOwnProperty(part)){
          const requestValues = this.request[part]
          let values = this[part]
          
          requestValues.forEach((item, i) => {
            if(item.pivot && item.pivot.price != undefined && +item.pivot.price != +item.price){
              let el = values.find(value => value.id === item.id && value.active === true);

              self.$set(el, 'edit_price', true);
              self.$set(el, 'custom_price', item.pivot.price)
            }
          });
        }
      });
    },
    selectStatus(id){
      for (let i = 0; i < this.statuses.length; i++) {
        if(this.statuses[i].id == id){
          let status = this.statuses[i]

          if(status.progress_stage){
            this.request.progress_stage = status.progress_stage
          }
        }
      }
    },
    editPrice(item){
      if (!item.hasOwnProperty('edit_price')) {
        this.$set(item, 'edit_price', true);
      }
      else{
        this.$delete(item, 'edit_price');
      }

      if (!item.hasOwnProperty('custom_price')) {
        this.$set(item, 'custom_price', item.price);
      }
      else{
        this.$delete(item, 'custom_price');
      }

      this.calculateTotalPrice(true)
    },
    clearErrors () {
      for (let key in this.errors) {
        this.errors[key] = ''
      }
    },
    getFormattedPriceArray(array) {
      let totalArrayPrice = 0;
      
      const formattedArray = array.reduce(function(acc, el) {
        let price = el.pivot && el.pivot.price !== undefined ? +el.pivot.price : +el.price;
        let datasetId = el.license_offer_dataset_id !== undefined || el.license_offer_dataset_id !== null ? el.license_offer_dataset_id : null
        
        price = el.custom_price != undefined ? +el.custom_price : +price;
        
        acc[el.id] = { price: +price, license_offer_dataset_id: datasetId };

        totalArrayPrice += +acc[el.id].price;

        return acc;
      }, {});

      return { formattedArray, totalArrayPrice };
    },
    async submit() {
      this.clearErrors()

      const that = this
      const id = this.request.id

      const userBankTypeIds = this.bankTypes.filter(el => el.active).map(el => el.id);
      const licenseOfferDescriptionIds = this.licenseOfferDescriptions.filter(el => el.active).map(el => el.id);
      let userCountriesIds = this.countries.filter(el => el.active && el.id !== null).map(el => el.id);

      if(this.request.other_country && this.request.other_country.id){
        userCountriesIds.push(this.request.other_country.id)
      }

      const getPriceData = (array) => {
        const { formattedArray, totalArrayPrice } = this.getFormattedPriceArray(array);

        return { ids: array, price: totalArrayPrice, formattedArray };
      };

      const dataMap = {
        licenseTypes: getPriceData(this.licenseTypes.filter(el => el.active)),
        softwareTypes: getPriceData(this.softwareTypes.filter(el => el.active)),
        accountTypes: getPriceData(this.accountTypes.filter(el => el.active)),
        membershipTypes: getPriceData(this.membershipTypes.filter(el => el.active)),
        operetionalTypes: getPriceData(this.operetionalTypes.filter(el => el.active)),
        otherServices: getPriceData(this.otherServices.filter(el => el.active)),
      };
      
      await axios.post(process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers/update/' + id, {
        bank_types: userBankTypeIds,
        license_offer_descriptions: licenseOfferDescriptionIds,
        license_types: dataMap.licenseTypes.formattedArray,
        software_types: dataMap.softwareTypes.formattedArray,
        account_types: dataMap.accountTypes.formattedArray,
        membership_types: dataMap.membershipTypes.formattedArray,
        operetional_types: dataMap.operetionalTypes.formattedArray,
        other_services: dataMap.otherServices.formattedArray,
        license_price: this.request.license_price,
        software_price: this.request.software_price,
        other_price: this.request.other_price,
        account_price: this.request.account_price,
        membership_price: this.request.membership_price,
        operetional_price: this.request.operetional_price,
        countries: userCountriesIds,
        price: this.request.price,
        admin_note: this.request.admin_note,
        license_offer_status_id: this.request.license_offer_status_id,
        progress_stage: this.request.progress_stage,
        other_bank_type: this.request.other_bank_type,
        other_account_type: this.request.other_account_type,
        other_license_type: this.request.other_license_type,
        other_membership_type: this.request.other_membership_type,
        other_operetional_type: this.request.other_operetional_type,
        implementation_period: this.request.implementation_period,
        deadline_at: this.request.deadline_at,
        customer_name: this.request.customer_name
      }).then(function (response) {
          if (response.data.error) {
            for (let serverError in response.data.errors) {
              for (let cilentError in that.errors) {
                if (serverError === cilentError && response.data.errors[serverError][0]) {
                  that.errors[cilentError] = response.data.errors[serverError][0]  
                }
              }
            }
            return
          }
          that.$noty.success("Your request data was successfully changed")

          that.close();
        }).catch(function (error) {
          console.log(error);
          if (error) {
            for (let serverError in error.errors) {
              for (let cilentError in that.errors) {
                if (serverError === cilentError && response.data.errors[serverError][0]) {
                  that.errors[cilentError] = response.data.errors[serverError][0]  
                }
              }
            }
            return
          }

          that.$noty.error("Your request data is not correct")
        })
    },
    crateOtherService(){
      if(this.otherServiceName){
        const that = this

        axios.post(process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers/other-service', {
          name: this.otherServiceName
        })
        .then(function (response) {
          if(response.data.success){
            let otherService = response.data.otherService

            otherService.active = true
            
            that.otherServices.push(otherService)

            that.otherServicesIndex++

            that.otherServiceName = ''

            that.getOtherServices()
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    getOtherServices() {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/data/other-services?license_offer=' + this.request.id)
        .then(function (response) {
          that.allOtherServices = response.data
          that.otherServicesList = that.filterOtherServices(response.data)
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async setOtherServices(){
      let otherServices = this.userOtherServices

      for(let index = 0; index < otherServices.length; index++) {
        otherServices[index].active = true
      }

      this.otherServices = otherServices
    },
    filterOtherServices(items){
      let filtered = items.filter(item => {
        return !this.otherServices.some(selectedItem => selectedItem.id == item.id)
      })

      return filtered
    },
    handleSelectChange(){
      if(this.otherServicesSelect){
        let item = this.otherServicesSelect

        item.active = true

        this.otherServices.push(item)

        this.otherServicesIndex++

        this.otherServicesSelect = null

        this.getOtherServices()
      }
    },
    otherServicesChangeState(item){
      item.active = !item.active

      this.otherServicesIndex++

      this.calculateTotalPrice(true)

      this.getOtherServices()
    },
    bankTypesChangeState (item) {
      const index = this.bankTypes.findIndex(bankType => {
          return (item.id === bankType.id)
      })

      this.unActiveItem(this.bankTypes, index, 'showOtherBankType')

      this.$set(this.bankTypes[index], 'active', !item.active)
      if (this.bankTypes[index].name == 'Other') {
        if (this.bankTypes[index].active) {
          this.showOtherBankType = true
        } else {
          this.showOtherBankType = false
          this.request.other_bank_type = ''
        }
      }
      
      this.bankTypesIndex++
    },
    licenseOfferDescriptionsChangeState (item) {
      const index = this.licenseOfferDescriptions.findIndex(desc => {
          return (item.id === desc.id)
      })

      this.$set(this.licenseOfferDescriptions[index], 'active', !item.active)
      
      this.licenseOfferDescriptionsIndex++
    },
    softwareTypesChangeState (item) {
      const index = this.softwareTypes.findIndex(softwareType => {
          return (item.id === softwareType.id)
      })

      this.unActiveItem(this.softwareTypes, index, 'showOtherSoftwareType')

      this.$set(this.softwareTypes[index], 'active', !item.active)

      this.request.software_price = this.softwareTypes[index].price

      if (this.softwareTypes[index].name == 'Other') {
        if (this.softwareTypes[index].active) {
          this.showOtherSoftwareType = true
        } else {
          this.showOtherSoftwareType = false
          this.request.other_software_type = ''
        }
      }

      this.calculateTotalPrice(true);
      
      this.softwareTypesIndex++
    },
    licenseTypesChangeState (item) {
      const index = this.licenseTypes.findIndex(licenseType => {
          return (item.id === licenseType.id)
      })

      this.unActiveItem(this.licenseTypes, index, 'showOtherLicenseType')

      this.$set(this.licenseTypes[index], 'active', !item.active)

      this.request.license_price = this.licenseTypes[index].pivot && this.licenseTypes[index].pivot.price ? +this.licenseTypes[index].pivot.price : +this.licenseTypes[index].price

      if (this.licenseTypes[index].name == 'Other') {
        if (this.licenseTypes[index].active) {
          this.showOtherLicenseType = true
        } else {
          this.showOtherLicenseType = false
          this.request.other_license_type = ''
        }
      }
      
      this.calculateTotalPrice(true);

      this.licenseTypesIndex++
    },
    accountTypesChangeState (item) {
      const index = this.accountTypes.findIndex(accountType => {
          return (item.id === accountType.id)
      })

      this.$set(this.accountTypes[index], 'active', !item.active)
      if (this.accountTypes[index].name == 'Other') {
        if (this.accountTypes[index].active) {
          this.showOtherAccountType = true
        } else {
          this.showOtherAccountType = false
          this.request.other_account_type = ''
        }
      }

      this.calculateTotalPrice(true);
      
      this.accountTypesIndex++
    },
    membershipTypesChangeState (item) {
      const index = this.membershipTypes.findIndex(membershipType => {
          return (item.id === membershipType.id)
      })

      this.$set(this.membershipTypes[index], 'active', !item.active)
      if (this.membershipTypes[index].name == 'Other') {
        if (this.membershipTypes[index].active) {
          this.showOtherMembershipType = true
        } else {
          this.showOtherMembershipType = false
          this.request.other_membership_type = ''
        }
      }

      this.calculateTotalPrice(true);
      
      this.membershipTypesIndex++
    },
    operetionalTypesChangeState (item) {
      const index = this.operetionalTypes.findIndex(operetionalType => {
          return (item.id === operetionalType.id)
      })

      this.$set(this.operetionalTypes[index], 'active', !item.active)
      if (this.operetionalTypes[index].name == 'Other') {
        if (this.operetionalTypes[index].active) {
          this.showOtherOperetionalType = true
        } else {
          this.showOtherOperetionalType = false
          this.request.other_operetional_type = ''
        }
      }

      this.calculateTotalPrice(true);
      
      this.operetionalTypesIndex++
    },
    selectOtherCountry(item){
      this.isAllLicenseTypesFetchedBlock = true;

      this.getLicenseTypesByCountry(item);
    },
    countriesChangeState (item) {
      const index = this.countries.findIndex(country => {
          return (item.id === country.id)
      })

      this.unActiveItem(this.countries, index, 'showOtherCountry')
      
      this.$set(this.countries[index], 'active', !item.active)
      if (this.countries[index].name == 'Other') {
        if (this.countries[index].active) {
          this.showOtherCountry = true
        } else {
          this.showOtherCountry = false
          this.request.other_country = ''
        }
      }

      this.isAllLicenseTypesFetchedBlock = true;

      this.getLicenseTypesByCountry(this.countries[index]);
      
      this.countriesIndex++
    },
    calculateTotalPrice(recalculatePartsPrice = false){
      const priceParts = ['licenseTypes', 'softwareTypes', 'accountTypes', 'membershipTypes', 'operetionalTypes', 'otherServices'];
      const pricePartsMap = {licenseTypes: 'license_price', softwareTypes: 'software_price', accountTypes: 'account_price', membershipTypes: 'membership_price', operetionalTypes: 'operetional_price', otherServices: 'other_price'};

      this.totalPrice = 0;

      if(recalculatePartsPrice){
        priceParts.forEach(property => {
          let partPrice = 0;

          this[property].forEach((item, i) => {
            if(item.active){
              let price = item.pivot && item.pivot.price ? +item.pivot.price : +item.price;
              
              partPrice += item.custom_price != undefined ? +item.custom_price : +price;
            }
          });

          if(pricePartsMap.hasOwnProperty(property)){
            this.$set(this.request, pricePartsMap[property], partPrice);
          }
        });
      }

      priceParts.forEach(property => {
        if(pricePartsMap.hasOwnProperty(property)){
          this.totalPrice += +this.request[pricePartsMap[property]];
        }
      });

      this.request.price = this.totalPrice;
    },
    getLicenseTypesByCountry(country, isFirst = false){
      if(country && country.license_types && country.license_types.length){
        Vue.set(this, 'licenseTypes', country.license_types);
      }
      else{
        Vue.set(this, 'licenseTypes', this.allLicenseTypes);
      }
      
      for (let i = 0; i < this.licenseTypes.length; i++) {
        Vue.set(this.licenseTypes[i], 'active', false);

        if(isFirst){
          for (let z = 0; z < this.userLicenseTypes.length; z++) {
              if (this.licenseTypes[i].id === this.userLicenseTypes[z].id) {
                Vue.set(this.licenseTypes[i], 'active', true);
                if (this.licenseTypes[i].name == 'Other') {
                  Vue.set(this.licenseTypes[i], 'active', true);
                }
              }
            }
        }
      }
    },
    unActiveItem(array, selectedIndex, property){
      array.forEach((item, i) => {
        if(i !== selectedIndex){
          item.active = false;
          this[property] = false;

          if(property === 'showOtherCountry'){
            this.request.other_country = ''
          }
        }
      });
    },
    async getBankTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/data/bank-types')
        .then(function (response) {
          that.bankTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async getSoftwareTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/data/software-types')
        .then(function (response) {
          that.softwareTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async getLicenseOfferDescriptions () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/data/license-offer-descriptions')
        .then(function (response) {
          that.licenseOfferDescriptions = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async getLicenseTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/data/license-types')
        .then(function (response) {
          that.licenseTypes = response.data
          that.allLicenseTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    }, 
    async getCountries () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/data/license-countries')
        .then(function (response) {
          that.countries = response.data
          that.countries.push({name: 'Other', code: 'ot', id: null})
          //that.getAllCountries();
        })
        .catch(function (error) {
          console.log(error);
        })
    }, 
    async getAllCountries () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/countries')
        .then(function (response) {
          that.countries = response.data.filter(item => {
            return item.is_for_license;
          })

          that.countries.push({name: 'Other', code: 'ot', id: null})

          that.allCountries = response.data.filter(item => {
            return !that.countries.some(country => country.id === item.id)
          })
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async getAccountTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/data/account-types')
        .then(function (response) {
          that.accountTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async getMembershipTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/data/membership-types')
        .then(function (response) {
          that.membershipTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async getOperetionalTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/data/operetional-types')
        .then(function (response) {
          that.operetionalTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async getStatuses () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/data/license-offer-statuses')
        .then(function (response) {
          that.statuses = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async getRequest () {
      const that = this
      const id = this.request.id

      await axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers/' + this.options.id)
        .then(function (response) {
          that.request = response.data.data
          that.original_request = response.data.data

          that.userBankTypes = that.request.bankTypes
          that.userLicenseTypes = that.request.licenseTypes
          that.userAccountTypes = that.request.accountTypes
          that.userMembershipTypes = that.request.membershipTypes
          that.userOperetionalTypes = that.request.operetionalTypes
          that.userSoftwareTypes = that.request.softwareTypes
          that.userCountries = that.request.countries
          that.userOtherServices = that.request.otherServices
          that.userLicenseOfferDescriptions = that.request.licenseOfferDescriptions
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    close() {
      this.$emit("close");
    }
  },
  computed: {
    isAllBankTypesFetched() {
      if (this.bankTypes.length > 0 && this.userBankTypes.length >0) {
        return true
      }

      return false
    },
    isAllSoftwareTypesFetched() {
      if (this.softwareTypes.length > 0 && this.userSoftwareTypes.length >0) {
        return true
      }

      return false
    },
    isAllLicenseTypesFetched() {
      if (this.licenseTypes.length > 0 && this.userLicenseTypes.length >0) {
        return true
      }
      
      return false
    },
    isAllAccountTypesFetched() {
      if (this.accountTypes.length > 0 && this.userAccountTypes.length >0) {
        return true
      }
      
      return false
    },
    isAllMembershipTypesFetched() {
      if (this.membershipTypes.length > 0 && this.userMembershipTypes.length >0) {
        return true
      }
      
      return false
    },
    isAllOperetionalTypesFetched() {
      if (this.operetionalTypes.length > 0 && this.userOperetionalTypes.length >0) {
        return true
      }
      
      return false
    },
    isAllLicenseOfferDescriptionsFetched() {
      if (this.licenseOfferDescriptions.length > 0 && this.userLicenseOfferDescriptions.length >0) {
        return true
      }
      
      return false
    },
    isAllCountriesFetched() {
      if (this.countries.length > 0 && this.allCountries.length && this.userCountries.length >0) {
        return true
      }
      
      return false
    },
    isStatusesFetched() {
      if (this.statuses.length > 0) {
        return true
      }
      
      return false
    },
    isAllInfoFetched(){
      if(
        this.isAllAccountTypesFetched && 
        this.isAllBankTypesFetched && 
        this.isAllCountriesFetched && 
        this.isAllLicenseTypesFetched && 
        this.isAllMembershipTypesFetched && 
        this.isAllOperetionalTypesFetched && 
        this.isAllSoftwareTypesFetched &&
        this.isStatusesFetched
      ){
        return true
      }

      return false
    }
  },
  watch: {
    isAllBankTypesFetched: {
      handler: function(val, oldVal) {
        if(oldVal == false){
          for (let i = 0; i < this.bankTypes.length; i++) {
            this.bankTypes[i].active = false
            for (let z = 0; z < this.userBankTypes.length; z++) {
              if (this.bankTypes[i].id === this.userBankTypes[z].id) {
                this.bankTypes[i].active = true
                if (this.bankTypes[i].name == 'Other') {
                  this.showOtherBankType = true
                }
              }
            }
          }
        }
      },
      deep: true
    },
    isAllSoftwareTypesFetched: {
      handler: function(val, oldVal) {
        if(oldVal == false){
          for (let i = 0; i < this.softwareTypes.length; i++) {
            this.softwareTypes[i].active = false
            for (let z = 0; z < this.userSoftwareTypes.length; z++) {
              if (this.softwareTypes[i].id === this.userSoftwareTypes[z].id) {
                this.softwareTypes[i].active = true
                if (this.softwareTypes[i].name == 'Other') {
                  this.showOtherSoftwareType = true
                }
              }
            }
          }
        }
      },
      deep: true
    },
    isAllLicenseOfferDescriptionsFetched: {
      handler: function(val, oldVal) {
        for (let i = 0; i < this.licenseOfferDescriptions.length; i++) {
            this.licenseOfferDescriptions[i].active = false
            for (let z = 0; z < this.userLicenseOfferDescriptions.length; z++) {
              if (this.licenseOfferDescriptions[i].id === this.userLicenseOfferDescriptions[z].id) {
                this.licenseOfferDescriptions[i].active = true
              }
            }
          }
      },
      deep: true
    },
    isAllLicenseTypesFetched: {
      handler: function(val, oldVal) {
        if(oldVal == false){
          for (let i = 0; i < this.licenseTypes.length; i++) {
            this.licenseTypes[i].active = false
            for (let z = 0; z < this.userLicenseTypes.length; z++) {
              if (this.licenseTypes[i].id === this.userLicenseTypes[z].id) {
                this.licenseTypes[i].active = true
                if (this.licenseTypes[i].name == 'Other') {
                  this.showOtherLicenseType = true
                }
              }
            }
          }
        }
      },
      deep: true
    },
    isAllAccountTypesFetched: {
      handler: function(val, oldVal) {
        if(oldVal == false){
          for (let i = 0; i < this.accountTypes.length; i++) {
            this.accountTypes[i].active = false
            for (let z = 0; z < this.userAccountTypes.length; z++) {
              if (this.accountTypes[i].id === this.userAccountTypes[z].id) {
                this.accountTypes[i].active = true
                if (this.accountTypes[i].name == 'Other') {
                  this.showOtherAccountType = true
                }
              }
            }
          }
        }
      },
      deep: true
    },
    isAllMembershipTypesFetched: {
      handler: function(val, oldVal) {
        if(oldVal == false){
          for (let i = 0; i < this.membershipTypes.length; i++) {
            this.membershipTypes[i].active = false
            for (let z = 0; z < this.userMembershipTypes.length; z++) {
              if (this.membershipTypes[i].id === this.userMembershipTypes[z].id) {
                this.membershipTypes[i].active = true
                if (this.membershipTypes[i].name == 'Other') {
                  this.showOtherMembershipType = true
                }
              }
            }
          }
        }
      },
      deep: true
    },
    isAllOperetionalTypesFetched: {
      handler: function(val, oldVal) {
        if(oldVal == false){
          for (let i = 0; i < this.operetionalTypes.length; i++) {
            this.operetionalTypes[i].active = false
            for (let z = 0; z < this.userOperetionalTypes.length; z++) {
              if (this.operetionalTypes[i].id === this.userOperetionalTypes[z].id) {
                this.operetionalTypes[i].active = true
                if (this.operetionalTypes[i].name == 'Other') {
                  this.showOtherOperetionalType = true
                }
              }
            }
          }
        }
      },
      deep: true
    },
    isAllCountriesFetched: {
      handler: function(val, oldVal) {
        if(oldVal == false){
          for (let i = 0; i < this.countries.length; i++) {
            this.countries[i].active = false
            for (let z = 0; z < this.userCountries.length; z++) {
              if (this.countries[i].id === this.userCountries[z].id) {
                this.countries[i].active = true

                this.getLicenseTypesByCountry(this.countries[i], true);
              }
            }
          }
          
          const userCountryIds = this.userCountries.map(country => country.id)
          
          for (let i = 0; i < this.allCountries.length; i++) {
            if (userCountryIds.includes(this.allCountries[i].id)) {
              const nullIndex = this.countries.findIndex(country => country.id === null)

              if (nullIndex !== -1) {
                this.countries[nullIndex].active = true
                this.showOtherCountry = true
                this.request.other_country = this.allCountries[i]

                this.getLicenseTypesByCountry(this.allCountries[i], true);

                break
              }
            }
          }
          this.countriesIndex++
          this.allCountriesIndex++
        }
      },
      deep: true
    },
    /*'request.other_country': {
      handler: function(newVal, oldVal) {
        if(newVal != oldVal){
          this.getLicenseTypesByCountry(newVal);
        }
      },
      deep: true
    }*/
  }
};
</script>

<style lang="scss" scoped>
.modal__wrapper {
  background: #272E35;
  color: #fff;

  .modal__body {
    font-size: 10px;
    margin-bottom: 20px;
  }

  .tmblr {
    margin: 5px 0px;
  }
}

.page-request {
  .box {
    background: #272E35;
    &-group{
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      align-items: stretch;
    }
    &__other-info{
      padding: 1.5rem;
      background-color: #22252B;
      display: flex;
      justify-content: space-between;
      gap: 24px;
      width: 100%;
      &.flex-wrap{
        flex-wrap: wrap;
      }
      & > .box-col {

        flex: 0 0 20%;
        &.wide{
          flex: 0 0 32%;
        }
        input{
          width: 100%;
        }
      }
    }
    &_list.row-list{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      &.flex-wrap{
        flex-wrap: wrap;
        gap: 24px;
      }
      .other-services-tmblr-col{
        width: 25%;
        flex: 0 0 25%;
        max-width: 25%;
        margin-bottom: 8px;
      }
    }
    &_tmblers {
      padding: 1.5rem 2.5rem;
      background-color: #22252B;
      width: 31.5%;
      &--full{
        width: 100%;
      }
      .box_list-2 {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: .75rem;

        .list_item {
          min-width: 45%;

          @media (max-width: 1600px) {
            width: 100%;
          }
        }
      }

      .row {
        display: flex;

        .box_list {
          margin-right: 2rem;
          margin-bottom: 1rem;
        }
      }
      .row{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;
        margin-bottom: 24px;
      }
      .col-other-services{
        width: 40%;
        flex: 0 0 40%;
        display: flex;
        gap: 12px;
      }
      .inpt{
        height: 2.75rem;
        color: #09090B;
        padding: 0.25rem 0.75rem;
        background-color: inherit;
        border: 1px solid #1763fb;
        border-radius: 6px;
        color: #fff;
        &.flexible{
          width: 70%;
          max-width: 70%;
  
        }
      }
      .btn{
        border: none;
        border-radius: 3px;
        background: #1763fb;
        color: #fff;
        height: 2.75rem;
        padding: 0.25rem 0.75rem;
        width: 25%;
        margin: 0;
      }
      .box_ttl {
        font-size: 14px;
        padding-bottom: .5rem;
        small{
          font-size: 8px;
        }
      }

      .inpt {
        width: 100%;
      }
    }

    &_left {
      @media (max-width: 1440px) {
        padding-left: 1.5rem;
      }
    }

    &_right {
      width: 25%;

      @media (max-width: 1440px) {
        padding-right: 1.5rem;
      }
    }

    &_arrow-r {
      position: relative;
      margin-right: 3rem;

      .arrow-r {
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
      }
    }

    &_arrow-l {
      position: relative;
      margin-left: 3rem;

      .arrow-r {
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
      }
    }

    &_prcs {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(23, 99, 251, 0.1);
      padding: 1rem 0;

      > :nth-child(2) {
        border-left: 2px solid rgba(23, 99, 251, 0.1);
        border-right: 2px solid rgba(23, 99, 251, 0.1);
      }

      .block {
        width: calc(33% - .5rem);
        padding: .25rem 1.5rem;

        .ttl {
          font-size: 10px;
          text-align: left;
          color: rgba(255, 255, 255, 0.5);
        }

        .val {
          font-size: 1.5rem;

          &-text {
            font-size: 1rem;
          }
        }
      }
    }

    &_payments {
      .content {
        padding-top: 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 24px;

        .block {
          padding-bottom: 1rem;

          .ttl {
            text-align: center;

            svg.img {
              width: 2.5rem;
              height: 2.5rem;
            }
          }

          .main {
            display: flex;
            align-items: center;
            justify-content: center;

            &_center {
              width: 45%;
            }
          }
        }

        .footer {
          display: flex;
          justify-content: flex-end;
          padding-bottom: 1rem;
        }
      }
    }
    .box_list{
      .list_item{
        position: relative;
        .edit-price, .add-dataset{
          position: absolute;
          right: -18px;
          top: 0;
          bottom: 0;
          width: 12px;
          height: 12px;
          display: flex;
          align-items: center;
          height: 100%;
          justify-content: center;
          svg{
            width: 12px;
            height: 12px;
            max-width: 12px;
            max-height: 12px;
            path{
              stroke: #ababb2
            }
          }
        }
        .add-dataset{
          right: -36px;
          svg{
            fill: #1763fb;
            path{
              stroke: #1763fb;
            }
          }
        }
      }
    }
  }
  .arrow-r {
    position: absolute;
    width: 3rem;
    height: 3rem;
    background: linear-gradient(113.24deg, #1763fb 16.01%, #1763fb 106.71%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep svg {
      width: 65%;
      height: 65%;

      path {
        fill: #ffffff;
      }
    }
  }

  .inpt {
    &_procCurr {
      width: 50%;
      margin-left: 45%;
    }
  }
}
.statuses-list{
  &__item{
    border-bottom: 1px dashed #ababb2;
    padding-bottom: 8px;
    margin-bottom: 8px;
    &--info{
      display: flex;
      justify-content: space-between;
    }
  }
}
.btn_submit {
  padding: 1.25rem 3.5rem;
  margin: .25rem .75rem;
  background: linear-gradient(113.24deg, #1763fb 16.01%, #1763fb 106.71%);
  box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
  border-radius: 6px;
}
textarea{
  height: 120px;
  width: 100%;
  border-radius: 3px;
  background: #1C2126;
  border: 1px solid #2F363D;
  color: #fff;
  padding: 8px;
  resize: vertical;
}
.inner-modal__wrapper{
  position: fixed;
  top: -100%;
  left: -100%;
  right: -100%;
  bottom: -100%;
  background: rgba(0, 0, 0, 0.5);
  .modal__body{
    display: block;
  }
  .inner-modal{
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #272E35;
    max-width: 100%;
    width: 520px;
    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;
      flex-wrap: wrap;

      .label {
        width: 100%;
        padding-right: .75rem;
        font-weight: bold;
      }

      .data {
        flex-grow: 1;
        width: 100%;

        .inpt {
          width: 100%;
          color: #fff;

          &__ta {
            height: 7rem;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          width: 30%;
          //width: calc(50% - .75rem);
          //margin: .75rem 0;
          //padding: 1rem 1.75rem;
          //background: #22252B;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #fff;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
}
.other-services-tmblr-col{
  max-width: 25%;
  flex: 0 0 25%;
  width: 25%;
}
</style>
