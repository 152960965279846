<template>
  <div class="auth-wrapper">
    <slot/>
  </div>
</template>

<script>
export default {
	name: 'AuthLayout',
}
</script>

<style lang="scss" scoped>
.auth-wrapper {
  background: #fff;
  background-color: #fff !important;
  background: url('/img/nero.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
