<template>
  <overlay-scrollbars class="page page-softwareNet" ref="os">
    <div class="box box__requests">
      <div class="header"></div>
      <div class="content">
        <div class="block">
          <div class="hdr">
            <div class="ttl">Software Requests</div>
          </div>
          <Table
            :options="getRequestsTableData"
            @more-modal="openRequestMoreModal"
            @change-page="getRequests"
            @scroll-top="toElTop"
          />
        </div>
        <div class="block">
          <div class="hdr">
            <div class="ttl">Additional Services Requests</div>
          </div>
          <Table
            :options="getServicesRequestsTableData"
            @change-page="getServicesRequests"
            @scroll-top="toElTop"
          />
        </div>
      </div>
    </div>
    <div class="box box__offers">
      <div class="header"></div>
      <div class="content">
        <div class="block">
          <div class="hdr">
            <div class="ttl">Software Offers</div>
            <div class="btn btn__createOffer" @click="openCreateOfferModal">Create</div>
          </div>
          <Table
            :options="offersTableData"
            @editItem="editOffers"
            @toggleProperty="toggleProperty"
            @deleteItem="deleteOffers"
            @change-page="getOffers"
            @scroll-top="toElTop"
          />
        </div>
        <div class="block">
          <div class="hdr">
            <div class="ttl">Software Options</div>
            <div class="btn btn__createOffer" @click="openCreateOptionModal">Create</div>
          </div>
          <Table
            :options="optionsTableData"
            @editItem="editOptions"
            @deleteItem="deleteOptions"
            @change-page="getOptions"
            @scroll-top="toElTop"
          />
        </div>
        <div class="block">
          <div class="hdr jcsb">
            <div class="hdr__l">
              <div class="ttl">Additional services</div>
              <div class="btn btn__create" @click="openCreateAddServiceModal">Create</div>
            </div>
            <div class="hdr__r">
              <div
                class="btn" :class="{ 'btn--active': showProvidersAddServices }"
                @click="toggleAddServices"
              >
                Providers
              </div>
            </div>
          </div>
          <Table
            :options="addServicesTableData"
            @editItem="editAddService"
            @deleteItem="deleteAddService"
            @change-page="getAddServices"
            @scroll-top="toElTop"
          />
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Table from '@/components/elem/Table'

export default {
  name: 'SoftwareNet',
  components: {
    Table
  },
  data: () => ({
    requestsTableData: {
      body: [],
      paginationData: null
    },
    servicesRequestsTableData: {
      body: [],
      paginationData: null
    },
    showProvidersAddServices: false
  }),
  computed: {
    ...mapState({
      offersTableData: state => state.software_net.offersTableData,
      optionsTableData: state => state.software_net.optionsTableData,
      addServicesTableData: state => state.software_net.addServicesTableData,
    }),
    getRequestsTableData () {
      let data = {
        // colsWidth: ['6%', '10%', '24%', '40%', '15%', '5%'],
        colsWidth: ['6%', '10%', '24%', '40%', '15%'],
        header: [
          { ttl: 'ID' },
          { ttl: 'User ID' },
          { ttl: 'Software Offer' },
          { ttl: 'Options' },
          // { ttl: 'Payment Methods' },
          { ttl: 'Created At' },
          // { ttl: 'More' }
        ],
        body: [],
        paginationData: this.requestsTableData.paginationData
      }

      if (this.requestsTableData.body.length > 0) {
        let bodyArr = []
        this.requestsTableData.body.forEach(el => {
          let optionsList = ''
          if (this.optionsTableData.body) {
            el.options.forEach((opt, key, array) => {
              if (+el.user_id === +opt.user_id) {
                const option = this.optionsTableData.body.find(el => +el[0] === +opt.software_option_id)
                optionsList += option[1]
                if (key !== array.length - 1) {
                  optionsList += ', '
                }
              }
            })
          }

          // let pmList = ''
          // el.payment_methods.forEach((pm, key, array) => {
          //   pmList += pm.name
          //   if (key !== array.length - 1) {
          //     pmList += ', '
          //   }
          // })

          bodyArr.push([
            el.id,
            el.user_id,
            el.software_offer.name,
            optionsList,
            // pmList,
            el.created_at,
            // { type: 'more' }
          ])
        })
        data.body = bodyArr
      }
      return data
    },
    getServicesRequestsTableData () {
      let data = {
        colsWidth: ['6%', '10%', '24%', '40%', '15%'],
        header: [
          { ttl: 'ID' },
          { ttl: 'User ID' },
          { ttl: 'Services Name' },
          { ttl: 'Price' },
          { ttl: 'Created At' }
        ],
        body: [],
        paginationData: this.servicesRequestsTableData.paginationData
      }

      if (this.servicesRequestsTableData.body.length > 0) {
        let bodyArr = []
        this.servicesRequestsTableData.body.forEach(el => {
          bodyArr.push([
            el.id,
            el.user.id,
            el.service.service_name,
            el.service.price || '',
            el.created_at
          ])
        })
        data.body = bodyArr
      }
      return data
    }
  },
  created () {
    this.getRequests()
    this.getServicesRequests()
    this.getOffers()
    this.getOptions()
    this.getAddServices()
  },
  methods: {
    ...mapActions({
      getOffers: 'software_net/getOffers',
      getOptions: 'software_net/getOptions',
      getAddServices: 'software_net/getAddServices',
      toggleProperty: 'software_net/toggleProperty',
    }),
    toggleAddServices () {
      this.showProvidersAddServices = !this.showProvidersAddServices
      this.getAddServices({
        page: 1,
        providers: this.showProvidersAddServices
      })
    },
    async getRequests (page = 1) {
      await axios.get('admin/software-offers/requests?page='+ page)
        .then(({ data }) => {
          this.requestsTableData.paginationData = data
          this.requestsTableData.body = data.data
        })
        .catch(errors =>  console.dir(errors))
    },
    async getServicesRequests (page = 1) {
      await axios.get('admin/software-add-services/requests?page='+ page)
        .then(({ data }) => {
          this.servicesRequestsTableData.paginationData = data
          this.servicesRequestsTableData.body = data.data
        })
        .catch(errors =>  console.dir(errors))
    },
    openRequestMoreModal (id) {
      let data = this.feedbackTableData.body.find(el => +el[0] === id).slice()
      console.log(data)

      // this.$store.commit('setModal', {
      //   template: 'neo-bank-more',
      //   options: {
      //     names: ['ID', 'Email', 'Person', 'Messengers', 'Social', 'Price', 'Created At'],
      //     values: data
      //   }
      // })
    },
    openCreateOfferModal () {
      this.$store.commit('setModal', {
        template: 'create-software-offer'
      })
    },
    editOffers (obj) {
      const item = this.offersTableData.paginationData.data.find(el => +el[0] === obj.id)

      this.$store.commit('setModal', {
        template: 'create-software-offer',
        options: {
          id: obj.id,
          sOptions: item[3],
          form: {
            name: item[1],
            slug: item[7],
            price: item[5],
            currency_id: item[6],
            description: item[2],
            is_manager_can: item[8]
          }
        }
      })
    },
    deleteOffers (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'software-offers',
          type: 'software offer',
          url: '',
          successfullMessage: 'You successfully deleted software offer',
        }
      })
    },
    openCreateOptionModal () {
      this.$store.commit('setModal', {
        template: 'create-software-option'
      })
    },
    editOptions (obj) {
      const item = this.optionsTableData.paginationData.data.find(el => +el[0] === obj.id)

      this.$store.commit('setModal', {
        template: 'create-software-option',
        options: {
          id: obj.id,
          form: {
            name: item[1],
            slug: item[2]
          }
        }
      })
    },
    deleteOptions (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'software-options',
          type: 'software option',
          url: '',
          successfullMessage: 'You successfully deleted software option',
        }
      })
    },
    openCreateAddServiceModal () {
      this.$store.commit('setModal', {
        template: 'create-software-add-service'
      })
    },
    editAddService (obj) {
      const item = this.addServicesTableData.paginationData.data.find(el => +el.id === obj.id)

      this.$store.commit('setModal', {
        template: 'create-software-add-service',
        options: {
          id: obj.id,
          form: {
            service_name: item.service_name,
            country_id: item.country ? item.country.id : null,
            service_type: item.service_type,
            price: item.price,
            service_time: item.service_time,
            is_legal_company: item.is_legal_company,
            provider_id: item.provider_id,
          }
        }
      })
    },
    deleteAddService (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'software-add-services',
          type: 'software additional service',
          url: '',
          successfullMessage: 'You successfully deleted software additional service',
        }
      })
    },
    toElTop (el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  .box {
    &__requests,
    &__offers,
    &__addService {
      .content {
        padding: 0 0 1rem;

        .block {
          margin-bottom: 1.5rem;

          .hdr {
            display: flex;
            align-items: center;
            margin-left: .75rem;
            margin-bottom: .5rem;

            svg.img {
              width: 1.75rem;
              height: 1.75rem;
              margin-right: .5rem;
            }

            .ttl {
              padding: .25rem .5rem;
            }

            .btn {
              border-radius: .5rem;
              padding: .5rem 1.5rem;
              margin-left: 1.75rem;
              background-color: #1763fb;
            }

            &__l {
              display: flex;
              align-items: center;
            }

            &__r {
              .btn {
                border-radius: .75rem;
                padding: .5rem 1rem;
                background-color: #3D444B;

                &--active {
                  background-color: #1763fb;
                }
              }
            }
          }
        }
      }
    }

    .jcsb {
      justify-content: space-between;
    }
  }
}


</style>