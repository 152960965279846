<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Applied Offer
      </div>
    </div>
    <div class="modal__body">
      <div class="box box_selectedAcc">
        <div class="header">
        </div>
        <div class="content">
          <div class="block">
            <div class="hdr">
              <!-- <Bank2 class="img" /> -->
              <div class="ttl">Bank account</div>
            </div>
            <Table @apply="apply" :key="bankAccKey" :options="bankAcc" />
          </div>
          <div class="block block_mt">
            <div class="hdr">
              <!-- <CreditCards class="img" /> -->
              <div class="ttl">Merchant account</div>
            </div>
            <Table @apply="apply" :key="merchantAccKey" :options="merchantAcc" />
          </div>
          <div class="block block_mt">
            <div class="hdr">
              <!-- <CreditCards class="img" /> -->
              <div class="ttl">Legal Service</div>
            </div>
            <Table @apply="cancelLegal" :key="legalKey" :options="legalService" />
          </div>
          <div class="block block_mt" ref="bosBlock">
            <div class="hdr">
              <div class="ttl">Buy & Sell NET License</div>
            </div>
            <Table :options="businesOffers" :key="businesOffersKey" @apply="cancelBusinesOffer" @show-details="processDataset" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isShowDatasetModal" class="inner-modal__wrapper" @click.self="closeDataset">
      <div class="inner-modal">
        <div class="modal__close">
          <div class="btn" @click.prevent="closeDataset">
            <Close class="img"/>
          </div>
        </div>
        <div class="modal__header">
          <div class="ttl">
            Add additional info
          </div>
        </div>
        <div class="modal__body page-request main">
          <div class="row">
            <div class="label">Note</div>
            <div class="data">
              <textarea name="note" rows="5" class="inpt inpt__ta" v-model="currentDataset.note"/>
            </div>
          </div>
          <div class="row">
            <div class="label">Progress Stage</div>
            <div class="data">
              <input type="text" name="progress_stage" class="inpt" v-model="currentDataset.progress_stage">
            </div>
          </div>
          <div class="row row_files">
            <div class="box box_addFiles">
              <div v-if="getUploadedFiles('docs').length" class="content content_many">
                <draggable v-model="currentDataset.datasetUpload.docs" tag="div" class="draggable">
                <div v-for="(file, key) in getUploadedFiles('docs')" :key="key" class="file">
                  <BlueFile class="img" />
                  <div class="btn btn_del" @click="delFile('docs', key, file)"><Close class="img" /></div>
                  <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'docs')"><BlueDownload class="img" /></div>
                  <div class="desc">{{ file.name }}</div>
                </div>
                </draggable>
                <div class="add">
                  <div class="btn" @click="$refs.docs[currentDataset.datasetUpload.docs.length - 1].click()"><Close class="img" /></div>
                </div>
              </div>
              <div v-else class="content">
                <div class="choose">
                  <div class="btn" @click="$refs.docs[0].click()">Documents</div>
                </div>
              </div>
                <input
                  v-for="(i, key) in currentDataset.datasetUpload.docs"
                  :key="key"
                  type="file"
                  :name="`docs_${key}`"
                  class="hide"
                  ref="docs"
                  @change="addInput($event, 'docs', key)"
                >
            </div>
          </div>
        </div>
        <div class="modal__footer footer">
          <div class="btn btn_submit" @click.prevent="submitDataset">
            Save Data
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'
import Info from './../elem/Info.vue'
import Compliance from './../elem/Compliance.vue'
import Table from './../elem/Table.vue'
import Bank2 from './../img/Bank2.vue'
import CreditCards from './../img/CreditCards.vue'
import BlueFile from './../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from './../img/BlueDownload.vue'

export default {
  components: {
    Close,
    Info,
    Compliance,
    Table,
    Bank2,
    CreditCards,
    BlueFile,
    draggable,
    BlueDownload
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      bankOffersData: [],
      bankAccKey: 0,
      merchantOffersData: [],
      merchantAccKey: 0,
      legalData: [],
      legalKey: 0,
      bankAcc: {
        colsWidth: ['3%', '10%', '10%', '11%', '12%', '15%', '15%', '12%', '10%'],
        header: [
          { ttl: '#', info: null },
          { ttl: 'Name of financial institution', info: null },
          { ttl: 'Type of Account', info: null },
          { ttl: 'Open/Integration fee', info: null },
          { ttl: 'Incoming fee', info: null },
          { ttl: 'Outgoing fee', info: null },
          { ttl: 'Onboarding time', info: null },
          { ttl: 'Request', info: null },
        ],
        body: [],
      },
      merchantAcc: {
        colsWidth: ['3%', '10%', '10%', '11%', '12%', '15%', '15%', '12%', '10%'],
        header: [
          { ttl: '#', info: null },
          { ttl: 'Type of License', info: null },
          { ttl: 'Type of Zone', info: null },
          { ttl: 'Open/Integration fee', info: null },
          { ttl: 'Incoming fee', info: null },
          { ttl: 'Outgoing fee', info: null },
          { ttl: 'Onboarding time', info: null },
          { ttl: 'Request', info: null },
        ],
        body: [],
      },
      legalService: {
        colsWidth: ['3%', '13%', '12%', '12%', '12%', '27%', '12%', '5%'],
        header: [
          { ttl: '#', info: null },
          { ttl: 'Name of Legal Co.', info: null },
          { ttl: 'Country', info: null },
          { ttl: 'Type of Services', info: null },
          { ttl: 'Price', info: null },
          { ttl: 'Description of Benefit', info: null },
          { ttl: 'Service time deadline', info: null },
          { ttl: '', info: null },
        ],
        body: [],
      },
      businesOffersKey: 1,
      businesOffers: {
        colsWidth: ['5%', '15%', '15%', '15%', '30%', '10%', '10%'],
        header: [
          { ttl: '#' },
          { ttl: 'Type of Business' },
          { ttl: 'Type of License' },
          { ttl: 'Country' },
          { ttl: 'Description' },
          { ttl: 'Request' },
          { ttl: 'Detail' }
        ],
        body: []
      },
      isShowDatasetModal: false,
      currentDataset: this.getEmptyDataset(),
      currentDatasetBusinessOfferId: null,
    };
  },
  created () {
    this.getBankOffers()
    this.getMerchantOffers()
    this.getAppliedLegalServices()
    this.getAppliedBusinesOffers()
  },
  methods: {
    getBankOffers () {
      const that = this
      axios.get('admin/offers/applied/bank/' + this.$route.params.id)
        .then(function (response) {
          that.bankOffersData = response.data
          that.bankAcc.body = []
          that.bankOffersData.forEach(el => {
            const timeLeftDaysNumber = (el.time_left) ? el.time_left.days_number : ''
            const timeLeftHoursNumber = (el.time_left) ? el.time_left.hours_number : ''
            that.bankAcc.body.push([
              el.serial_number, el.financial_institution_name, el.account_type, that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber}, { type: 'cancel', applyType: 'bank-offer', id: el.id }
            ])
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getMerchantOffers () {
      const that = this
      axios.get('admin/offers/applied/merchant/' + this.$route.params.id)
        .then(function (response) {
          that.merchantOffersData = response.data
          that.merchantAcc.body = []
          that.merchantOffersData.forEach(el => {
            const timeLeftDaysNumber = (el.time_left) ? el.time_left.days_number : ''
            const timeLeftHoursNumber = (el.time_left) ? el.time_left.hours_number : ''
            that.merchantAcc.body.push([
              el.serial_number, el.financial_institution_name, el.country_code, that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber}, { type: 'cancel', applyType: 'merchant-offer', id: el.id }
            ])
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getAppliedLegalServices () {
      const that = this
      axios.get('admin/legal-atlas/applied/' + this.$route.params.id)
        .then(function (response) {
          that.legalService.body = []
          that.legalData = response.data
          that.legalData.forEach(el => {
            that.legalService.body.push([
              el.serial_number, el.legal_name, el.country ? el.country.code : '', el.services_type, that.number_format(el.price, 2, '.', ' ') + '€',
              el.benefit_description, { type: 'deadline_legal', days: el.time_left.days_number, days_text: el.time_left.days_text, hours: el.time_left.hours_number, hours_text: el.time_left.hours_text, id: el.id }
            ])
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getAppliedBusinesOffers () {
      axios.get('admin/business-offers/applied/' + this.$route.params.id)
        .then(({ data }) => {
          this.businesOffers.body = data.data
          setTimeout(() => {
            var element = this.$refs.bosBlock
            console.log(element)
            element.scrollIntoView({ behavior: 'smooth', inline: 'start' })
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    apply (payload) {
      const that = this
      axios.post('admin/offers/cancel/' + payload.id + '/' + this.$route.params.id)
        .then((response) => {
          const res = response.data
          if (payload.applyType == 'bank-offer') {
            that.bankAcc.body = []
            that.bankOffersData = that.bankOffersData.filter(offer => offer.id !== payload.id)
            that.bankOffersData.forEach(el => {
              const timeLeftDaysNumber = (el.time_left) ? el.time_left.days_number : ''
              const timeLeftHoursNumber = (el.time_left) ? el.time_left.hours_number : ''
              that.bankAcc.body.push([
                el.serial_number, el.financial_institution_name, el.country_code, el.account_type, that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber}, { type: 'cancel', applyType: 'bank-offer', id: el.id }
              ])
            })
            that.bankAccKey++
          } else if (payload.applyType == 'merchant-offer') {
            that.merchantAcc.body = []
            that.merchantOffersData = that.merchantOffersData.filter(offer => offer.id !== payload.id)
            that.merchantOffersData.forEach(el => {
              const timeLeftDaysNumber = (el.time_left) ? el.time_left.days_number : ''
              const timeLeftHoursNumber = (el.time_left) ? el.time_left.hours_number : ''
              that.merchantAcc.body.push([
                el.serial_number, el.financial_institution_name, el.country_code, el.account_type, that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber}, { type: 'cancel', applyType: 'merchant-offer', id: el.id }
              ])
            })
            that.merchantAccKey++
          }

        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    cancelLegal(payload) {
      const that = this
      axios.post('admin/legal-atlas/cancel/' + payload.id + '/' + this.$route.params.id)
        .then((response) => {
          const res = response.data
          if (res.canceled) {
            that.legalService.body = []
            that.legalData = that.legalData.filter(legal => legal.id !== payload.id)
            that.legalData.forEach(el => {
              that.legalService.body.push([
                el.serial_number, el.legal_name, el.services_type, that.number_format(el.price, 2, '.', ' ') + '€',
                el.benefit_description, { type: 'deadline', days: el.time_left.days_number, days_text: el.time_left.days_text, hours: el.time_left.hours_number, hours_text: el.time_left.hours_text, id: el.id }
              ])
            })
            that.legalAccKey++
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    async cancelBusinesOffer (payload) {
      await axios.post('admin/business-offers/cancel/' + payload.id + '/' + this.$route.params.id)
        .then(({ data }) => {
          if (data.canceled) {
            this.businesOffers.body = this.businesOffers.body.filter(el => +el[0] !== +payload.id)
            this.businesOffersKey++
          }
        })
        .catch(errors => {
          console.dir(errors)
        })
    },
    async processDataset(data){
      let businessOfferId = data.id
      let userId = this.$route.params.id

      this.currentDatasetBusinessOfferId = businessOfferId

      this.currentDataset = await this.getDataset(businessOfferId, userId);

      this.isShowDatasetModal = true;
    },
    closeDataset(){
      this.currentDataset = this.getEmptyDataset();

      this.currentDatasetBusinessOfferId = null

      this.isShowDatasetModal = false;
    },
    async submitDataset(){
      let userId = this.$route.params.id
      let businessOfferId = this.currentDatasetBusinessOfferId
      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/business-offers/dataset/'+ businessOfferId + '/' + userId

      if(this.currentDataset.id){
        url += '/' + this.currentDataset.id
      }

      try {
        const response = await axios.post(url, {
          note: this.currentDataset.note,
          progress_stage: this.currentDataset.progress_stage,
        });
        let datasetId = response.data.id;

        await this.processFiles(datasetId)

        this.closeDataset()
      }
      catch (error) {
        console.log(error);
      }
    },
    async processFiles(id){
      let data = new FormData()
      const uploadKeys = Object.keys(this.currentDataset.datasetUpload)

      for (let z = 0; z < uploadKeys.length; z++) {
        const key = uploadKeys[z]

        for (let i = 0; i < this.currentDataset.datasetUpload[key].length; i++) {
          if (this.currentDataset.datasetUpload[key][i].name !== null) {
            let f = {}
            if (this.currentDataset.datasetUpload[key][i].type == 'server') {
              f = JSON.stringify(this.currentDataset.datasetUpload[key][i])
            } else {
              data.append(key + '_names[' + i + ']', this.currentDataset.datasetUpload[key][i].name)
              f = this.currentDataset.datasetUpload[key][i].file
            }
            data.append(key + '[' + i + ']', f)
          }
        }
      }
      
      axios.post('admin/business-offers/dataset/' + id + '/files', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((response) => {
          
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    async getDataset(businessOfferId, userId){
      try {
        const response = await axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/business-offers/dataset/' + businessOfferId + '/' + userId);
        let dataset = response.data;
        let datasetId = dataset.id;
        
        dataset.datasetUpload = {docs: await this.getDatasetDocs(datasetId)};

        return dataset;
      }
      catch (error) {
        console.log(error);

        return this.getEmptyDataset();
      }
    },
    async getDatasetDocs(id) {
      if(id) {
        try {
          const response = await axios.get('admin/business-offers/dataset/' + id + '/get-file/docs');
          
          let docsData = response.data
          
          if (docsData.length > 0) {
            return docsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              };
            });
          }

          return [{
            id: null,
            uuid: null,
            name: null,
            type: '',
            url: null,
            order: null
          }]
        }
        catch (error) {
          console.dir(error);
        }
      }
    },
    getEmptyDataset(){
      return {
        progress_stage: 0,
        note: '',
        datasetUpload: {
          docs: [{ name: null }],
        }
      }
    },
    getUploadedFiles(el) {
      return this.currentDataset.datasetUpload[el].filter(e => e.name !== null)
    },
    delFile(name, key, file) {
      if (this.currentDataset.datasetUpload[name].length > 1) {
        this.currentDataset.datasetUpload[name].splice(key, 1)
      } else {
        this.currentDataset.datasetUpload[name][0].name = null
      }

      if(file.id && this.currentDataset.id){
        axios.post('admin/business-offers/dataset/' + this.currentDataset.id +'/delete-file', {
        collection: name,
        media_id: file.id,
      })
        .then(function () {
          
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    addInput(event, name, key) {
      if (this.$refs[name][key].files.length) {
        const file_name = this.$refs[name][key].files[0].name
        if (this.getUploadedFiles(name).some(e => e.name == file_name)) {
          this.$noty.error('Error! A file with this name has already been added.')
          return false
        }

        const extension = this.$refs[name][key].files[0].name.split('.').pop()
        const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx', 'zip', 'rar', '7z', 'tar']

        if (!availableExtensionsArr.includes(extension.toLowerCase())) {
          this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
          return false
        }

        if (this.$refs[name][key].files[0].size / 1024 / 1024 > 5) {
          this.$noty.error('File is too large. Maximum - 5MB.')
          return false
        }

        this.currentDataset.datasetUpload[name] = this.currentDataset.datasetUpload[name].filter(e => e.name !== null)
        if (this.$refs[name].length < 2) {
          this.currentDataset.datasetUpload[name].push({ name: file_name, file: event.target.files[0]})
        } else {
          this.$noty.error('Maximum - 2 files')
        }
      }
    },
    downloadFile(file, type) {
      const id = this.currentDataset.id

      if(file.url){
        this.openFile(file.url)
      }
      else if(id){
        axios.post('admin/business-offers/dataset/' + id +'/get-file-link', {
        collection: type,
        media_id: file.id,
      })
        .then(function (response) {
          if (response.data) {
            const url = response.data
            var fileURL = response.data;
            this.openFile(fileUrl);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    openFile(url){
      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.setAttribute('target', '_blank')
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    // height: 25rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    max-height: 80vh;
    display: block;
    padding-bottom: 2rem;
    overflow: auto;
  }
}

.box {
  &_selectedAcc {
    width: 100%;
  }
}

.tbl {
  color: #ffffff;
}

.block_mt {
  margin-top: 20px;
}
.inner-modal__wrapper{
  position: fixed;
  top: -100%;
  left: -100%;
  right: -100%;
  bottom: -100%;
  background: rgba(0, 0, 0, 0.5);
  .modal__body{
    display: block;
  }

  .inner-modal{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #272E35;
    max-width: 100%;
    width: 520px;
    .ttl, .label{
      color: #fff
    }
    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;
      flex-wrap: wrap;

      .label {
        width: 100%;
        padding-right: .75rem;
        font-weight: bold;
      }

      .data {
        flex-grow: 1;
        width: 100%;

        .inpt {
          width: 100%;
          color: #fff;

          &__ta {
            height: 7rem;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          width: 30%;
          //width: calc(50% - .75rem);
          //margin: .75rem 0;
          //padding: 1rem 1.75rem;
          //background: #22252B;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #fff;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
