<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">Add to balance internal deal</div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="label">Total amount</div>
        <div class="data">
          <input type="number" @change="calculateBalance" name="balance_total_amount" class="inpt" v-model="form.balance_total_amount">
        </div>
      </div>
      <div class="row">
        <div class="label">Seller amount</div>
        <div class="data">
          <input type="number" @change="calculateBalance" name="balance_seller_amount" class="inpt" v-model="form.balance_seller_amount">
        </div>
      </div>
      <div class="row">
        <div class="label">Agent amount</div>
        <div class="data">
          <input type="number" @change="calculateBalance" name="balance_agent_amount" class="inpt" v-model="form.balance_agent_amount">
        </div>
      </div>
      <div class="row">
        <div class="label">Team amount</div>
        <div class="data">
          <input type="number" @change="calculateBalance" name="balance_team_amount" class="inpt" v-model="form.balance_team_amount">
        </div>
      </div>
      <div class="row">
        <div class="label">M&A income</div>
        <div class="data">
          <input type="number" name="balance_income_amount" class="inpt" v-model="form.balance_income_amount">
        </div>
      </div>
      <div class="row">
        <div class="label">Paid balance</div>
        <div class="data">
          <input type="number" name="paid_balance" class="inpt" v-model="form.paid_balance">
        </div>
      </div>
      <div class="row">
        <div class="label">Payment type</div>
        <div class="data">
          <select name="balance_payment_type" class="inpt" v-model="form.balance_payment_type" :key="paymentTypesKey">
            <option :value="null">Select type</option>
            <option
              v-for="(type, i) in paymentTypes" :key="type.value"
              :selected="type.value == form.balance_payment_type"
              :value="type.value"
            >
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Income Deadline</div>
        <div class="data">
          <input type="date" name="income_deadline_at" class="inpt" v-model="form.income_deadline_at">
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div v-if="confirmDelete" class="options">
        <center>
          <h3>Are you sure you want delete this internal deal from balance</h3>
          <div style="max-width: 240px; margin:auto">
            <div class="btn btn__save" @click="deleteLead">Yes, delete</div>
            <div class="btn btn__delete" @click="confirmDelete = false">NO</div>
          </div>
        </center>
      </div>
      <div v-else class="options">
        <div class="btn btn__save" @click="save">Save</div>
        <div class="btn btn__delete" @click="confirmDelete = true">Delete</div>
      </div>
    </div>
  </div>
</template>

<script>
import Close from '../img/Close.vue'

export default {
  components: {
    Close,
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data: function(){
    return{
      paymentTypes: [
        {
          name: 'Fiat',
          value: 'fiat'
        },
        {
          name: 'Crypto',
          value: 'crypto'
        }
      ],
      form: {
        balance_total_amount: 0,
        balance_seller_amount: 0,
        balance_agent_amount: 0,
        balance_team_amount: 0,
        balance_income_amount: 0,
        balance_payment_type: 0,
        balance: 0,
        paid_balance: 0,
        income_deadline_at: '',
      },
      paymentTypesKey: 0,
      error: null,
      confirmDelete: false,
    }
  },
  async mounted() {
    await this.getLead()
  },
  methods: {
    async getLead(){
      let that = this
      
      await axios.get('admin/leads/' + this.options.id)
      .then(function (response) {
        that.form = response.data
        that.form.is_balances = true
        that.paymentTypesKey++
      })
      .catch(function (error) {
        console.log(error);
      })
	  },
    calculateBalance(){
      this.form.balance_team_amount = (this.form.balance_total_amount - this.form.balance_seller_amount - this.form.balance_agent_amount) / 100 * 10

      this.form.balance_income_amount = this.form.balance_total_amount - this.form.balance_seller_amount - this.form.balance_agent_amount - this.form.balance_team_amount
    },
    async deleteLead() {
      this.form.is_balances = false;
      this.form.balance_total_amount = 0;
      this.form.balance_seller_amount = 0;
      this.form.balance_agent_amount = 0;
      this.form.balance_team_amount = 0;
      this.form.balance_income_amount = 0;
      this.form.balance_payment_type = '';
      this.form.balance = 0;
      this.form.paid_balance = 0;
      this.form.income_deadline_at = '';
      
      await this.save()
    },
    async save(){
      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/leads'

      url += '/' + this.options.id

      await axios.post(url, this.form)
        .then((response) => {
          this.close();

          this.$store.dispatch('editItemFromTable', {
            id: this.options.id,
            table: this.options.table,
            actionType: this.options.actionType,
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
        max-width: 160px;
      }

      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
        .btn {
          padding: 0.5rem;
          background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
          border-radius: 6px;
          color: #fff;
          font-size: 13px;
        }
        .delete-btn{
          margin-left: auto;
          width: 24px;
          height: 24px;
          background-color: #B03636;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg{
            fill: #fff;
            max-width: 16px;
            max-height: 16px;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          width: 30%;
          //width: calc(50% - .75rem);
          //margin: .75rem 0;
          //padding: 1rem 1.75rem;
          //background: #22252B;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #333333;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
  &__footer {
    padding: 1rem 1.5rem 1.5rem;
    .btn__delete{
      background: #B03636;
    }
  }
  textarea{
    height: 5rem;
  }
}
.gap-12{
  gap: 12px;
}
</style>
