<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">New version Business Offer</div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="label">Type of Business</div>
        <div class="data">
          <div>
            {{ form.business_type }}
          </div>
          <input type="text" name="new_business_type" class="inpt" v-model="form.new_business_type">
        </div>
      </div>
      <div class="row">
        <div class="label">Type of License</div>
        <div class="data">
          <div>
            {{ form.license_type }}
          </div>
          <input type="text" name="new_license_type" class="inpt" v-model="form.new_license_type">
        </div>
      </div>
      <div class="row">
        <div class="label">Country</div>
        <div class="data">
          <div>
            <span v-for="item in countries" :key="item.id" v-if="item.id == form.country_id">
              {{ item.name }}
            </span>
          </div>
          <select name="new_country_id" class="inpt" v-model="form.new_country_id">
            <option value="null">Select country</option>
            <option
              v-for="country in countries" :key="country.id"
              :selected="country.id === form.new_country_id"
              :value="country.id"
            >
              {{ country.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Description</div>
        <div class="data">
          <div>
            {{ form.description }}
          </div>
          <textarea name="new_description" rows="5" class="inpt inpt__ta" v-model="form.new_description"/>
        </div>
      </div>
      <div class="row row__price">
        <div class="label">Wish Price</div>
        <div class="data">
          <div class="w-100">
            <span>
              {{ form.wish_price }}
            </span>
            <span v-for="item in currencies" :key="item.id" v-if="item.id == form.currency_id">
              {{ item.code }}
            </span>
          </div>
          <div class="row__price">
            <div class="data no-wrap">
              <input type="text" name="new_wish_price" class="inpt" v-model="form.new_wish_price">
              <select  name="new_currency_id" v-if="currencies" v-model="form.new_currency_id">
                <option
                  v-for="c in currencies" :key="c.id"
                  :value="c.id"
                  v-text="c.code"
                />
              </select>
            </div>
          </div>
        </div>
      </div>
      <span v-if="error && error.length > 0" class="invalid-feedback">{{ error }}</span>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save(false)">Decline</div>
      <div class="btn" @click="save(true)">Accept</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Close from '../img/Close.vue'
import Tmblr from '../elem/Tmblr.vue'
import BlueFile from '../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from '../img/BlueDownload.vue'

export default {
  components: {
    Close,
    Tmblr,
    BlueDownload,
    BlueFile,
    draggable,
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    countries: [],
    allCurrencies: null,
    form: {
      business_type: null,
      license_type: null,
      country_id: null,
      description: null,
      wish_price: null,
      currency_id: null,

      new_business_type: null,
      new_license_type: null,
      new_country_id: null,
      new_description: null,
      new_wish_price: null,
      new_currency_id: null,
    },
    error: null,
    message: '',
    upload: {
      license_docs: [{ name: null }],
      agreement_docs: [{ name: null }],
      deal_docs: [{ name: null }],
    }
  }),
  computed: {
    isEdit () {
      return this.options && this.options.id && this.options.form
    },
    currencies () {
      if (!this.allCurrencies) return []

      return this.allCurrencies.filter(el => el.code === 'EUR' || el.code === 'USD')
    }
  },
  created () {
    this.getCurrencies()
    this.getCountries()
  },
  methods: {
    ...mapActions({
      updateNewBusinessOffer: 'bos_business/updateNewOffer',
    }),
    updateForm () {
      if (!this.isEdit) return
      
      this.form.new_business_type = this.options.form.new_business_type
      this.form.new_license_type = this.options.form.new_license_type
      this.form.new_description = this.options.form.new_description
      this.form.new_wish_price = this.options.form.new_wish_price
      this.form.new_currency_id = this.options.form.new_currency_id
      this.form.new_country_id = this.options.form.new_country_id

      this.form.business_type = this.options.form.business_type
      this.form.license_type = this.options.form.license_type
      this.form.description = this.options.form.description
      this.form.wish_price = this.options.form.price.wish_price ? this.options.form.price.wish_price : 0
      this.form.currency_id = this.options.form.price.currency ? this.options.form.price.currency.id : 2

      const country = this.countries.find(el => el.name === this.options.form.country_name)
      this.form.country_id = country && country.id ? country.id : null
    },
    getCurrencies () {
      axios.get('admin/data/currencies')
        .then(({ data }) => {
          this.allCurrencies = data
        })
        .catch(err => console.dir(err))
    },
    async getCountries () {
      await axios.get('admin/data/countries')
        .then(({data}) => {
          this.countries = data
          this.updateForm()
        })
        .catch((error) => {
          console.log(error);
        })
    },
    async save(accept) {
      let data = {
        business_type: this.form.new_business_type,
        license_type: this.form.new_license_type,
        description: this.form.new_description,
        wish_price: this.form.new_wish_price,
        country_id: this.form.new_country_id,
        currency_id: this.form.new_currency_id,
        accept: accept,
      }
      
      await this.updateNewBusinessOffer({id: this.options.id, form: data}).then(res => {
            this.close()
          })
          .catch(error => console.log(error))
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
      }

      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
      }

      &__price {
        .data {
          display: flex;
          flex-wrap: wrap;
          &.no-wrap{
            flex-wrap: nowrap;
            input{
              flex-shrink: 0;
            }
          }
          .w-100{
            width: 100%;
            flex: 0 0 100%;
          }

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          width: 30%;
          //width: calc(50% - .75rem);
          //margin: .75rem 0;
          //padding: 1rem 1.75rem;
          //background: #22252B;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #333333;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
  textarea{
    height: 5rem;
  }
}
</style>
