<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">Deal internal</div>
    </div>
    <div class="modal__body">
      <div class="row" v-if="$store.state.user.is_super_admin">
        <div class="label">Manager can view</div>
        <div class="data c-tmblr">
          <Tmblr :active="!!form.is_manager_can" :class="'c-tmblr'" :text="'Manager can view'" @changeState="changeState('is_manager_can')" />
        </div>
      </div>
      <div class="row">
        <div class="label">Internal customer id</div>
        <div class="data">
          <input type="number" name="customer_id" class="inpt" v-model="form.customer_id">
        </div>
      </div>
      <div class="row">
        <div class="label">Customer number</div>
        <div class="data">
          <input type="text" name="client_number" class="inpt" v-model="form.client_number">
        </div>
      </div>
      <div class="row">
        <div class="label">Customer name</div>
        <div class="data">
          <input type="text" name="client_name" class="inpt" v-model="form.client_name">
        </div>
      </div>
      <div class="row">
        <div class="label">Customer type</div>
        <div class="data">
          <input type="text" name="customer_type" class="inpt" v-model="form.customer_type">
        </div>
      </div>
      <div class="row">
        <div class="label">Priority</div>
        <div class="data">
          <select name="status" class="inpt" v-model="form.priority">
            <option :value="null">Select priority</option>
            <option
              v-for="priority in priorities" :key="priority"
              :selected="priority === form.priority"
              :value="priority"
            >
              {{ priority }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Status</div>
        <div class="data">
          <input type="text" name="status" class="inpt" v-model="form.status">
        </div>
      </div>
      <div class="row">
        <div class="label">Joined at</div>
        <div class="data">
          <input type="date" name="joined_at" class="inpt" v-model="form.joined_at">
        </div>
      </div>
      <div class="row">
        <div class="label">Web site</div>
        <div class="data">
          <input type="text" name="web_site" class="inpt" v-model="form.web_site">
        </div>
      </div>
      <div class="row">
        <div class="label">Country</div>
        <div class="data">
          <select name="country_id" class="inpt" v-model="form.country_id">
            <option :value="null">Select country</option>
            <option
              v-for="country in countries" :key="country.id"
              :selected="country.id === form.country_id"
              :value="country.id"
            >
              {{ country.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Fintech type</div>
        <div class="data">
          <select name="fintech_type_id" class="inpt" v-model="form.fintech_type_id">
            <option :value="null">Select fintech type</option>
            <option
              v-for="type in fintechTypes" :key="type.id"
              :selected="type.id === form.fintech_type_id"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Contact links</div>
        <div class="data">
          <div v-for="(link, i) in form.lead_links">
            <div>
              <div class="row">
                <div class="data">
                  <div class="row">
                    <div class="label">
                      Link № {{ i + 1 }}
                    </div>
                    <div class="data">
                      <div class="delete-btn" @click="deleteLeadLink(i)">
                        <Close class="img"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row gap-12">
                <div class="col">
                  <input type="text" name="service_name" class="inpt" v-model="link.service_name">
                </div>
                <div class="col">
                  <input type="text" name="link" class="inpt" v-model="link.link">
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="data">
                <span class="btn" @click="addLeadLink">
                  Add contact link
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label">Managers</div>
        <div class="data">
          <div v-for="(formManager, formManagerIndex) in form.managers">
            <div>
              <div class="row">
                <div class="data">
                  <div class="row">
                    <div class="label">
                      Manager № {{ formManagerIndex + 1 }}
                    </div>
                    <div class="data">
                      <div class="delete-btn" @click="deleteManager(formManagerIndex)">
                        <Close class="img"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <select name="manager_name" class="inpt" v-model="formManager.name">
                <option :value="null">Select manager</option>
                <option 
                  v-for="(manager, i) in managers" 
                  :value="manager.name"
                >
                  {{ manager.name }}
                </option>
              </select>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="data">
                <span class="btn" @click="addManager">
                  Add manager
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="$store.state.user.is_super_admin">
        <div class="label">Asystent</div>
        <div class="data">
          <select name="asystent_name" class="inpt" v-model="form.asystent_name">
            <option :value="null">Select asystent</option>
            <option
              v-for="item in asystents" :key="item.name"
              :selected="item.name === form.asystent_name"
              :value="item.name"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Agent name</div>
        <div class="data">
          <input type="number" name="agent_name" class="inpt" v-model="form.agent_name">
        </div>
      </div>
      <div class="row">
        <div class="label">Agent fee</div>
        <div class="data">
          <input type="number" name="agent_fee" class="inpt" v-model="form.agent_fee">
        </div>
      </div>
      <div class="row">
        <div class="label">Client budget</div>
        <div class="data">
          <select name="client_budget" class="inpt" v-model="form.client_budget" :key="clientBudgetKey">
            <option :value="null">Select client budget</option>
            <option
              v-for="(budget, i) in clientBudgets" :key="budget.value"
              :selected="+parseInt(budget.value) == +parseInt(form.client_budget)"
              :value="budget.value"
            >
              {{ budget.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Last request</div>
        <div class="data">
          <textarea name="last_request" rows="5" class="inpt inpt__ta" v-model="form.last_request"/>
        </div>
      </div>
      <div class="row">
        <div class="label">Description</div>
        <div class="data">
          <textarea name="description" rows="5" class="inpt inpt__ta" v-model="form.description"/>
        </div>
      </div>
      <div class="row">
        <div class="label">Last conversation</div>
        <div class="data">
          <textarea name="last_conversation" rows="5" class="inpt inpt__ta" v-model="form.last_conversation"/>
        </div>
      </div>
      <div class="row">
        <div class="label">Deadline at</div>
        <div class="data">
          <input type="date" name="deadline_at" class="inpt" v-model="form.deadline_at">
        </div>
      </div>
      <div class="row row_files">
        <div class="box box_addFiles">
          <div v-if="getUploadedFiles('documents').length" class="content content_many">
            <draggable v-model="upload.documents" tag="div" class="draggable">
            <div v-for="(file, key) in getUploadedFiles('documents')" :key="key" class="file">
              <BlueFile class="img" />
              <div class="btn btn_del" @click="delFile('documents', key, file)"><Close class="img" /></div>
              <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'documents')"><BlueDownload class="img" /></div>
              <div class="desc">{{ file.name }}</div>
            </div>
            </draggable>
            <div class="add">
              <div class="btn" @click="$refs.documents[upload.documents.length - 1].click()"><Close class="img" /></div>
            </div>
          </div>
          <div v-else class="content">
            <div class="choose">
              <div class="btn" @click="$refs.documents[0].click()">License document</div>
            </div>
          </div>
            <input
              v-for="(i, key) in upload.documents"
              :key="key"
              type="file"
              :name="`documents_${key}`"
              class="hide"
              ref="documents"
              @change="addInput($event, 'documents', key)"
            >
        </div>
        <div class="box box_addFiles">
          <div v-if="getUploadedFiles('promt').length" class="content content_many">
            <draggable v-model="upload.promt" tag="div" class="draggable">
            <div v-for="(file, key) in getUploadedFiles('promt')" :key="key" class="file">
              <BlueFile class="img" />
              <div class="btn btn_del" @click="delFile('promt', key, file)"><Close class="img" /></div>
              <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'promt')"><BlueDownload class="img" /></div>
              <div class="desc">{{ file.name }}</div>
            </div>
            </draggable>
            <div class="add">
              <div class="btn" @click="$refs.promt[upload.promt.length - 1].click()"><Close class="img" /></div>
            </div>
          </div>
          <div v-else class="content">
            <div class="choose">
              <div class="btn" @click="$refs.promt[0].click()">Promt</div>
            </div>
          </div>
            <input
              v-for="(i, key) in upload.promt"
              :key="key"
              type="file"
              :name="`promt_${key}`"
              class="hide"
              ref="promt"
              @change="addInput($event, 'promt', key)"
            >
        </div>
        <div class="box box_addFiles">
          <div v-if="getUploadedFiles('call_history').length" class="content content_many">
            <draggable v-model="upload.call_history" tag="div" class="draggable">
            <div v-for="(file, key) in getUploadedFiles('call_history')" :key="key" class="file">
              <BlueFile class="img" />
              <div class="btn btn_del" @click="delFile('call_history', key, file)"><Close class="img" /></div>
              <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'call_history')"><BlueDownload class="img" /></div>
              <div class="desc">{{ file.name }}</div>
            </div>
            </draggable>
            <div class="add">
              <div class="btn" @click="$refs.call_history[upload.call_history.length - 1].click()"><Close class="img" /></div>
            </div>
          </div>
          <div v-else class="content">
            <div class="choose">
              <div class="btn" @click="$refs.call_history[0].click()">Call history</div>
            </div>
          </div>
            <input
              v-for="(i, key) in upload.call_history"
              :key="key"
              type="file"
              :name="`call_history_${key}`"
              class="hide"
              ref="call_history"
              @change="addInput($event, 'call_history', key)"
            >
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save">Save</div>
    </div>
  </div>
</template>

<script>
import Close from '../img/Close.vue'
import Tmblr from '../elem/Tmblr.vue'
import BlueFile from '../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from '../img/BlueDownload.vue'

export default {
  components: {
    Close,
    Tmblr,
    BlueDownload,
    BlueFile,
    draggable,
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data(){
    return{
      countries: [],
      managers: [],
      asystents: [],
      fintechTypes: [],
      priorities: [
        'High (1 hour)',
        'Middle (day)',
        'Low (week)',
      ],
      clientBudgets: [
        {
          name: '< 100 K',
          value: 100000.00
        },
        {
          name: '< 250 K',
          value: 250000.00
        },
        {
          name: '< 500 K',
          value: 500000.00
        },
        {
          name: '< 1 M',
          value: 1000000.00
        },
        {
          name: '< 2 M',
          value: 2000000.00
        },
        {
          name: '< 5 M',
          value: 5000000.00
        },
        {
          name: '< 10 M',
          value: 10000000.00
        },
        {
          name: '> 10 M',
          value: 9999999999.00
        },
      ],
      form: {
        customer_id: null,
        client_number: null,
        client_name: null,
        country_id: null,
        status: null,
        priority: null,
        joined_at: null,
        web_site: null,
        customer_type: null,
        last_request: null,
        last_conversation: null,
        balance: 0,
        client_budget: null,
        paid_balance: 0,
        manager_name: null,
        asystent_name: null,
        agent_name: null,
        agent_fee: null,
        deadline_at: null,
        description: null,
        fintech_type_id: null,
        is_manager_can: true,
        lead_links: [],
        lead_links_to_delete: [],
        managers: [],
      },
      clientBudgetKey: 0,
      error: null,
      upload: {
        documents: [{ name: null }],
        call_history: [{ name: null }],
        promt: [{ name: null }],
      }
    }
  },
  computed: {
    isEdit () {
      return this.options && this.options.id && this.options.form
    }
  },
  async created () {
    if(this.isEdit){
      await this.getLead()
      await this.updateFormData()
      await this.getDocuments()
      await this.getCallHistory()
      await this.getPromt()
    }

    await this.getCountries()
    await this.getManagers()
    await this.getAsystents()
    await this.getFintechTypes()
  },
  methods: {
    addLeadLink(){
      this.form.lead_links.push({
        service_name: null,
        link: null
      })
    },
    deleteLeadLink(index){
      if(this.form.lead_links[index].hasOwnProperty('id')){
        this.form.lead_links_to_delete.push(this.form.lead_links[index])
      }

      this.form.lead_links.splice(index, 1)
    },
    addManager(){
      this.form.managers.push({
        name: null
      })
    },
    deleteManager(index){
      this.form.managers.splice(index, 1)
    },
    updateFormData(){
      let arrManagers = [];
      
      if(this.form.manager_name){
        arrManagers = this.form.manager_name.split('|').map(name => {
          return { name: name };
        });
      }
      
      this.$set(this.form, 'managers',  arrManagers)
    },
    async getDocuments () {
      const that = this
      const id = this.options.id
      
      if(id){
        await axios.get('admin/leads/' + id + '/get-file/documents')
        .then((response) => {
          let docsData = response.data

          if(docsData.length > 0) {
            that.upload.documents = docsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
      }
    },
    async getCallHistory () {
      const that = this
      const id = this.options.id
      
      if(id){
        await axios.get('admin/leads/' + id + '/get-file/call_history')
        .then((response) => {
          let docsData = response.data

          if(docsData.length > 0) {
            that.upload.call_history = docsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
      }
    },
    async getPromt () {
      const that = this
      const id = this.options.id
      
      if(id){
        await axios.get('admin/leads/' + id + '/get-file/promt')
        .then((response) => {
          let docsData = response.data

          if(docsData.length > 0) {
            that.upload.promt = docsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
      }
    },
    getUploadedFiles(el) {
      return this.upload[el].filter(e => e.name !== null)
    },
    delFile(name, key, file) {
      if (this.upload[name].length > 1) {
        this.upload[name].splice(key, 1)
      } else {
        this.upload[name][0].name = null
      }

      if(file.id && this.options.id){
        axios.post('admin/leads/' + this.options.id +'/delete-file', {
        collection: name,
        media_id: file.id,
      })
        .then(function () {
          
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    addInput(event, name, key) {
      if (this.$refs[name][key].files.length) {
        const file_name = this.$refs[name][key].files[0].name
        if (this.getUploadedFiles(name).some(e => e.name == file_name)) {
          this.$noty.error('Error! A file with this name has already been added.')
          return false
        }

        const extension = this.$refs[name][key].files[0].name.split('.').pop()
        const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx']
        if (!availableExtensionsArr.includes(extension.toLowerCase())) {
          this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
          return false
        }

        if (this.$refs[name][key].files[0].size / 1024 / 1024 > 10) {
          this.$noty.error('File is too large. Maximum - 10MB.')
          return false
        }

        this.upload[name] = this.upload[name].filter(e => e.name !== null)
        if (this.$refs[name].length < 10) {
          this.upload[name].push({ name: file_name, file: event.target.files[0]})
        } else {
          this.$noty.error('Maximum - 10 files')
        }
      }
    },
    downloadFile(file, type) {
      const id = this.options.id
      if(file.url){
        this.openFile(file.url)
      }
      else if(id){
        axios.post('admin/leads/' + id +'/get-file-link', {
        collection: type,
        media_id: file.id,
      })
        .then(function (response) {
          if (response.data) {
            const url = response.data
            var fileURL = response.data;
            this.openFile(fileUrl);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    openFile(url){
      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.setAttribute('target', '_blank')
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async getLead(){
      const that = this
      await axios.get('admin/leads/' + this.options.id)
      .then(function (response) {
        that.form = response.data
        that.form.lead_links_to_delete = []
        that.clientBudgetKey++
      })
      .catch(function (error) {
        console.log(error);
      })
	  },
    async getCountries () {
      await axios.get('admin/data/countries')
      .then(({data}) => {
        this.countries = data
      })
      .catch((error) => {
        console.log(error);
      })
    },
    async getManagers () {
      const that = this
      await axios.get('admin/data/managers')
        .then(function (response) {
          that.managers = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
	  },
    async getAsystents () {
      const that = this
      await axios.get('admin/data/asystents')
        .then(function (response) {
          that.asystents = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async getFintechTypes(){
      await axios.get('admin/data/fintech-types')
        .then(({ data }) => {
          this.fintechTypes = data
        })
        .catch(err => console.dir(err))
    },
    async save(){
      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/leads'

      if (this.isEdit) {
        url += '/' + this.options.id
      }

      this.form.manager_name = this.form.managers.map(obj => obj.name).join('|');

      await axios.post(url, this.form)
        .then((response) => {
          this.close();

          this.processFiles(response.data.lead.id)

          if(this.isEdit){
            this.$store.dispatch('editItemFromTable', {
              id: this.options.id,
              table: this.options.table,
              actionType: this.options.actionType,
            })
          }
          else{
            this.$store.dispatch('createItemInTable', {
              table: this.options.table,
              actionType: this.options.actionType,
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    processFiles(id){
      let data = new FormData()
      const uploadKeys = Object.keys(this.upload)

      for (let z = 0; z < uploadKeys.length; z++) {
        const key = uploadKeys[z]
        for (let i = 0; i < this.upload[key].length; i++) {
          if (this.upload[key][i].name !== null) {
            let f = {}
            if (this.upload[key][i].type == 'server') {
              f = JSON.stringify(this.upload[key][i])
            } else {
              data.append(key + '_names[' + i + ']', this.upload[key][i].name)
              f = this.upload[key][i].file
            }
            data.append(key + '[' + i + ']', f)
          }
        }
      }
      
      axios.post('admin/leads/' + id + '/files', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((response) => {
        this.close()
      })
      .catch((errors) => {
        console.dir(errors)
      })
    },
    changeState(property){
      this.form[property] = !this.form[property]
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
        max-width: 160px;
      }

      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
        .btn {
          padding: 0.5rem;
          background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
          border-radius: 6px;
          color: #fff;
          font-size: 13px;
        }
        .delete-btn{
          margin-left: auto;
          width: 24px;
          height: 24px;
          background-color: #B03636;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg{
            fill: #fff;
            max-width: 16px;
            max-height: 16px;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          width: 30%;
          //width: calc(50% - .75rem);
          //margin: .75rem 0;
          //padding: 1rem 1.75rem;
          //background: #22252B;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #333333;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
  textarea{
    height: 5rem;
  }
}
.gap-12{
  gap: 12px;
}
</style>
