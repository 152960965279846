<template>
  <overlay-scrollbars class="page page-balance">
    <div class="box box_myBalance">
      <div class="header">
        <div class="title">My balance</div>
        <div class="block">
          <Stat class="img" />
          <div class="block_content">
            <div class="subtxt">Balance status</div>
            <div class="txt">{{ number_format(myBalance.total_paid_balance, 2, '.', ' ') }}€</div>
          </div>
        </div>
        <div class="block">
          <Graph class="img" />
          <div class="block_content">
            <div class="subtxt">Total request cost</div>
            <div class="txt">{{ number_format(myBalance.total_balance, 2, '.', ' ') }}€</div>
          </div>
        </div>
        <div class="block">
          <CircleLoad class="img" />
          <div class="block_content">
            <div class="subtxt">Must be covered cost <ArrowFlat class="high" /></div>
            <div class="txt">{{ number_format(myBalance.total_balance - myBalance.total_paid_balance, 2, '.', ' ') }}€</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box box_appList" style="border: 1px solid rgba(23, 99, 251, 1)">
      <div class="header">
        <div class="title">Internal deals</div>
      </div>
      <div class="content">
        <div class="block">
          <div class="hdr">
            <div class="row justify-content-between align-items-center">
              <div class="col">
                
              </div>
              <div class="col">
                <div class="tp">
                  Total: {{ number_format(myBalance.total_leads_balance, 2, '.', ' ') }}
                  |
                  Paid: <span class="paid-value">{{ number_format(myBalance.total_paid_leads_balance, 2, '.', ' ') }}</span>
                  |
                  Must be paid: <span class="not-paid-value">{{ number_format(myBalance.total_leads_balance - myBalance.total_paid_lead_balance, 2, '.', ' ') }}</span>
                  |
                  Total team income: {{ number_format(myBalance.total_team_leads_balance, 2, '.', ' ') }}
                  |
                  Total M&A income: {{ number_format(myBalance.total_income_leads_balance, 2, '.', ' ') }}
                </div>
              </div>
            </div>
          </div>
          <Table
            class="offer-requests"
            :options="leadTableData" 
            :key="leadTableKey"
            @addToBalance="addToBalanceItem"
            @change-page="getLeads"
          />
        </div>
      </div>
    </div>
    <div class="box box_appList">
      <div class="header">
        <div class="title">YOUR APPLICATION LIST</div>
      </div>
      <div class="content">
        <div class="block">
          <div class="hdr">
            <div class="row justify-content-between align-items-center">
              <div class="col">
                <div class="ttl">Requests</div>
              </div>
              <div class="col">
                Total: {{ number_format(myBalance.total_license_offers_balance, 2, '.', ' ') }}
                |
                Paid: {{ number_format(myBalance.total_paid_license_offers_balance, 2, '.', ' ') }}
                |
                Must be paid: {{ number_format(myBalance.total_license_offers_balance - myBalance.total_paid_license_offers_balance, 2, '.', ' ') }}
              </div>
            </div>
          </div>
          <Table
            class="offer-requests"
            :options="licenseOfferTableData" 
            :key="licenseOfferTableKey"
            @change-page="getLicenseOffers"
          />
        </div>
        <div class="block">
          <div class="hdr">
            <div class="row justify-content-between align-items-center">
              <div class="col">
                <div class="ttl">Legal Service</div>
              </div>
              <div class="col">
                Total: {{ number_format(myBalance.total_legal_services_balance, 2, '.', ' ') }}
                |
                Paid: {{ number_format(myBalance.total_paid_legal_services_balance, 2, '.', ' ') }}
                |
                Must be paid: {{ number_format(myBalance.total_legal_services_balance - myBalance.total_paid_legal_services_balance, 2, '.', ' ') }}
              </div>
            </div>
          </div>
          <Table 
            :options="legalService"
            :key="legalServiceTableKey"
            @change-page="getLegalServices" />
        </div>
        <div class="block block_mt" ref="bosBlock">
          <div class="hdr">
            <div class="row justify-content-between align-items-center">
              <div class="col">
                <div class="ttl">Buy & Sell NET License</div>
              </div>
              <div class="col">
                Total: {{ number_format(myBalance.total_business_offers_balance, 2, '.', ' ') }}
                |
                Paid: {{ number_format(myBalance.total_paid_business_offers_balance, 2, '.', ' ') }}
                |
                Must be paid: {{ number_format(myBalance.total_business_offers_balance - myBalance.total_paid_business_offers_balance, 2, '.', ' ') }}
              </div>
            </div>
          </div>
          <Table 
            :options="businesOffers" 
            :key="businesOffersKey"
            @change-page="getBusinesOffers" />
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Info from './../components/elem/Info.vue'
import Table from './../components/elem/Table.vue'

import Stat from './../components/img/Stat.vue'
import Graph from './../components/img/Graph.vue'
import CircleLoad from './../components/img/CircleLoad.vue'
import ArrowFlat from './../components/img/ArrowFlat.vue'
import Bank2 from './../components/img/Bank2.vue'
import CreditCards from './../components/img/CreditCards.vue'
import Close from './../components/img/Close.vue'

export default {
  components: {
    Info,
    Table,
    Stat,
    Graph,
    CircleLoad,
    ArrowFlat,
    Bank2,
    CreditCards,
    Close,
  },
  data: () => ({
    myBalance: {
      total_balance: 0,
      total_paid_balance: 0,
      must_be_paid: 0,
      total_license_offers_balance: 0,
      total_paid_license_offers_balance: 0,
      must_be_paid_license_offers: 0,
      total_business_offers_balance: 0,
      total_paid_business_offers_balance: 0,
      must_be_paid_business_offers: 0,
      total_legal_services_balance: 0,
      total_paid_legal_services_balance: 0,
      must_be_paid_legal_services: 0,
      total_leads_balance: 0,
      total_paid_leads_balance: 0,
      must_be_paid_leads_balance: 0,
      total_team_leads_balance: 0,
      total_income_leads_balance: 0,
    },
    legalServiceTotal: 0,
    legalServicePaidTotal: 0,
    legalServiceTableKey: 0,
    legalService: {
      colsWidth: ['5%', '10%', '10%', '10%', '12%', '8%', '22%', '10%', '13%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'User' },
        { ttl: 'Name of Legal Co.', info: null },
        { ttl: 'Country', info: null },
        { ttl: 'Type of Services', info: null },
        { ttl: 'Price', info: null },
        { ttl: 'Description of Benefit', info: null },
        { ttl: 'Paid', info: null },
        { ttl: 'Service time deadline', info: null },
      ],
      body: [],
      paginationData: null,
      footer: {
        type: 'Total showed price',
        val: 0,
        paid_type: 'Total showed paid',
        paid_val: 0
      }
    },
    businesOffersTotal: 0,
    businesOffersPaidTotal: 0,
    businesOffersKey: 1,
    businesOffers: {
      colsWidth: ['4%', '10%', '12%', '12%', '15%', '25%', '12%', '10%'],
      header: [
        { ttl: '#' },
        { ttl: 'User' },
        { ttl: 'Type of Business' },
        { ttl: 'Type of License' },
        { ttl: 'Country' },
        { ttl: 'Description' },
        { ttl: 'Price' },
        { ttl: 'Paid', info: null }
      ],
      body: [],
      paginationData: null,
      footer: {
        type: 'Total showed price',
        val: 0,
        paid_type: 'Total showed paid',
        paid_val: 0
      }
    },
    licenseOffersTotal: 0,
    licenseOffersPaidTotal: 0,
    licenseOfferTableKey: 0,
    licenseOfferTableData: {
      objName: 'licenseOfferTableData',
      colsWidth: ['2%', '12%', '12%', '12%', '12%', '12%', /*'10%', '10%', '10%',*/ '12%', '12%', '12%'],
      header: [
        { ttl: '#' },
        { ttl: 'User', info: null },
        { ttl: 'Bank type', info: null },
        { ttl: 'Countries', info: null },
        { ttl: 'License type', info: null},
        { ttl: 'Software type', info: null},
        //{ ttl: 'Account type', info: null },
        //{ ttl: 'Membership type', info: null },
        //{ ttl: 'Operetional type', info: null },
        { ttl: 'Status', info: null },
        { ttl: 'Price', info: null },
        { ttl: 'Paid', info: null }
      ],
      body: [],
      paginationData: null,
      sort: {
        name: '',
        order: 'desc',
        loading: false
      },
      footer: {
        type: 'Total showed price',
        val: 0,
        paid_type: 'Total showed paid',
        paid_val: 0
      }
    },
    leadsTotal: 0,
    leadsPaidTotal: 0,
    leadTableKey: 0,
    leadTableData: {
      objName: 'leadTableData',
      colsWidth: ['2%', '6.5%', '6.5%', '6.5%', '6.5%', '6.5%', '6.5%', '6.5%', '6.5%', '6.5%', '6.5%', '6.5%', '6.5%', '6.5%', '6.5%', '6.5%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Internal customer number', info: null },
        { ttl: 'Customer number', info: null },
        { ttl: 'Name of Customer', info: null },
        { ttl: 'Fintech type', info: null },
        { ttl: 'Last Request', info: null },
        { ttl: 'Manager', info: null },
        { ttl: 'Agent name', info: null },
        { ttl: 'Status', info: null},
        { ttl: 'Total amount', info: null},
        { ttl: 'Seller amount', info: null},
        { ttl: 'Agent amount', info: null},
        { ttl: 'Team amount', info: null},
        { ttl: 'M&A income', info: null},
        { ttl: 'Deadline income', info: null},
        { ttl: 'Type of payment', info: null},
        
        { ttl: '', info: null },
      ],
      body: [],
      paginationData: null,
      sort: {
        name: '',
        order: 'desc',
        loading: false
      },
      footer: {
        type: 'Total showed price',
        val: 0,
        paid_type: 'Total showed paid',
        paid_val: 0
      }
    },
  }),
  created(){
    this.getTotalBalance()
    this.getLeads()
    this.getLicenseOffers()
    this.getLegalServices()
    this.getBusinesOffers()
  },
  methods:{
    getTotalBalance () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/settings/all-balances')
        .then(function (response) {
          if (response.data.success) {
            that.myBalance = response.data.result
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    calculateTotalTransactionsSum(transactions){
      let sum = 0
      
      if (Array.isArray(transactions)) {
        transactions.forEach((transaction, i) => {
          sum += +transaction.amount
        })
      } else if (typeof transactions === 'object' && transactions !== null) {
        Object.values(transactions).forEach((transaction, i) => {
          sum += +transaction.amount
        })
      }

      return sum
    },
    getLeads (page = 1) {
      const that = this
      const basePath = process.env.VUE_APP_BACKEND_API_URL + 'admin/leads?page=' + page + '&per_page=15&is_balances=true'
      axios.get(basePath)
        .then(function (response) {
          that.leadTableData.paginationData = response.data
          that.leadTableData.body = []
          let totalPrice = 0
          let paidTotalPrice = 0

          response.data.data.data.forEach(el => {
            that.leadTableData.body.push([
              el.id,
              el.customer_id + el.customer ? el.customer.name + ' ' + el.customer.email : '',
              el.client_number,
              {type: 'link', link: el.web_site, text: el.client_name ? el.client_name : el.web_site },
              el.fintech_type_id ? el.fintech_type.name : '',
              el.last_request,
              el.manager_name,
              el.agent_name ? el.agent_name : '',
              {type: 'status', value: el.status,},
              el.balance_total_amount,
              el.balance_seller_amount,
              el.balance_agent_amount,
              el.balance_team_amount,
              el.balance_income_amount,
              {type: 'deadline_lite', ...that.getDeadlinePeriod(el.income_deadline_at)},
              el.balance_payment_type,
              { type: 'add-balance-actions', id: el.id }, 
            ])

            paidTotalPrice += parseFloat(el.paid_balance) ? parseFloat(el.paid_balance) : 0
            totalPrice += parseFloat(el.balance_total_amount) ? parseFloat(el.balance_total_amount) : 0
          })
          
          that.leadsTotal = totalPrice
          that.leadsPaidTotal = paidTotalPrice
          
          totalPrice = that.number_format(totalPrice, 2, '.', ' ') + '€'

          paidTotalPrice = that.number_format(paidTotalPrice, 2, '.', ' ') + '€'

          that.leadTableData.footer.val = totalPrice

          that.leadTableData.footer.paid_val = paidTotalPrice
          
          that.leadTableKey++

          if (that.leadTableData.sort.name) that.leadTableData.sort.loading = false
        })
        .catch((errors) => {
          console.dir(errors)
        })
    }, 
    addToBalanceItem(obj){
      this.$store.commit('setModal', {
        template: 'lead-add-to-balance',
        options: {
          id: obj.id,
          form: obj,
          actionType: 'add-to-balance',
          table: 'leads',
          objectType: 'lead',
          successfullMessage: '',
        }
      })
    },
    getDeadlinePeriod(date){
      if(date != null){
        const now = new Date();
        const deadlineDate = new Date(date);

        const difference = deadlineDate - now;

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        return { days, hours };
      }
    },
    getLicenseOffers (page = 1) {
      const that = this
      const basePath = process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers?page=' + page + '&per_page=15&with_transactions=true'
      const sortParams = '&sort=' + this.licenseOfferTableData.sort.name + '&order=' + this.licenseOfferTableData.sort.order
      const searchParams = '&progress_stage=30'
      axios.get(basePath + sortParams + searchParams)
        .then(function (response) {
          that.licenseOfferTableData.paginationData = response.data
          that.licenseOfferTableData.body = []
          let totalPrice = 0
          let paidTotalPrice = 0

          response.data.data.forEach(el => {
            let elTotal = that.calculateTotalTransactionsSum(el.user_transactions)
            that.licenseOfferTableData.body.push([
              el.id,
              el.user.full_name ? el.user.full_name : el.user.name + ' | ' + el.user.email + ' | ' + el.user.member_login,
              that.objectToString(el.bank_types, el.other_bank_type),
              that.objectToString(el.countries),
              that.objectToString(el.license_types, el.other_license_type),
              that.objectToString(el.software_types, el.other_software_type),
              //that.objectToString(el.account_types, el.other_account_type),
              //that.objectToString(el.membership_types, el.other_membership_type),
              //that.objectToString(el.operetional_types, el.other_operetional_type),
              el.status.name + ' Progress: ' + el.progress_stage,
              that.number_format(el.price, 2, '.', ' ') + '€',
              {type: 'transactions', transactions: el.user_transactions, total: elTotal, price: +el.price},
            ])

            paidTotalPrice += parseFloat(elTotal) ? parseFloat(elTotal) : 0
            totalPrice += parseFloat(el.price) ? parseFloat(el.price) : 0
          })
          
          that.licenseOffersTotal = totalPrice
          that.licenseOffersPaidTotal = paidTotalPrice
          
          totalPrice = that.number_format(totalPrice, 2, '.', ' ') + '€'

          paidTotalPrice = that.number_format(paidTotalPrice, 2, '.', ' ') + '€'

          that.licenseOfferTableData.footer.val = totalPrice

          that.licenseOfferTableData.footer.paid_val = paidTotalPrice
          
          that.licenseOfferTableKey++

          if (that.licenseOfferTableData.sort.name) that.licenseOfferTableData.sort.loading = false
        })
        .catch((errors) => {
          console.dir(errors)
        })
    }, 
    getLegalServices (page = 1) {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/legal-atlas/all-applied?page=' + page + '&per_page=15')
        .then(function (response) {
          that.legalService.paginationData = response.data,
          that.legalService.body = []
          that.legalData = response.data.data

          let totalPrice = 0
          let paidTotalPrice = 0

          if (Array.isArray(that.legalData)) {
            that.legalData.forEach(el => {
              let elTotal = that.calculateTotalTransactionsSum(el.userTransactions)

              that.legalService.body.push([
                el.id, el.user ? el.user.name + ' | ' + el.user.email + ' | ' + el.user.member_login : '', el.legal_name, el.country ? el.country.code : '', el.services_type, that.number_format(el.price, 2, '.', ' ') + '€',
                el.benefit_description, {type: 'transactions', transactions: el.userTransactions, total: elTotal, price: +el.price}, { type: 'deadline', days: el.time_left.days_number, days_text: el.time_left.days_text, hours: el.time_left.hours_number, hours_text: el.time_left.hours_text, id: el.id }
              ])

              totalPrice += parseFloat(el.price) ? parseFloat(el.price) : 0
              paidTotalPrice += parseFloat(elTotal) ? parseFloat(elTotal) : 0
            })
          } else if (typeof that.legalData === 'object' && that.legalData !== null) {
              Object.values(that.legalData).forEach(function(el) {
                let elTotal = that.calculateTotalTransactionsSum(el.userTransactions)

                that.legalService.body.push([
                  el.id, el.user ? el.user.name + ' | ' + el.user.email + ' | ' + el.user.member_login : '', el.legal_name, el.country ? el.country.code : '', el.services_type, that.number_format(el.price, 2, '.', ' ') + '€',
                  el.benefit_description,  {type: 'transactions', transactions: el.userTransactions, total: elTotal, price: +el.price}, { type: 'deadline', days: el.time_left.days_number, days_text: el.time_left.days_text, hours: el.time_left.hours_number, hours_text: el.time_left.hours_text, id: el.id }
                ])

                totalPrice += parseFloat(el.price) ? parseFloat(el.price) : 0
                paidTotalPrice += parseFloat(elTotal) ? parseFloat(elTotal) : 0
              });
          }

          that.legalServiceTotal = totalPrice
          that.legalServicePaidTotal = paidTotalPrice
          
          totalPrice = that.number_format(totalPrice, 2, '.', ' ') + '€'

          paidTotalPrice = that.number_format(paidTotalPrice, 2, '.', ' ') + '€'

          that.legalService.footer.val = totalPrice

          that.legalService.footer.paid_val = paidTotalPrice
          
          that.legalServiceTableKey++
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getBusinesOffers (page = 1) {
      const that = this

      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/business-offers/all-applied?page=' + page + '&per_page=15')
        .then(function (response) {
          let businesOffersData = response.data.data
          let totalPrice = 0
          let paidTotalPrice = 0

          that.businesOffers.paginationData = response.data,
          that.businesOffers.body = []

          if (Array.isArray(businesOffersData)) {
            businesOffersData.forEach(el => {
              let elTotal = that.calculateTotalTransactionsSum(el.userTransactions)

              that.businesOffers.body.push([
                el.id, el.user ? el.user.name + ' | ' + el.user.email + ' | ' + el.user.member_login : '', el.business_type, el.license_type, el.country ? el.country.name : '', el.description, that.number_format(el.price, 2, '.', ' ')  + '€', {type: 'transactions', transactions: el.userTransactions, total: elTotal, price: +el.price}
              ])

              totalPrice += parseFloat(el.price) ? parseFloat(el.price) : 0
              paidTotalPrice += parseFloat(elTotal) ? parseFloat(elTotal) : 0
            })
          } else if (typeof businesOffersData === 'object' && businesOffersData !== null) {
            Object.values(businesOffersData).forEach(function(el) {
              let elTotal = that.calculateTotalTransactionsSum(el.userTransactions)

              that.businesOffers.body.push([
                el.id, el.user ? el.user.name + ' | ' + el.user.email + ' | ' + el.user.member_login : '', el.business_type, el.license_type, el.country ? el.country.name : '', el.description, that.number_format(el.price, 2, '.', ' ')  + '€', {type: 'transactions', transactions: el.userTransactions, total: elTotal, price: +el.price}
              ])

              totalPrice += parseFloat(el.price) ? parseFloat(el.price) : 0
              paidTotalPrice += parseFloat(elTotal) ? parseFloat(elTotal) : 0
            });
          }

          that.businesOffersTotal = totalPrice

          that.businesOffersPaidTotal = totalPrice
          
          totalPrice = that.number_format(totalPrice, 2, '.', ' ') + '€'

          paidTotalPrice = that.number_format(paidTotalPrice, 2, '.', ' ') + '€'

          that.businesOffers.footer.val = totalPrice

          that.businesOffers.footer.paid_val = paidTotalPrice
          
          that.businesOffersKey++
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    objectToString(obj, alt = null){
			let array = [];

			obj.forEach((el) => {
    			if(el.name != 'Other'){
					array.push(el.name)
				}
			});

			if(alt){
				array.push(alt)	
			}

			return array.join(', ')
		},
  },
  watch: {
      '$store.state.editItemFromTableData': function(data) {
        let page = this.leadTableData.paginationData.current_page

        this.getLeads(page)
      }
    }
}
</script>

<style lang="scss" scoped>
.paid-value{
  color: green;
}
.not-paid-value{
  color: red;
}
.tp{
  font-size: 1.15rem;
  padding: 0.25rem 1rem;
  margin: 0.25rem;
  display: flex;
  align-items: baseline;
  justify-content: center;
  background-color: #272E35;
  border-radius: 6px;
}
.page-balance {
  .box {
    &_myBalance {
      background: linear-gradient(113.24deg, #1763fb 16.01%, #1763fb 106.71%);

      .header {
        display: flex;
        align-items: center;
        background-image: url('/img/group_61.png');
        background-position: 102% -0.5rem;
        background-repeat: no-repeat;
        background-size: auto;
        border-radius: 14px;

        .block {
          display: flex;
          padding: .25rem 1rem;

          svg.img {
            width: 1.5rem;
            height: 1.5rem;
            margin-top: 10px;
          }

          &_content {
            padding: 0 .75rem;

            .subtxt {
              position: relative;
              font-size: 10px;

              ::v-deep svg {
                width: .75rem;
                height: .75rem;
                margin: 0 4px;

                &.high {
                  transform: rotate(-90deg);
                  path {
                    fill: #B03636;
                  }
                }
              }
            }

            .txt {
              display: flex;
              align-items: center;
              font-size: 1.25rem;
              line-height: 1.1;

              ::v-deep svg {
                width: .75rem;
                height: .75rem;
                margin: 6px;

                &.high {
                  transform: rotate(-90deg);
                  path {
                    fill: #B03636;
                  }
                }
              }
            }
          }

          &-paymentDeadline {
            padding: 0.25rem 1.5rem;
            margin: 0 .75rem 0 2rem;
            background: #272E35;
            border-radius: 6px;
            align-items: baseline;

            .val {
              margin: 0 .25rem 0 .5rem;
              font-size: 22px;
              color: #1763fb;
            }

            .desc {
              margin: 0 .25rem 0 -0.125rem;
              font-size: 14px;
            }
          }
        }
      }
    }

    &_appList {
      .content {
        padding: 0 0 1rem;

        .block {
          margin-bottom: 1.5rem;

          .hdr {
            display: flex;
            align-items: center;
            margin-left: .75rem;
            margin-bottom: .5rem;

            svg.img {
              width: 1.75rem;
              height: 1.75rem;
              margin-right: .5rem;
            }

            .ttl {
              padding: .25rem .5rem;
            }
          }
        }
      }
    }
  }
  .row{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 4px;
    align-items: center;
    &.justify-content-between{
      justify-content: space-between;
    }
    &.align-items-center{
      align-items: center;
    }
  }
}
</style>