<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">Process</div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="label">Process</div>
        <div class="data">
          <input type="text" name="process" class="inpt" v-model="form.process">
        </div>
      </div>
      <div class="row" v-if="$store.state.user.is_super_admin">
        <div class="label">Manager can see</div>
        <div class="data c-tmblr">
          <Tmblr :active="!!form.is_manager_can" :class="'c-tmblr'" :text="'Manager can see'" @changeState="changeState('is_manager_can')" />
        </div>
      </div>
      <div class="row">
        <div class="label">Process links</div>
        <div class="data">
          <div v-for="(link, i) in form.process_links">
            <div>
              <div class="row">
                <div class="data">
                  <div class="row">
                    <div class="label">
                      Link № {{ i + 1 }}
                    </div>
                    <div class="data">
                      <div class="delete-btn" @click="deleteProcessLink(i)">
                        <Close class="img"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row gap-12">
                <div class="col">
                  <input type="text" name="service_name" class="inpt" v-model="link.service_name">
                </div>
                <div class="col">
                  <input type="text" name="link" class="inpt" v-model="link.link">
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="data">
                <span class="btn" @click="addProcessLink">
                  Add link
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label">Managers</div>
        <div class="data">
          <div v-for="(formManager, formManagerIndex) in form.managers">
            <div>
              <div class="row">
                <div class="data">
                  <div class="row">
                    <div class="label">
                      Responsible Manager № {{ formManagerIndex + 1 }}
                    </div>
                    <div class="data">
                      <div class="delete-btn" @click="deleteManager(formManagerIndex)">
                        <Close class="img"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <select name="manager_name" class="inpt" v-model="formManager.name">
                <option :value="null">Select manager</option>
                <option 
                  v-for="(manager, i) in managers" 
                  :value="manager.name"
                >
                  {{ manager.name }}
                </option>
              </select>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="data">
                <span class="btn" @click="addManager">
                  Add manager
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-body">
        <div class="accordion-body-header">
          <h4>
            Steps
          </h4>
          <div class="new-accordion-body-btn" @click="addStep()">
            Add step
          </div>
        </div>
        <draggable tag="ul" :list="form.process_steps" class="new-accordion-steps list-group" handle=".handle">
          <li
            class="new-accordion-step list-group-item"
            v-for="(step, idx) in form.process_steps"
            :key="step.sequence"
            :style="{ 'border-color': step.color }"
          >
            <div class="step-row">
              <div class="step-drag handle">
                <AlignJustify class="img" />
              </div>
              <div class="step-body">
                <div class="step-input-wrap">
                  <label>
                    Step description
                  </label>
                  <textarea class="inpt" v-model="step.description" /></textarea>
                </div>
                <div class="step-input-wrap">
                  <label>
                    Step link
                  </label>
                  <input type="text" class="inpt" v-model="step.link" />
                </div>
              </div>
              <div class="step-delete"  @click="removeStep(step, idx)">
                <span>
                  <Close class="img" />
                </span>
              </div>
            </div>
          </li>
        </draggable>
      </div>
      <div class="row row_files">
        <div class="box box_addFiles">
          <div v-if="getUploadedFiles('documents').length" class="content content_many">
            <draggable v-model="upload_process.documents" tag="div" class="draggable">
            <div v-for="(file, key) in getUploadedFiles('documents')" :key="key" class="file">
              <BlueFile class="img" />
              <div class="btn btn_del" @click="delFile('documents', key, file)"><Close class="img" /></div>
              <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'documents')"><BlueDownload class="img" /></div>
              <div class="desc">{{ file.name }}</div>
            </div>
            </draggable>
            <div class="add">
              <div class="btn" @click="$refs.documents[upload_process.documents.length - 1].click()"><Close class="img" /></div>
            </div>
          </div>
          <div v-else class="content">
            <div class="choose">
              <div class="btn" @click="$refs.documents[0].click()">Process document</div>
            </div>
          </div>
            <input
              v-for="(i, key) in upload_process.documents"
              :key="key"
              type="file"
              :name="`documents_${key}`"
              class="hide"
              ref="documents"
              @change="addInput($event, 'documents', key)"
            >
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save">Save</div>
    </div>
  </div>
</template>

<script>
import Close from '../img/Close.vue'
import Tmblr from '../elem/Tmblr.vue'
import BlueFile from '../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from '../img/BlueDownload.vue'
import AlignJustify from '../img/AlignJustify.vue'
import Pen from '@/components/img/Pen.vue'

export default {
  components: {
    Close,
    Tmblr,
    BlueDownload,
    BlueFile,
    draggable,
    AlignJustify,
    Pen,
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data(){
    return{
      managers: [],
      form: {
        process: null,
        is_manager_can: true,
        process_links: [],
        process_links_to_delete: [],
        managers: [],
        process_steps: [],
      },
      error: null,
      upload_process: {
        documents: [{ name: null }],
      }
    }
  },
  computed: {
    isEdit () {
      return this.options && this.options.id && this.options.form
    }
  },
  async created () {
    if(this.isEdit){
      await this.getProcess()
      await this.updateFormData()
      await this.getDocuments()
    }
    
    await this.getManagers()
  },
  methods: {
    addStep(){
      let sequence = this.form.process_steps.length + 1
    
      this.form.process_steps.push({
        name: '',
        description: '',
        manager_name: '',
        color: '',
        link: '',
        sequence: sequence,
        upload: {
          documents: [{name: null}]
        }
      })
    },
    async removeStep(step, index){
      if(step.hasOwnProperty('id')){
        await axios.delete('admin/process-steps/' + step.id)
        .then(function (response) {
          if(response.data.success){
            that.$noty.success('Step was successfully deleted.')
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    
      this.form.process_steps.splice(index, 1)
    },
    changeState(property){
      this.form[property] = !this.form[property]
    },
    addProcessLink(){
      this.form.process_links.push({
        service_name: null,
        link: null
      })
    },
    deleteProcessLink(index){
      if(this.form.process_links[index].hasOwnProperty('id')){
        this.form.process_links_to_delete.push(this.form.process_links[index])
      }

      this.form.process_links.splice(index, 1)
    },
    addManager(){
      this.form.managers.push({
        name: null
      })
    },
    deleteManager(index){
      this.form.managers.splice(index, 1)
    },
    updateFormData(){
      let arrManagers = [];
      
      if(this.form.manager_name){
        arrManagers = this.form.manager_name.split('|').map(name => {
          return { name: name };
        });
      }
      
      this.$set(this.form, 'managers',  arrManagers)
    },
    async getDocuments () {
      const that = this
      const id = this.options.id
      
      if(id){
        await axios.get('admin/processes/' + id + '/get-file/documents')
        .then((response) => {
          let docsData = response.data

          if(docsData.length > 0) {
            that.upload_process.documents = docsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
      }
    },
    getUploadedFiles(el) {
      return this.upload_process[el].filter(e => e.name !== null)
    },
    delFile(name, key, file) {
      if (this.upload_process[name].length > 1) {
        this.upload_process[name].splice(key, 1)
      } else {
        this.upload_process[name][0].name = null
      }

      if(file.id && this.options.id){
        axios.post('admin/processes/' + this.options.id +'/delete-file', {
        collection: name,
        media_id: file.id,
      })
        .then(function () {
          
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    addInput(event, name, key) {
      if (this.$refs[name][key].files.length) {
        const file_name = this.$refs[name][key].files[0].name
        if (this.getUploadedFiles(name).some(e => e.name == file_name)) {
          this.$noty.error('Error! A file with this name has already been added.')
          return false
        }

        const extension = this.$refs[name][key].files[0].name.split('.').pop()
        const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx']
        if (!availableExtensionsArr.includes(extension.toLowerCase())) {
          this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
          return false
        }

        if (this.$refs[name][key].files[0].size / 1024 / 1024 > 10) {
          this.$noty.error('File is too large. Maximum - 10MB.')
          return false
        }

        this.upload_process[name] = this.upload_process[name].filter(e => e.name !== null)
        if (this.$refs[name].length < 10) {
          this.upload_process[name].push({ name: file_name, file: event.target.files[0]})
        } else {
          this.$noty.error('Maximum - 10 files')
        }
      }
    },
    downloadFile(file, type) {
      const id = this.options.id
      if(file.url){
        this.openFile(file.url)
      }
      else if(id){
        axios.post('admin/processes/' + id +'/get-file-link', {
        collection: type,
        media_id: file.id,
      })
        .then(function (response) {
          if (response.data) {
            const url = response.data
            var fileURL = response.data;
            this.openFile(fileUrl);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    openFile(url){
      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.setAttribute('target', '_blank')
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async getProcess(){
      const that = this
      await axios.get('admin/processes/' + this.options.id)
      .then(function (response) {
        that.form = response.data
        that.form.process_links_to_delete = []
      })
      .catch(function (error) {
        console.log(error);
      })
	  },
    async getManagers () {
      const that = this
      await axios.get('admin/data/managers')
        .then(function (response) {
          that.managers = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
	  },
    async getAsystents () {
      const that = this
      await axios.get('admin/data/asystents')
        .then(function (response) {
          that.asystents = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async save(){
      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/processes'

      if (this.isEdit) {
        url += '/' + this.options.id
      }

      this.form.manager_name = this.form.managers.map(obj => obj.name).join('|');
      
      if(!this.$store.state.user.is_super_admin){
        this.form.is_manager_can = true
      }
      
      await axios.post(url, this.form)
        .then((response) => {
          this.close();

          this.processFiles(response.data.process.id)

          if(this.isEdit){
            this.$store.dispatch('editItemFromTable', {
              id: this.options.id,
              table: this.options.table,
              actionType: this.options.actionType,
            })
          }
          else{
            this.$store.dispatch('createItemInTable', {
              table: this.options.table,
              actionType: this.options.actionType,
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    processFiles(id){
      let data = new FormData()
      const upload_processKeys = Object.keys(this.upload_process)

      for (let z = 0; z < upload_processKeys.length; z++) {
        const key = upload_processKeys[z]
        for (let i = 0; i < this.upload_process[key].length; i++) {
          if (this.upload_process[key][i].name !== null) {
            let f = {}
            if (this.upload_process[key][i].type == 'server') {
              f = JSON.stringify(this.upload_process[key][i])
            } else {
              data.append(key + '_names[' + i + ']', this.upload_process[key][i].name)
              f = this.upload_process[key][i].file
            }
            data.append(key + '[' + i + ']', f)
          }
        }
      }
      
      axios.post('admin/processes/' + id + '/files', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((response) => {
        this.close()
      })
      .catch((errors) => {
        console.dir(errors)
      })
    },
    changeState(property){
      this.form[property] = !this.form[property]
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
        max-width: 160px;
      }

      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
        .btn {
          padding: 0.5rem;
          background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
          border-radius: 6px;
          color: #fff;
          font-size: 13px;
        }
        .delete-btn{
          margin-left: auto;
          width: 24px;
          height: 24px;
          background-color: #B03636;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg{
            fill: #fff;
            max-width: 16px;
            max-height: 16px;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          width: 30%;
          //width: calc(50% - .75rem);
          //margin: .75rem 0;
          //padding: 1rem 1.75rem;
          //background: #22252B;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #333333;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
  textarea{
    height: 5rem;
  }
}
.gap-12{
  gap: 12px;
}
.accordion{
  &-wrap{
    width: 100%;
    margin-bottom: 20px;
  }
  &-title{
    flex-grow: 1;
  }
  &-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-btns{
    display: flex;
  }
  &-delete-btn{
    width: 14px;
    height: 14px;
    margin-right: 10px;
    ::v-deep svg.img path {
      fill: red;
    }
  }
  &-toggle-btn{
    width: 24px;
    height: 24px;
    border: 1px solid #1763fb;
    border-radius: 50%;
    color: #1763fb;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-body{
    display: block;
    width: 100%;
    .inpt{
      width: 100%;
      color: #000;
    }
  }
  &-body{
    margin-top: 20px;
    &-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      &-input-wrap{
        width: 100%;
        input{
          width: 100%;
        }
      }
    }
    &-btn{
      border: 1px solid #1763fb;
      background: #1763fb;
      border-radius: 6px;
      padding: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
  }
  &-label{
    margin-top: 12px;
    display: block;
  }
}
.new-accordion{
  &-wrap{
    width: 100%;
    .inpt{
      width: 100%;
    }
  }
  &-body{
    margin-top: 20px;
    &-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    &-btn{
      border: 1px solid #1763fb;
      background: #1763fb;
      border-radius: 6px;
      padding: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
  }
  &-label{
    margin-top: 12px;
    display: block;
  }
}
.new-accordion, .accordion{
  &-step{
    border: 1px dashed #1763fb;
    border-bottom: 0;
    padding: 8px;
    background: #fff;
    &:last-child{
      border-bottom: 1px dashed #1763fb;
    }
    .step-row{
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      max-width: 100%;
      flex: 0 0 100%;
    }
    .step-drag, .step-delete{
      width: 32px;
      max-width: 32px;
      padding: 8px;
    }
    .step-body{
      flex-grow: 1;
    }
    .step-drag{
      padding-left: 0;
      svg.img{
        fill: #1763fb !important;
        path{
          fill: #1763fb !important;
        }
      }
    }
    .step-delete{
      padding-right: 0;
      ::v-deep svg.img path {
        fill: red;
      }
    }
    .step-input-wrap{
      margin-bottom: 8px;
      &.double{
        display: flex;
        gap: 8px;
        &>*{
          flex: 0 0 calc(50% - 4px);
        }
      }
    }
  }
}
</style>
