<template>
	<div class="box-container" ref="legal_atlases_tbl">
      <div class="transparent-box box_b-info" style="width: 100%;">
        <div class="content" >
          <div class="block">
            <div class="hdr">
              <div class="ttl-wrapper">
                <div class="ttl title">Offer Requests</div>
              </div>
            </div>
            <Table
              class="offer-requests"
              :options="licenseOfferTableData" :key="licenseOfferTableKey"
              @change-page="getLicenseOffers"
              @editItem="editLicenseOffer"
              @deleteItem="deleteLicenseOffer"
            />
          </div>
        </div>
      </div>
	</div>
</template>

<script>
import Table from '@/components/elem/Table.vue'
import Pagination from 'laravel-vue-pagination'

export default {
	components: {
		Table,
		Pagination,
	},
	data () {
		return {
			initRequest: false
		}
	},
	props: {
	  customer: {
	    type: Object,
		required: true
	  }
	},
	data () {
		return {
			licenseOfferTableKey: 0,
			licenseOfferTableData: {
		      objName: 'licenseOfferTableData',
		      colsWidth: ['11.5%', '11.5%', '11.5%', '11.5%', '11.5%', '11.5%', '11.5%', '11.5%', '8%'],
		      header: [
		        { ttl: 'Bank type', info: null },
				{ ttl: 'Countries', info: null },
		        { ttl: 'License type', info: null},
				{ ttl: 'Software type', info: null},
		        { ttl: 'Account type', info: null },
				{ ttl: 'Membership type', info: null },
				{ ttl: 'Operetional type', info: null },
		        { ttl: 'Status', info: null },
		        { ttl: '', info: null },
		      ],
		      body: [],
		      paginationData: null,
		      sort: {
		        name: '',
		        order: 'asc',
		        loading: false
		      }
		    }
		}
	}, 
	methods: {
	    getLicenseOffers (page = 1) {
	      const that = this
	      const basePath = process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers?page=' + page + '&customer_id=' + this.customer.id + '&per_page=15'
	      const sortParams = '&sort=' + this.licenseOfferTableData.sort.name + '&order=' + this.licenseOfferTableData.sort.order
	      axios.get(basePath + sortParams)
	        .then(function (response) {
	          that.licenseOfferTableData.paginationData = response.data
	          that.licenseOfferTableData.body = []
	          response.data.data.forEach(el => {
	            that.licenseOfferTableData.body.push([
	              that.objectToString(el.bank_types, el.other_bank_type),
	              that.objectToString(el.countries),
	              that.objectToString(el.license_types, el.other_license_type),
				  that.objectToString(el.software_types, el.other_software_type),
	              that.objectToString(el.account_types, el.other_account_type),
	              that.objectToString(el.membership_types, el.other_membership_type),
				  that.objectToString(el.operetional_types, el.other_operetional_type),
				  el.status.name + ' Progress: ' + el.progress_stage,
	              { type: 'edit-delete-pdf-actions', id: el.id, pdf_url: process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers/generate-pdf/' + el.id },
	            ])
	          })
	          if (that.licenseOfferTableData.sort.name) that.licenseOfferTableData.sort.loading = false
	        })
	        .catch((errors) => {
	          console.dir(errors)
	        })
	    }, 
		objectToString(obj, alt = null){
			let array = [];

			obj.forEach((el) => {
    			if(el.name != 'Other'){
					array.push(el.name)
				}
			});

			if(alt){
				array.push(alt)	
			}

			return array.join(', ')
		},
	    /*sortBy (data) {
	      this[data.obj].sort.loading = true
	      this[data.obj].sort.order = this[data.obj].sort.name === data.sort && this[data.obj].sort.order === 'asc'
	        ? 'desc' : 'asc'
	      this[data.obj].sort.name = data.sort
	      this.getLicenseOffers(1)
	    },*/
	    editLicenseOffer (obj) {
	      this.$store.commit('setModal', {
	        template: 'license-offer',
	        options: {
	          id: obj.id,
	          actionType: 'edit',
	          table: 'license-offers',
	          objectType: 'license offers',
	          url: process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers/update/' + obj.id,
	          successfullMessage: 'You successfully updated license offer',
	        }
	      })
	    },
	    deleteLicenseOffer (obj) {
	      this.$store.commit('setModal', {
	        template: 'delete',
	        options: {
	          id: obj.id,
	          actionType: 'delete',
	          table: 'license-offers',
	          type: 'license offers',
	          url: process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers/' + obj.id,
	          successfullMessage: 'You successfully deleted legal atlas',
	        }
	      })
	    },
	    updateCurrentPage(tableName, actionType) {
	      let currentPage = 1
	      if (actionType == 'edit' || actionType == 'delete') {
	        try {
	        currentPage = document.getElementsByClassName(tableName)[0]
	          .getElementsByClassName('pagination')[0]
	          .getElementsByClassName('active')[0]
	          .getElementsByClassName('page-link')[0]
	          .innerText.trim().replace( /\D+/g, '')
	        } catch (e) {
	           console.log(e)
	        }
	      }
	      if (tableName == 'license-offers') {
	        this.getLicenseOffers(currentPage)
	      }
	    },
	},
	created () {
		if (this.customer && this.customer.id && !this.initRequest) {
			this.getLicenseOffers()
			this.initRequest = true
		}
	},
	watch: {
		customer: function(customer) {
			if (this.customer && this.customer.id && !this.initRequest) {
				this.getLicenseOffers()
				this.initRequest = true
			}
		},
		'$store.state.deleteItemFromTableData': function(data) {
	        this.updateCurrentPage(data.table, data.actionType)
	    },
	    '$store.state.editItemFromTableData': function(data) {
	        this.updateCurrentPage(data.table, data.actionType)
	    }
	},
}
</script>

<style>
.title {
	position: relative;
    padding-bottom: 0.25rem;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.91);
    margin-bottom: 10px;
}
</style>