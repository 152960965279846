<template>
  <overlay-scrollbars class="page page-account">
    <div class="box box_selectedAcc">
      <div class="header">
      </div>
      <div class="content">
        <div class="block">
          <div class="hdr">
            <Bank2 class="img" /><div class="ttl">Bank account</div>
            <div @click="createBankAccount" class="btn create-account-btn">Create</div>
          </div>
          <Table class="bank-account" @editItem="editBankAccount" @deleteItem="deleteBankAccount" :key="bankAccKey" :options="bankAcc" @change-page="getBankAccounts" @scroll-top="toBankAccountsTop"/>
        </div>
        <div class="block">
          <div class="hdr">
            <CreditCards class="img" /><div class="ttl">Merchant account</div>
            <div @click="createMerchantAccount" class="btn create-account-btn">Create</div>
          </div>
          <Table class="merchant-account" @editItem="editMerchantAccount" @deleteItem="deleteMerchantAcccount" :key="merchantAccKey" :options="merchantAcc" @change-page="getMerchantAccounts" @scroll-top="toMerchantAccountsTop" />
        </div>
        <div class="block">
          <div class="hdr">
            <Briefcase class="img" /><div class="ttl">Packages</div>
            <!-- <div @click="createPackage" class="btn create-account-btn">Create</div> -->
          </div>
          <Table
            class="tariffPackages"
            :options="tariffPackages" :key="tariffPackagesKey"
            @editItem="editTariffPackage"
            @change-page="getTariffPackages"
          />
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Info from './../components/elem/Info.vue'
import Compliance from './../components/elem/Compliance.vue'
import Table from './../components/elem/Table.vue'
import Bank2 from './../components/img/Bank2.vue'
import CreditCards from './../components/img/CreditCards.vue'
import Briefcase from '@/components/img/Briefcase'
import Pagination from 'laravel-vue-pagination'

export default {
  components: {
    Info,
    Compliance,
    Table,
    Bank2,
    CreditCards,
    Briefcase,
    Pagination,
  },
  data: () => ({
    bankAccKey: 0,
    merchantAccKey: 0,
    bankAcc: {
      colsWidth: ['3%', '10%', '10%', '11%', '12%', '15%', '15%', '12%', '10%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Name', info: null },
        { ttl: 'Country', info: null },
        { ttl: 'Type of account', info: null },
        { ttl: 'Open/Integration fee', info: null },
        { ttl: 'Incoming fee', info: null },
        { ttl: 'Outgoing fee', info: null },
        { ttl: 'Onboarding time', info: null },
        { ttl: '', info: null },
      ],
      body: [],
      paginationData: null,
    },
    merchantAcc: {
      colsWidth: ['3%', '10%', '10%', '11%', '12%', '15%', '15%', '12%', '10%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Name', info: null },
        { ttl: 'Country', info: null },
        { ttl: 'Type of Zone', info: null },
        { ttl: 'Open/Integration fee', info: null },
        { ttl: 'Incoming fee', info: null },
        { ttl: 'Outgoing fee', info: null },
        { ttl: 'Onboarding time', info: null },
        { ttl: '', info: null },
      ],
      body: [],
      paginationData: null,
    },
    tariffPackagesKey: 0,
  }),
  computed: {
    ...mapState({
      tariffPackages: state => state.tariff_packages.tariffPackages
    }),
  },
  created () {
    this.$store.dispatch('setUser')
    this.getBankAccounts()
    this.getMerchantAccounts()
    this.getTariffPackages()
  },
  methods: {
    ...mapActions({
      getTariffPackages: 'tariff_packages/getTariffPackages'
    }),
    toBankAccountsTop () {
      console.log('toBankAccountsTop')
    },
    toMerchantAccountsTop () {
      console.log('toMerchantAccountsTop')
    },
    getBankAccounts (page = 1) {
      const that = this
      axios.get('admin/accounts/bank?page=' + page)
        .then(function (response) {
          that.bankAcc.paginationData = response.data
          that.bankAcc.body = []
          that.bankAcc.paginationData.data.forEach(el => {
            const countryCode = (el.country) ? el.country.code : ''
            that.bankAcc.body.push([
              el.serial_number, el.bank_name, countryCode, el.account_type,
              that.number_format(el.open_integration_fee, 2, '.', ' ') + '€',
              el.incoming_fee, el.outgoing_fee, el.onboarding_time_text, { type: 'edit-delete-actions', id: el.id}
            ])
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getMerchantAccounts (page = 1) {
      const that = this
      axios.get('admin/accounts/merchant?page=' + page)
        .then(function (response) {
          that.merchantAcc.paginationData = response.data
          that.merchantAcc.body = []
          that.merchantAcc.paginationData.data.forEach(el => {
            const countryCode = (el.country) ? el.country.code : ''
            that.merchantAcc.body.push([
              el.serial_number, el.license_type, countryCode, el.zone_type, that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, el.onboarding_time_text, { type: 'edit-delete-actions', id: el.id}
            ])
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    // getTariffPackages (page = 1) {
    //   axios.get('admin/accounts/tariff-packages?page=' + page)
    //     .then(({ data }) => {
    //       this.tariffPackages.paginationData = data
    //       data.data.forEach(el => {
    //         this.tariffPackages.body.push([
    //           el.id,
    //           el.name,
    //           el.price && el.price.length ? `${+el.price[0].value} ${el.price[0].currency.code}` : '--',
    //           el.description,
    //           { type: 'edit-delete-actions', id: el.id}
    //         ])
    //       })
    //     })
    //     .catch(err => console.dir(err))
    // },
    createBankAccount () {
      this.$store.commit('setModal', {
        template: 'account',
        options: {
          id: null,
          actionType: 'create',
          table: 'bank-account',
          objectType: 'bank account',
          url: 'admin/accounts',
          successfullMessage: 'You successfully created bank account',
        }
      })
    },
    createMerchantAccount () {
      this.$store.commit('setModal', {
        template: 'account',
        options: {
          id: null,
          actionType: 'create',
          table: 'merchant-account',
          objectType: 'merchant account',
          url: 'admin/accounts',
          successfullMessage: 'You successfully created merchant account',
        }
      })
    },
    createPackage () {
      console.log('createPackage')
    },
    editBankAccount (obj) {
      this.$store.commit('setModal', {
        template: 'account',
        options: {
          id: obj.id,
          actionType: 'edit',
          table: 'bank-account',
          objectType: 'bank account',
          url: 'admin/accounts/update/' + obj.id,
          successfullMessage: 'You successfully updated bank account',
        }
      })
    },
    deleteBankAccount (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'bank-account',
          type: 'account',
          url: 'admin/accounts/' + obj.id,
          successfullMessage: 'You successfully deleted account',
        }
      })
    },
    editMerchantAccount (obj) {
      this.$store.commit('setModal', {
        template: 'account',
        options: {
          id: obj.id,
          actionType: 'edit',
          table: 'merchant-account',
          objectType: 'merchant account',
          url: 'admin/accounts/update/' + obj.id,
          successfullMessage: 'You successfully updated merchant account',
        }
      })
    },
    deleteMerchantAcccount (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'merchant-account',
          type: 'merchant',
          url: 'admin/accounts/' + obj.id,
          successfullMessage: 'You successfully deleted account',
        }
      })
    },
    editTariffPackage (obj) {
      const item = this.tariffPackages.paginationData.data.find(el => +el.id === +obj.id)

      const invoice_file = item.invoice_file_path
        ? { name: item.invoice_file_path.substring(item.invoice_file_path.lastIndexOf('/')+1) }
        : null

      this.$store.commit('setModal', {
        template: 'create-tariff-packages',
        options: {
          id: obj.id,
          form: {
            name: item.name,
            price: item.price,
            description: item.description,
            invoice_file: invoice_file
          }
        }
      })
    },
    updateCurrentPage(tableName, actionType) {
      if (tableName == 'bank-account' || tableName == 'merchant-account') {
          let currentPage = 1
          if (actionType == 'edit' || actionType == 'delete') {
            currentPage = document.getElementsByClassName(tableName)[0]
            .getElementsByClassName('pagination')[0]
            .getElementsByClassName('active')[0]
            .getElementsByClassName('page-link')[0]
            .innerText.trim().replace( /\D+/g, '')
          }
          if (tableName == 'bank-account') {
            this.getBankAccounts(currentPage)
          } else if (tableName == 'merchant-account') {
            this.getMerchantAccounts(currentPage)
          }
      }
    }
  },
  watch: {
    '$store.state.deleteItemFromTableData': function(data) {
        this.updateCurrentPage(data.table, data.actionType)
    },
    '$store.state.editItemFromTableData': function(data) {
        this.updateCurrentPage(data.table, data.actionType)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-account {
  .box {
    &_selectedAcc {
      .content {
        padding: 0 0 1rem;

        .block {
          margin-bottom: 1.5rem;

          .hdr {
            display: flex;
            align-items: center;
            margin-left: .75rem;
            margin-bottom: .5rem;

            svg.img {
              width: 1.75rem;
              height: 1.75rem;
              margin-right: .5rem;
            }

            .ttl {
              padding: .25rem .5rem;
            }
          }
        }
      }
    }
  }
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
}

.page-link span {
    color: white;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    border: 1px solid #dee2e6;
    color: white !important;
}

.create-account-btn {
  border-radius: 1rem;
  padding: 10px;
  margin-left: 30px;
  background-color: #1763fb;
}

::v-deep .tariffPackages {
  .tbl {
    &_row {
      .col:nth-child(4) {
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>