<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        User Transactions
      </div>
    </div>
    <div class="modal__body">
      <div class="form">
        <div class="form-group" v-for="(item, i) in transactions" :key="i">
          <div class="row">
            <div class="col-6">
              <input v-model="item.amount" required type="number" class="form-control" placeholder="Amount">
            </div>
            <div class="col-6">
              <input v-model="item.note" type="text" class="form-control" placeholder="Note">
            </div>
            <!--<model-select class="form-control" 
                  :options="appliedOptions"
                  :optionValue="getOptionText"
                  :optionText="getOptionText"
                  v-model="item.service"
                  @select="onServiceSelect"
                  placeholder="Select service">
            </model-select>-->
            <div class="col-10">
              <select 
                v-model="item.service"  
                @change="onServiceSelect(item)">
                <option >
                  Select service
                </option>
                <option 
                  v-for="(option, i) in appliedOptions" 
                  :key="i" 
                  :value="option">
                  {{ option.name }}
                </option>
              </select>
            </div>
            <div class="col-2">
              <div class="upload-file-wrap">
                <label :for="'media_' + i">
                  <BlueDownload class="img" />
                </label>
                <input 
                  type="file" 
                  @change="handleFileUpload(i, $event)" 
                  :name="'media_' + i" 
                  :id="'media_' + i" 
                  class="hidden"
                  accept=".jpg, .jpeg, .png, .txt, .pdf, .doc, .docx, .xlsx" />
              </div>
            </div>
          </div>
          <div class="row justify-content-between align-items-center">
            <span>
              <span v-if="item.created_at">
                {{ item.created_at | formatDateTime }}
              </span>
              <span class="file-name_wrap" v-if="item.media">
                <a :href="item.media.original_url" v-if="item.media.original_url" target="_blank">
                  {{ item.media.name }}
                </a>
                <span v-else>
                  {{ item.media.name }}
                </span>
              </span>
            </span>
            <span class="delete-btn" @click="deleteUserTransaction(i, item)">
              <Close class="img"/>
            </span>
          </div>
        </div>
        <div class="form-group">
          <div class="btn" @click="addUserTransaction">Add transaction</div>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Save data</div>
    </div>
  </div>
</template>

<script>
import Close from '../img/Close.vue'
import Download from '../img/Download.vue'
import BlueFile from './../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from './../img/BlueDownload.vue'
import { ModelSelect } from 'vue-search-select'
import { ModelListSelect } from 'vue-search-select'

export default {
  components: {
    Close,
    BlueFile,
    draggable,
    BlueDownload,
    Download,
    ModelSelect,
    ModelListSelect
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      transactions: [{
        note: '',
        amount: '',
        user_id: this.options.customerId,
        service: null,
        media: {
          id: null,
          name: '',
          original_url: '',
          file: null,
        }
      }],
      transactionsToDelete: [],
      appliedItems: [],
      appliedOptions: [],
    };
  },
  async created () {
    await this.getAllApplied()
    await this.getUserTransactions()
  },
  methods: {
    onServiceSelect(item){
      this.setItemOfferId(item)
    },
    getOptionText(option) {
        return option.name;
    },
    getOptionValue(option) {
        return option.id;
    },
    async getUserTransactions() {
      axios.get('admin/customers/' + this.options.customerId + '/user-transactions')
      .then((response) => {
        this.transactions = response.data;
        this.transactions.forEach(item => {
          item.media = item.media[0]
          this.$set(item, 'service', this.getItemService(item))
        })
      })
      .catch((error) => {
        console.log(error);
      });
    },
    async getAllApplied() {
      axios.get('admin/customers/' + this.options.customerId + '/get-all-applied')
      .then((response) => {
        this.appliedItems = response.data
        
        Object.keys(this.appliedItems).forEach((key) => {
          const items = this.appliedItems[key]

          items.forEach((item, i) => {
            this.appliedOptions.push({
              name: this.getItemName(item, key),
              type: key,
              id: item.id,
            })
          })
        })
      })
      .catch((error) => {
        console.log(error);
      });
    },
    setItemOfferId(item){
      let field

      if(item.service.type == 'businessOffers'){
        field = 'business_offer_id'
      }
      else if(item.service.type == 'legalServices'){
        field = 'legal_service_id'
      }
      else if(item.service.type == 'licenseOffers'){
        field = 'license_offer_id'
      }

      item.business_offer_id = null
      item.legal_service_id = null
      item.license_offer_id = null

      item[field] = item.service.id
    },
    getItemService(item){
      let key
      let id

      if(item.business_offer_id != null){
        key = 'businessOffers'
        id = item.business_offer_id;
      }
      else if(item.legal_service_id != null){
        key = 'legalServices'
        id = item.legal_service_id;
      }
      else if(item.license_offer_id != null){
        key = 'licenseOffers'
        id = item.license_offer_id
      }

      for (let index = 0; index < this.appliedOptions.length; index++) {
        const option = this.appliedOptions[index];
        
        if(option.type == key && option.id == id){
          return option
        }
      }

      return null
    },
    getItemName(item, key){
      let name = 'ID - ' + item.id

      if(key == 'businessOffers'){
        name += ' ' + item.business_type + ' ' + (item.country != null ? item.country.name : '') + ' ' + item.price
      }
      else if(key == 'legalServices'){
        name += ' ' + item.legal_name + ' ' + (item.country != null ? item.country.name : '') + ' ' + item.price
      }
      else if(key == 'licenseOffers'){
        name += ' ' + (item.license_types[0] ? item.license_types[0].name : '') + ' ' + (item.countries[0] ? item.countries[0].name : '') + ' ' + item.price 
      }

      return name
    },
    addUserTransaction() {
      this.transactions.push({
        note: '',
        amount: '',
        user_id: this.options.customerId,
        service: null,
        media: {
          id: null,
          name: '',
          original_url: '',
          file: null
        },
      });
    },
    deleteUserTransaction(index, item){
      this.transactions.splice(index, 1);
      
      if(item.hasOwnProperty('id') && item.id){
        this.transactionsToDelete.push(item.id)
      }
    },
    handleFileUpload(index, event) {
      const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx']
      const files = event.target.files
      const file = event.target.files[0]
      const extension = file.name.split('.').pop()

      if (files.length !== 1) {
        this.$noty.error('Maximum - 1 files')

        return
      }

      if (!availableExtensionsArr.includes(extension.toLowerCase())) {
        this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
        
        return false
      }

      const fileObject = {
        name: file.name,
        original_url: URL.createObjectURL(file),
        file: file,
      };

      this.transactions[index].media = fileObject
    },
    async submit() {
      const that = this;

      const data = new FormData();
      
      this.transactions.forEach((item, index) => {
        if(item.id){
          data.append(`transactions[${index}][id]`, item.id)
        }

        data.append(`transactions[${index}][amount]`, item.amount)
        data.append(`transactions[${index}][note]`, item.note)
        data.append(`transactions[${index}][license_offer_id]`, item.license_offer_id)
        data.append(`transactions[${index}][legal_service_id]`, item.legal_service_id)
        data.append(`transactions[${index}][business_offer_id]`, item.business_offer_id)

        if(item.media && item.media.file){
          data.append(`receipts[${index}][file]`, item.media.file);
        }
        else if(item.media && item.media.id){
          data.append(`receipts[${index}][id]`, item.media.id);
        }
      });

      this.transactionsToDelete.forEach((item, index) => {
        data.append(`transactionsToDelete[${index}]`, item)
      });

      try {
        const response = await axios.post('admin/customers/' + this.options.customerId + '/user-transactions', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });

        that.close();

        if (response.data.success) {
          that.$noty.success('You successfully applied changes');
        } else {
          that.$noty.error('Error!');
        }
      } catch (error) {
        console.dir(error);
      }
    },
    getFileFormData(){
      let formData = new FormData()

      this.transactions.forEach(item => {
        if(item.media && item.media.file){
          formData.append('receipts[]', item.media.file);
        }
      });

      return formData;
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }
  .form-group{
    .form-control{
      margin-bottom: 0 !important;
      height: 48px !important;
    }
    .row{
      gap: 12px;
      width: 100%;
      flex-wrap: wrap;
      &.justify-content-between{
        justify-content: space-between;
      }
      &.align-items-center{
        align-items: center;
      }
      .col-5{
        flex: 0 1 41.66666667%;
        max-width: 41.66666667%;
      }
      .col-6{
        flex: 0 1 calc(50% - 6px);
        max-width: calc(50% - 6px);
      }
      .col-10{
        flex: 0 1 calc(83.33333333% - 6px);
        max-width: calc(83.33333333% - 6px);
      }
      .col-2{
        flex: 0 1 calc(16.66666667% - 6px);
        max-width: calc(16.66666667% - 6px);
      }
      .upload-file-wrap{
        border: 1px dashed #1763fb;
        height: 48px;
        width: 100%;
        border-radius: 6px;
        margin-bottom: 8px;
        margin: 0 !important;
        label{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
        .img{
          max-height: 32px;
          fill: #1763fb;
          transform: scaleY(-1);
          path{
            fill: #1763fb;
          }
        }
      }
      .hidden{
        display: none;
        opacity: 0;
      }
    }
    .btn{
      color: #ffffff;
      font-size: 12px;
      padding: .5rem 1.5rem;
      background: linear-gradient(113.24deg, #1763fb 16.01%, #1763fb 106.71%);
      box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
      border-radius: 6px;
    }
    select{
      background-color: #e4e9f4;
      padding: 0.5rem;
      border: 1px solid var(--simple_green);
      border-radius: 0.75rem;
      box-shadow: 0px 11px 23px rgba(0, 0, 0, 0.02);
      outline: none;
      height: 48px;
      width: 100%;
    }
    input{
      padding: .5rem;
    }
  }
  .delete-btn{
    width: 1.75rem;
    height: 1.75rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #b03636;
    border-radius: 3px;;
  }
  .file-name_wrap{
    display: inline-block;
    max-width: 150px;
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 19px;
  }
}
</style>
