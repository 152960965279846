<template>
  <div class="page page-accounts" ref="os">
    <div class="box box_selectedAcc">
      <div class="header">
      </div>
      <div class="content">
        <div class="block" ref="bankOffers">
          <div class="hdr">
            <Bank2 class="img" /><div class="ttl">Bank account</div>
          </div>
          <Table
            class="bank-offer"
            style="margin-left: 0px; margin-right: 0px;"
            :options="bankAcc" :key="bankAccKey"
            @editItem="editBankOffer"
            @deleteItem="deleteBankOffer"
            @change-page="getBankOffers"
            @sort="sortBy"
          />
        </div>
        <div class="block" ref="merchantOffers">
          <div class="hdr">
            <CreditCards class="img" /><div class="ttl">Merchant account</div>
          </div>
          <Table
            class="merchant-offer"
            :options="merchantAcc" :key="merchantAccKey"
            @editItem="editMerchantOffer"
            @deleteItem="deleteMerchantOffer"
            @change-page="getMerchantOffers"
            @sort="sortBy"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Info from '@/components/elem/Info.vue'
import Compliance from '@/components/elem/Compliance.vue'
import Table from '@/components/elem/Table.vue'
import Bank2 from '@/components/img/Bank2.vue'
import CreditCards from '@/components/img/CreditCards.vue'
import Pagination from 'laravel-vue-pagination'

export default {
  name: 'Accounts',
  props: {
    provider: {
      type: Object,
      required: true
    }
  },     
  components: {
    Info,
    Compliance,
    Table,
    Bank2,
    CreditCards,
    Pagination,
  },
  data: () => ({
    initRequest: false,
    bankAccKey: 0,
    merchantAccKey: 0,
    bankAcc: {
      objName: 'bankAcc',
      colsWidth: ['3%', '10%', '10%', '11%', '12%', '15%', '15%', '12%', '10%'],
      header: [
        { ttl: '#' },
        { ttl: 'Name', sort: 'type_of_license' },
        { ttl: 'Type of Account', sort: 'account_type' },
        { ttl: 'Open/Integration fee', sort: 'open_integration_fee' },
        { ttl: 'Incoming fee', sort: 'incoming_fee' },
        { ttl: 'Outgoing fee', sort: 'outgoing_fee' },
        { ttl: 'Onboarding time', sort: 'onboarding_time' },
        { ttl: '' },
      ],
      body: [],
      paginationData: null,
      sort: {
        name: '',
        order: 'asc',
        loading: false
      }
    },
    merchantAcc: {
      objName: 'merchantAcc',
      colsWidth: ['3%', '10%', '10%', '11%', '12%', '15%', '15%', '12%', '10%'],
      header: [
        { ttl: '#' },
        { ttl: 'Name', sort: 'type_of_license' },
        { ttl: 'Type of Zone', sort: 'account_type' },
        { ttl: 'Open/Integration fee', sort: 'open_integration_fee' },
        { ttl: 'Incoming fee', sort: 'incoming_fee' },
        { ttl: 'Outgoing fee', sort: 'outgoing_fee' },
        { ttl: 'Onboarding time', sort: 'onboarding_time' },
        { ttl: '' },
      ],
      body: [],
      paginationData: null,
      sort: {
        name: '',
        order: 'asc',
        loading: false
      }
    },
  }),
  created () {
    if (this.provider && !this.initRequest) {
      this.getBankOffers()
      this.getMerchantOffers()
      this.initRequest = true
    }
  }, 
  watch: {
  	provider: function(provider) {
  		if (this.provider && !this.initRequest) {
  			this.getBankOffers()
        this.getMerchantOffers()
        this.initRequest = true
  		}
  	},
    '$store.state.deleteItemFromTableData': function(data) {
        this.updateCurrentPage(data.table, data.actionType)
    },
    '$store.state.editItemFromTableData': function(data) {
        this.updateCurrentPage(data.table, data.actionType)
    }
  },
  methods: {
  	getBankOffers (page = 1) {
      const url = {
        base: process.env.VUE_APP_BACKEND_API_URL + 'admin/offers/bank-accounts',
        page: `?page=${page}`,
        sort: `&sort=${this.bankAcc.sort.name}`,
        order: `&order=${this.bankAcc.sort.order}`,
        other: `&provider_id=${this.provider.id}&per_page=5`,
      }
      axios.get(url.base + url.page + url.sort + url.order + url.other)
        .then(({ data }) => {
          this.setBankAcc(data)
        })
        .catch(err => console.dir(err))
    },
    getMerchantOffers (page = 1) {
      const url = {
        base: process.env.VUE_APP_BACKEND_API_URL + 'admin/offers/merchant-accounts',
        page: `?page=${page}`,
        sort: `&sort=${this.bankAcc.sort.name}`,
        order: `&order=${this.bankAcc.sort.order}`,
        other: `&provider_id=${this.provider.id}&per_page=5`,
      }
      axios.get(url.base + url.page + url.sort + url.order + url.other)
        .then(({ data }) => {
          this.setMerchantAcc(data)
        })
        .catch(err => console.dir(err))
    },
    editBankOffer (obj) {
      this.$store.commit('setModal', {
        template: 'provider-offer',
        options: {
          id: obj.id,
          actionType: 'edit',
          table: 'bank-offer',
          objectType: 'bank account',
          url: process.env.VUE_APP_BACKEND_API_URL + 'admin/offers/update/' + obj.id + '/' + this.provider.id,
          successfullMessage: 'You successfully updated bank account',
        }
      })
    },
    deleteBankOffer (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'bank-offer',
          type: 'offer',
          url: process.env.VUE_APP_BACKEND_API_URL + 'admin/offers/' + obj.id + '/' + this.provider.id,
          successfullMessage: 'You successfully deleted offer',
        }
      })
    },
    editMerchantOffer (obj) {
      this.$store.commit('setModal', {
        template: 'provider-offer',
        options: {
          id: obj.id,
          actionType: 'edit',
          table: 'merchant-offer',
          objectType: 'merchant account',
          url: process.env.VUE_APP_BACKEND_API_URL + 'admin/offers/update/' + obj.id + '/' + this.provider.id,
          successfullMessage: 'You successfully updated merchant account',
        }
      })
    },
    deleteMerchantOffer (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'merchant-offer',
          type: 'merchant',
          url: process.env.VUE_APP_BACKEND_API_URL + 'admin/offers/' + obj.id + '/' + this.provider.id,
          successfullMessage: 'You successfully deleted offer',
        }
      })
    },
    sortBy (data) {
      this[data.obj].sort.loading = true
      this[data.obj].sort.order = this[data.obj].sort.name === data.sort && this[data.obj].sort.order === 'asc'
        ? 'desc' : 'asc'
      this[data.obj].sort.name = data.sort

      switch (data.obj) {
        case 'bankAcc':
          this.getBankOffers()
          break;
        case 'merchantAcc':
          this.getMerchantOffers()
          break;
      }
    },
    setBankAcc (data) {
      this.bankAcc.paginationData = data
      this.bankAcc.body = []
      data.data.forEach(el => {
        const price = el.oif_min_max && el.open_integration_fee_max
          ? this.number_format(el.open_integration_fee, 2, '.', ' ') +' - '+ this.number_format(el.open_integration_fee_max, 2, '.', ' ') +' €'
          : this.number_format(el.open_integration_fee, 2, '.', ' ') +' €'
        const finName = !el.provider_id && !el.is_legal_company
          ? el.financial_institution_name
          : {
              type: 'info',
              html: el.financial_institution_name,
              info: {
                pos: 'tr',
                txt: 'Provided by Legal company'
              },
            }
        this.bankAcc.body.push([
          el.serial_number,
          finName,
          el.account_type,
          price,
          el.incoming_fee,
          el.outgoing_fee,
          el.onboarding_time_text,
          { type: 'edit-delete-actions', id: el.id }
        ])
      })
      if (this.bankAcc.sort.name) this.bankAcc.sort.loading = false
    },
    setMerchantAcc (data) {
      this.merchantAcc.paginationData = data
      this.merchantAcc.body = []
      data.data.forEach(el => {
        const price = el.oif_min_max && el.open_integration_fee_max
          ? this.number_format(el.open_integration_fee, 2, '.', ' ') +' - '+ this.number_format(el.open_integration_fee_max, 2, '.', ' ') +' €'
          : this.number_format(el.open_integration_fee, 2, '.', ' ') +' €'
        const finName = !el.provider_id && !el.is_legal_company
          ? el.financial_institution_name
          : {
              type: 'info',
              html: el.financial_institution_name,
              info: {
                pos: 'tr',
                txt: 'Provided by Legal company'
              },
            }
        this.merchantAcc.body.push([
          el.serial_number,
          finName,
          el.account_type,
          price,
          el.incoming_fee,
          el.outgoing_fee,
          el.onboarding_time_text,
          { type: 'edit-delete-actions', id: el.id }
        ])
      })
      if (this.merchantAcc.sort.name) this.merchantAcc.sort.loading = false
    },
    updateCurrentPage(tableName, actionType) {
      console.log(tableName)
      console.log(actionType)
     if (tableName == 'bank-offer' || tableName == 'merchant-offer') {
       let currentPage = 1
       if (actionType == 'edit' || actionType == 'delete') {
         try {
           currentPage = document.getElementsByClassName(tableName)[0]
           .getElementsByClassName('pagination')[0]
           .getElementsByClassName('active')[0]
           .getElementsByClassName('page-link')[0]
           .innerText.trim().replace( /\D+/g, '')
         } catch (e) {
             console.log(e)
         }
       }
       if (tableName == 'bank-offer') {
        this.getBankOffers(currentPage)
       } else if (tableName == 'merchant-offer') {
        this.getMerchantOffers(currentPage)
       }
     }
    },
  },
  number_format(number, decimals, dec_point, separator) {
      number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof separator === "undefined" ? "," : separator,
        dec = typeof dec_point === "undefined" ? "." : dec_point,
        s = "",
        toFixedFix = function(n, prec) {
          var k = Math.pow(10, prec);
          return "" + (Math.round(n * k) / k).toFixed(prec);
        };
      s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
      }
      if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
      }
      return s.join(dec);
    },
}
</script>

<style lang="scss" scoped>
.page-accounts {
  .box {
    &_selectedAcc {
      .content {
        padding: 0 0 1rem;

        .block {
          margin-bottom: 1.5rem;

          .hdr {
            display: flex;
            align-items: center;
            margin-left: .75rem;
            margin-bottom: .5rem;

            svg.img {
              width: 1.75rem;
              height: 1.75rem;
              margin-right: .5rem;
            }

            .ttl {
              padding: .25rem .5rem;
            }
          }
        }
      }
    }
  }
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
}

.page-link span {
    color: white;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    border: 1px solid #dee2e6;
    color: white !important;
}

.create-account-btn {
  border-radius: 1rem;
  padding: 10px;
  margin-left: 30px;
  background-color: #1763fb;
}

.page-accounts .box_selectedAcc .content .block .hdr .ttl {
    margin: 5px;
}

.page {
    padding: 0;
}
</style>