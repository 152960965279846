<template>
  <overlay-scrollbars class="page page-personal" ref="os">
    <div class="box-container">
      <div class="transparent-box box_b-info">
        <div class="content">
            <div class="row customer-search-name-wrapper">
              <input v-model="customerName" name="company_full_name" type="text" placeholder="Enter cutomer name" class="form-control customer-search-name">
              <!--<div 
                v-on:click.stop.prevent="downloadExcel"
                v-if="$store.state.user.is_super_admin"
                class="btn excel-btn">
                Download to Excel
              </div>--> 
            </div>
          <div class="row person-info">
            <div class="card">
              <User class="img"/>
              <div class="data">
                <div class="ttl">Total customer</div>
                <div class="desc">{{ customersInfo.total }}</div>
              </div>
            </div>
            <div class="card">
              <User class="img"/>
              <div class="data">
                <div class="ttl">Fintech customer</div>
                <div class="desc">{{ customersInfo.fintechUsersCount }}</div>
              </div>
            </div>
            <div class="card">
              <User class="img"/>
              <div class="data">
                <div class="ttl">Standart customer</div>
                <div class="desc">{{ customersInfo.standartUsersCount }}</div>
              </div>
            </div>
            <div class="card">
              <User class="img"/>
              <div class="data">
                <div class="ttl">New customer</div>
                <div class="desc">{{ customersInfo.newUsersCount }}</div>
              </div>
            </div>
            <div class="card">
              <User class="img"/>
              <div class="data">
                <div class="ttl">KYB RATE from 60%</div>
                <div class="desc">{{ customersInfo.kybRateCount }}</div>
              </div>
            </div>
          </div>

          <div class="block">
            <Table
                @editItem="editItem"
                @deleteItem="deleteItem"
                @toggleProperty="toggleProperty"
                :key="customersTableKey"
                :options="customersTableData"
                @change-page="getCustomers"
                @scroll-top="toElTop"
            />
          </div>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Pen from './../components/img/Pen.vue'
import User from './../components/img/User.vue'
import Table from './../components/elem/Table.vue'
import Pagination from 'laravel-vue-pagination'

export default {
  components: {
    Pen,
    User,
    Pagination,
    Table,
  },
  data: () => ({
    customers: [],
    customersTableKey: 0,
    customersInfo: {
      total: '',
      fintechUsersCount: '',
      standartUsersCount: '',
      newUsersCount: '',
      kybRateCount: '',
    },
    customerName: '',
    customerNameDelayTimer: null,
    customersTableDataPagination: {},
    customersTableData: {
      colsWidth: ['8.3%', '8.3%', '8.3%', '8.3%', '8.3%', '8.3%', '8.3%', '8.3%', '8.3%', '8.3%', '8.3%', '8.3%'],
      header: [
        { ttl: 'Cust. ID', info: null },
        { ttl: 'Date Join', info: null },
        { ttl: 'Customer Name', info: null },
        { ttl: 'Priority', info: null },
        { ttl: 'Rating', info: null },
        { ttl: 'KYB Rate', info: null },
        { ttl: 'Type cust.', info: null },
        { ttl: 'Location', info: null },
        { ttl: 'Manager', info: null },
        { ttl: 'Balance', info: null },
        { ttl: 'Referrals', info: null },
        { ttl: '', info: null },
      ],
      body: [],
      paginationData: null,
    },
  }),
  created () {
    this.getCustomers()
    this.getCustomersInfo()
    const that = this
  },
  methods: {
    toElTop (el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
    getCustomers (page = 1) {
      const that = this
      let url = 'admin/customers?page=' + page
      if (this.customerName.trim().length > 0) {
        url = url + '&customer_name=' + this.customerName
      }
      axios.get(url)
        .then(function (response) {
          that.customersTableDataPagination = response.data
          that.customersTableData.paginationData = response.data
          that.customersTableData.body = []
          response.data.data.forEach(el => {
            const isNewHtml = (el.payment_settings && el.payment_settings.payment_request_settings_changed) ? '<span class="badge  new-request-badge">New</span>' : ''
            const requestHtml = (el.request_changed_date) ? el.request_changed_date + isNewHtml : ''
            let balanceItem = (el.balance.trim().length !== 0 && el.balance) ? that.number_format(el.balance, 2, '.', ' ') : ''
            if (el.balance_currency) {
              balanceItem = balanceItem + ' ' + el.balance_currency.code;
            }
            let name = el.full_name
            const kybRate = (parseInt(el.is_kyb_rate_manual) == 1) ? el.kyb_rate_manual : el.kyb_rate 
            if (!name && typeof el.company_full_name !== 'undefined') {
              name = el.company_full_name
            }
            that.customersTableData.body.push([
              el.id + ' | ' + el.serial_number,
              el.created_at_text,
              el.name ? el.name : el.full_name,
              { type: 'priority', value: el.priority},
              { type: 'rating', rating: el.rating, rating_color: el.rating_color },
              kybRate,
              el.customer_type_name,
              el.location,
              el.manager_name,
              balanceItem,
              el.refferal ? { type: 'edit-link', text: el.refferal.email + ' ' + el.refferal.name, id: el.refferal.id } : '',
              { type: 'edit-delete-toggle-can-actions', id: el.id, is_manager_can: el.is_manager_can }
            ])
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getCustomersInfo () {
      const that = this
      axios.get('admin/customers/info')
        .then(function (response) {
          that.customersInfo = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    deleteItem (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          table: 'customers',
          type: 'customer',
          url: 'admin/customers/' + obj.id,
          successfullMessage: 'You successfully deleted customer',
        }
      })
    },
    editItem (obj) {
      this.$router.push({ name: "CustomerDetail", params: { id: obj.id } })
    },
    downloadExcel () {
      return null;
      if(this.$store.state.user.is_super_admin){
        axios.post('admin/customers/create-excel-report')
        .then(function (response) {
          const url = response.data
          const link = document.createElement('a')
          link.href = url
          document.body.appendChild(link)
          link.click()
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    toggleProperty(obj){
      if(obj.id){
        let urlPart = '';
        
        if(obj.property == 'is_manager_can'){
          urlPart = 'toggle-can';
        }
        
        let that = this
        
        axios.post('admin/customers/' + obj.id + '/' + urlPart)
        .then(function (response) {
          const currentPage = document.getElementsByClassName('pagination')[0]
            .getElementsByClassName('active')[0]
            .getElementsByClassName('page-link')[0]
            .innerText.trim().replace( /\D+/g, '')
            
          that.getCustomers(currentPage)
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
  },
  watch: {
    customerName: function(customerName) {
      clearTimeout(this.customerNameDelayTimer);
      const that = this
      this.customerNameDelayTimer = setTimeout(function() {
          that.getCustomers()
      }, 300);
    },
    '$store.state.deleteItemFromTableData': function(data) {
      if (data.id && data.table.length > 0) {
        const el = document.getElementById(data.table + '-pagination')
        if (data.table == 'customers') {
          const currentPage = document.getElementsByClassName('pagination')[0]
            .getElementsByClassName('active')[0]
            .getElementsByClassName('page-link')[0]
            .innerText.trim().replace( /\D+/g, '')
          this.getCustomers(currentPage)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-personal {
  .box {
    &-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &_rate {
      position: relative;
      height: 7rem;
      height: 11vh;
      margin-top: 6rem;
      margin-top: 7vh;
      margin-bottom: 4rem;
      margin-bottom: 6vh;

      .box-inside {
        width: 95%;
        height: 11rem;
        height: calc(100% + 6vh);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(113.24deg, #1763fb 16.01%, #1763fb 106.71%);
        box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
        border-radius: 14px;
        display: flex;

        .content {
          flex-grow: 1;

          .scale-box {
            width: 100%;
          }
        }

        .img {
          height: 14rem;
          height: calc(100% + 6vh);
          position: relative;
          top: 100%;
          transform: translateY(-96%);
          margin: auto 3rem;
          margin: auto 3vw;
        }
      }
    }

    &_my-profile {
      position: relative;
      width: 100%;
      padding-right: 4.25rem;

      .header {
        display: flex;

        .title {
          flex-grow: 1;
        }

        .tmblr {
          display: flex;
          align-items: center;
          padding: 0 .25rem;

          .txt {
            color: rgba(255, 255, 255, 0.5);
          }

          .btn {
            &-tmblr {
              position: relative;
              width: 1.5rem;
              height: .5rem;
              background-color: rgba(255, 255, 255, 0.12);
              border-radius: 2rem;
              margin: auto 1rem;

              &-l::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(-50%, -50%);
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background-color: #ffffff;
              }

              &-r::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(50%, -50%);
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background-color: #ffffff;
              }
            }
          }

          &-hide {
            .txt {
              color: #1763fb;
            }

            .btn-tmblr-r::before {
              background-color:  #1763fb;
            }
          }
        }
      }

      .content {
        padding: 0 0 1rem 0;
        padding: 0 0 1.5vh 0;
        display: flex;

        &.blur {
          filter: blur(7px);
        }

        .card {
          &-big {
            width: 100%;
            height: 11.5rem;
            display: flex;
            align-items: flex-end;
            background-image: url('/img/two_line.png');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: contain;
            padding: 1.25rem 1.75rem;

            svg.img {
              width: 3.5rem;
              height: 4rem;
              margin-right: 1rem;
            }

            .data {
              flex-grow: 1;

              .ttl {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.5);
                padding-bottom: .25rem;
              }

              .desc {
                font-size: 1.25rem;
                font-weight: 600;
              }
            }
          }
        }

        .col {
          &-1 {
            width: 27rem;
            width: 21vw;
            padding-right: .5rem;
          }

          &-2 {
            flex-grow: 1;
            padding-left: .5rem;
            padding-right: .5rem;
            display: flex;

            .subcol {
              padding: 0 .5rem;

              &-1 {
                width: 30%;
              }

              &-2,
              &-3 {
                width: 35%;
              }
            }
          }
        }
      }
    }

    &_b-info {
      flex-grow: 1;
      position: relative;
      // background-color: #272E35;
      margin-right: 2rem;

      .person-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .card {
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      .header {
        padding-right: 4.25rem;

        .title {
          &::before {
            background-color: rgb(255 255 255 / 25%);
          }
        }
      }

      .content {
        .row {
          display: flex;
          justify-content: space-between;

          .card {
            background: rgba(23, 99, 251, 0.1);
            width: 30%;

            &-min {
              width: 20%;
            }
          }
        }
      }
    }

    &_comp-logo {
      width: 23rem;
      position: relative;
      background-color: #272E35;
      overflow: hidden;
      padding-bottom: 1rem;

      .dots {
        position: absolute;

        &-top {
          top: -5px;
          right: 7px;
          width: 2rem;
          opacity: .5;
        }

        &-left {
          top: 6.5rem;
          left: 28px;
          width: 1.5rem;
          opacity: .5;
        }

        &-bottom {
          bottom: -12px;
          right: 1.75rem;
          width: 2.5rem;
          transform: rotate(90deg);
          opacity: 0.5;
        }
      }

      .header {
        .title {
          &::before {
            background-color: rgb(255 255 255 / 25%);
          }
        }
      }

      .logo {
        flex-grow: 1;
        width: 14rem;
        height: 14rem;
        margin: .25rem auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
        position: relative;
        z-index: 1;

        .btn {
          width: 3.25rem;
          height: 3.25rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          &_update {
            background-color: #1763fb;

            svg {
              width: 1.75rem;
              height: 1.75rem;
            }
          }

          &_remove {
            background-color: #B03636;

            svg {
              width: 1.25rem;
              height: 1.25rem;
            }
          }
        }
      }
    }
  }
}

.scale {
  position: relative;
  width: 100%;
  height: 1rem;
  margin: 1rem 0 3.5rem 0;
  border-radius: 3rem;
  background-color: rgba(255, 255, 255, 0.12);

  .progress {
    position: relative;
    height: 100%;
    border-radius: 3rem;
    background-color: #ffffff;
    background-image: url('/img/rate_el.png');
    background-position: 0 0;
    background-repeat: repeat-x;
    background-size: auto 100%;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 5rem;
      background-color: #E2F4EC;
      background-image: url('/img/smile.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 65%;
    }

    .note {
      position: absolute;
      bottom: -3.25rem;
      right: 0;
      transform: translateX(50%);
      background-color: #094F4D;
      color: #ffffff;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 7px 1rem 6px;
      border-radius: 6px;

      &::before {
        content: '';
        position: absolute;
        top: -4px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: .75rem;
        height: .75rem;
        background-color: #094F4D;
        border-radius: 2px;
      }
    }
  }
}

.btn {
  &-edit {
    width: 2.75rem;
    height: 2.75rem;
    position: absolute;
    top: .75rem;
    right: .75rem;
    background: #22252B;
    border-radius: 6px;
    padding: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg.img {
      width: 1rem;
      height: 1rem;
    }
  }
  &-save {
    width: 3.75rem;
    height: 2.75rem;
    position: absolute;
    top: .75rem;
    right: .75rem;
    background: #22252B;
    border-radius: 6px;
    padding: .75rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg.img {
      width: 1rem;
      height: 1rem;
    }
  }
}

.form-control {
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: #1C2126;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin: auto 0rem;
  border: 1px solid #2F363D;
  border-radius: .75rem;
  box-shadow: 0px 11px 23px rgba(0, 0, 0, 0.02);
  outline: none;
  -webkit-appearance: none;

  &::placeholder {
    color: #B9B9B9;
  }
}

.invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.transparent-box {
  width: 100%;
  border-radius: 14px;
  margin: 1rem 0;
}

.excel-btn-wrapper {
  position: relative;
  height: 67px;
}

.excel-btn {
  border-radius: 0.5rem; 
  padding: 10px; 
  margin-left: 30px; 
  border: 1px solid #1763fb;
  display: inline-block;
  position:absolute;
  right: 15px;
  top: 0;
}

.customer-search-name-wrapper {
  position: relative;
  height: 67px;
}

.customer-search-name {
  position:absolute;
  right: 198px;
  top: 0;
  width: 400px;
  height: 50px;
}

</style>
