<template>
  <div id="main">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
// import Modal from '@/components/modals/Modal.vue';
import Echo from 'laravel-echo'
import { mapMutations, mapActions, mapGetters} from 'vuex'

export default {
  methods: {
    ...mapMutations({
      setMessagesCount: 'SET_MESSAGES_COUNT',
      createNewChatMessage: 'CREATE_NEW_CHAT_MESSAGE',
    }),
    ...mapActions({
      setCountMessagesRead: 'setCountMessagesRead',
    }),
    setVH() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
  created() {
    document.title = "n5Bank | Cabinet"
  },
  mounted() {
    this.setVH();

    window.addEventListener('resize', () => {
      this.setVH();
    });

  },
  computed: {
    ...mapGetters({
      viewUpdateProfileFields: 'notifications/viewUpdateProfileFields',
      viewUpdatedBusinessInformationFields: 'notifications/viewUpdatedBusinessInformationFields',
      viewUpdatedLogo: 'notifications/viewUpdatedLogo',
      viewUpdatedRequest: 'notifications/updateRequest',
      applyOffer: 'notifications/applyOffer',
      cancelOffer: 'notifications/cancelOffer',
      updateCompliance: 'notifications/updateCompliance',
      gotoAllNotifications: 'notifications/gotoAllNotifications'
    }),
    layout() {
      return (this.$route.meta.requiresAuth) ? 'default-layout' : 'auth-layout'
    }
  },
  watch: {
    viewUpdateProfileFields (notification) {
      if (notification) {
        if (this.$route.name !== 'CustomerDetail') {
          this.$router.push({ name: "CustomerDetail", params: { id: notification.author.id, viewUpdateProfileFields: notification.notification.data.updated_fields } })
        }
      }
    },
    viewUpdatedBusinessInformationFields (notification) {
      if (notification) {
        if (this.$route.name !== 'CustomerDetail') {
          this.$router.push({ name: "CustomerDetail", params: { id: notification.author.id, viewUpdatedBusinessInformationFields: notification.notification.data.updated_fields } })
        }
      }
    },
    viewUpdatedLogo (notification) {
      if (notification) {
        if (this.$route.name !== 'CustomerDetail') {
          this.$router.push({ name: "CustomerDetail", params: { id: notification.author.id, viewUpdatedLogo: true } })
        }
      }
    },
    viewUpdatedRequest (notification) {
      if (notification) {
        if (this.$route.name !== 'CustomerDetail') {
          this.$router.push({ name: "CustomerDetail", params: { id: notification.author.id, viewUpdatedRequest: true } })
        }
      }
    },
    applyOffer (notification) {
      if (this.$route.name !== 'CustomerDetail') {
        this.$router.push({ name: "CustomerDetail", params: { id: notification.author.id, viewAppliedOffers: true }})
      }
    },
    cancelOffer (notification) {
      if (this.$route.name !== 'CustomerDetail') {
        this.$router.push({ name: "CustomerDetails", params: { id: notification.author.id, viewAppliedOffers: true }})
      }
    },
    updateCompliance (notification) {
      if (this.$route.name !== 'CustomerDetail') {
        this.$router.push({ name: "CustomerDetail", params: { id: notification.author.id, viewCompliance: true }})
      }
    },
    gotoAllNotifications (val) {
      if (val) {
        this.$router.push({ name: "Notifications" })
      }
    },
   '$store.state.user': function(user) {
      if (user && user !== 'undefined') {
         this.$store.dispatch('notifications/updateTotalUnreadCountNotifications', parseInt(user.unreadNotificationsCount))

         const echo = new Echo({
            broadcaster: 'socket.io',
            key: '427fd927ec4484288e8e90557a11f8ca',
            host: process.env.VUE_APP_LARAVEL_ECHO_HOST,
            logToConsole: true,
            auth: {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              }
            }
          })

        const that = this
        echo.private(`users.${this.$store.state.user.id}`)
          .listen('NotificationCount', (e) => {
            that.$store.dispatch('notifications/updateTotalUnreadCountNotifications', e.count_unread)
          })
          .listen('CreateChatMessage', (e) => {
            console.log(e)
            this.createNewChatMessage(e)
          })
          .listen('ChatMessageCountEvent', (e) => {
            this.setMessagesCount(e.count_unread)
          });
      }
    },
  }
}
</script>

<style lang="scss">
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  height: 1px;
  min-height: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh) * 100);
}

#main {
  height: 100%;

  > div {
    height: 100%;
    max-height: 100%;
    max-height: 100vh;
    // display: flex;
    background-color: var(--c_more-black);
    overflow: auto;

    > .container {
      // flex-grow: 1;
      margin-left: 20rem;
      margin-left: var(--sb_width);
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.page {
  flex-grow: 1;
  padding: 1.5rem 3rem;

  @media (max-width: 1280px) {
    padding: 1rem 1.5rem;
  }

  .box {
    width: 100%;
    background: #2F363D;
    border-radius: 14px;
    margin: 1rem 0;

    .header,
    .content {
      padding: 1rem 0;
      padding: 1.5vh 0;

      > * {
        padding: {
          left: 2.5rem;
          right: 2.5rem;
        };
        padding: {
          left: 2vw;
          right: 2vw;
        };
      }

      .title {
        position: relative;
        padding-bottom: .25rem;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        color: rgba(255, 255, 255, 0.91);

        &::before {
          content: '';
          position: absolute;
          top: 12px;
          left: .5px;
          width: 2rem;
          width: 1.5vw;
          height: 1.5px;
          background-color:rgba(255, 255, 255, 0.6);
        }
      }

      .subtitle {
        padding-bottom: .25rem;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
      }
    }
  }

  .content {
    .card {
      min-height: 82px;
      background: #22252B;
      box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
      border-radius: 14px;
      display: flex;
      align-items: center;
      padding: 1rem 1.25rem;
      margin-bottom: 1.25rem;

      svg.img {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
      }

      .data {
        min-height: 40px;
        flex-grow: 1;
        font-size: 12px;

        .ttl {
          color: rgba(255, 255, 255, 0.5);
          padding-bottom: .25rem;
        }

        .desc {
          letter-spacing: 0.5px;
          font-weight: 600;
          word-break: break-word;
        }
      }
    }
  }
}

a.btn, .btn {
  text-decoration: unset;
  color: inherit;
  cursor: pointer;
}

input.inpt, .inpt {
  height: 2.75rem;
  color: #ffffff;
  padding: .25rem .75rem;
  background-color: inherit;
  border: 1px solid #1763fb;
  border-radius: 6px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
}

.tbl {
  background: #22252B;
  box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
  border-radius: 14px;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: rgba(23, 99, 251, 0.1);
    border-radius: 14px;
    // border-top-left-radius: 14px;
    // border-top-right-radius: 14px;

    .col {
      text-align: center;
    }
  }

  .col {
    span {
      position: relative;
      display: inline-block;
    }
  }

  &_row {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.blur_5 {
  filter: blur(5px);
}

.hide {
  display: none !important;
}

select option{
  background: #272e35;
  color: #fff;
}
</style>
