<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">Document Templates</div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="file-data">
          <div v-for="(document, documentIndex) in document_templates">
            <div>
              <div class="row">
                <div class="data">
                  <div class="row">
                    <div class="label">
                      Document Template № {{ documentIndex + 1 }}
                    </div>
                    <div class="data">
                      <div class="delete-btn" @click="deleteDocumentTemplate(documentIndex)">
                        <Close class="img"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row gap-12">
                <input type="text" name="name" class="inpt" v-model="document.name">
              </div>
              <div class="row row_files">
                <div class="box box_addFiles">
                  <div v-if="getUploadedFiles('documents', document, documentIndex).length" class="content content_many">
                    <draggable v-model="document.upload.documents" tag="div" class="draggable">
                    <div v-for="(file, key) in getUploadedFiles('documents', document, documentIndex)" :key="key" class="file">
                      <BlueFile class="img" />
                      <div class="btn btn_del" @click="delFile('documents', key, file, document, documentIndex)">
                        <Close class="img" />
                      </div>
                      <div class="btn btn_download" 
                        v-if="file.id" 
                        @click.stop.prevent="downloadFile(file, 'documents', document, documentIndex)"
                      >
                        <BlueDownload class="img" />
                      </div>
                      <div class="desc">{{ file.name }}</div>
                    </div>
                    </draggable>
                    <div class="add">
                      <div class="btn" @click="$refs[documentIndex + '_documents'][document.upload.documents.length - 1].click()">
                        <Close class="img" />
                      </div>
                    </div>
                  </div>
                  <div v-else class="content">
                    <div class="choose">
                      <div class="btn" @click="$refs[documentIndex + '_documents'][0].click()">Document</div>
                    </div>
                  </div>
                  <input
                    v-for="(el, key) in document.upload.documents"
                    :key="key"
                    type="file"
                    :name="`documents_${documentIndex}_${key}`"
                    class="hide"
                    :ref="documentIndex + '_documents'"
                    @change="addInput($event, documentIndex + '_documents', key, 'documents', document, documentIndex)"
                  >
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="data">
                <span class="btn" @click="addDocumentTemplate">
                  Add document template
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="saveAll">Save</div>
    </div>
  </div>
</template>

<script>
import Close from '../img/Close.vue'
import Tmblr from '../elem/Tmblr.vue'
import BlueFile from '../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from '../img/BlueDownload.vue'

export default {
  components: {
    Close,
    Tmblr,
    BlueDownload,
    BlueFile,
    draggable,
  },
  data(){
    return{
      document_templates: [],
      document_templates_to_delete: [],
      error: null,
    }
  },
  async mounted () {
    await this.getDocumentTemplates()
    await this.getAllDocuments();
  },
  methods: {
    addDocumentTemplate(){
      this.document_templates.push({
        name: null,
        upload: {
          documents: [{name: null}]
        }
      })
    },
    async deleteDocumentTemplate(index){
      console.log(this.document_templates[index].hasOwnProperty('id'));
      if(this.document_templates[index].hasOwnProperty('id')){
        await axios.delete('admin/account-request-document-templates/' + this.document_templates[index].id)
        .then(function (response) {
          if(response.data.success){
            that.$noty.success('Document template was successfully deleted.')
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }

      this.document_templates.splice(index, 1)
    },
    async getAllDocuments(){
      this.document_templates.forEach((item, i) => {
        if(item.hasOwnProperty('id')){
          this.getDocuments(item.id, i)
        }
      });
    },
    async getDocuments(id, index) {
      const that = this
      
      if(id){
        await axios.get('admin/account-request-document-templates/' + id + '/get-file/documents')
        .then((response) => {
          let docsData = response.data

          if(docsData.length > 0){
            if (!that.document_templates[index].upload) {
                that.$set(that.document_templates[index], 'upload', { documents: [{ name: null }] });
            }

            that.document_templates[index].upload.documents = docsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
      }
    },
    getUploadedFiles(el, document, documentIndex) {
      return this.document_templates[documentIndex].upload[el].filter(e => e.name !== null)
    },
    delFile(name, key, file, document, documentIndex) {
      if (this.document_templates[documentIndex].upload[name].length > 1) {
        this.document_templates[documentIndex].upload[name].splice(key, 1)
      } else {
        this.document_templates[documentIndex].upload[name][0].name = null
      }

      if(file.id && document.hasOwnProperty('id')){
        axios.post('admin/account-request-document-templates/' + document.id +'/delete-file', {
          collection: name,
          media_id: file.id,
        })
        .then(function () {
          
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    addInput(event, name, key, collection, document, documentIndex) {
      if (this.$refs[name][key].files.length) {
        const file_name = this.$refs[name][key].files[0].name

        if (this.getUploadedFiles(collection, document, documentIndex).some(e => e.name == file_name)) {
          this.$noty.error('Error! A file with this name has already been added.')

          return false
        }

        const extension = this.$refs[name][key].files[0].name.split('.').pop()
        const availableExtensionsArr = ['jpg', 'jpeg', 'png',  'txt', 'pdf', 'doc', 'docx', 'xlsx']
        if (!availableExtensionsArr.includes(extension.toLowerCase())) {
          this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
          
          return false
        }

        if (this.$refs[name][key].files[0].size / 1024 / 1024 > 5) {
          this.$noty.error('File is too large. Maximum - 5MB.')
          
          return false
        }

        this.document_templates[documentIndex].upload[collection] = this.document_templates[documentIndex].upload[collection].filter(e => e.name !== null)
        
        if (this.$refs[name].length < 10) {
          this.document_templates[documentIndex].upload[collection].push({ name: file_name, file: event.target.files[0]})
        } else {
          this.$noty.error('Maximum - 10 files')
        }
      }
    },
    downloadFile(file, type, document, documentIndex) {
      const id = document.id

      if(file.url){
        this.openFile(file.url)
      }
      else if(id){
        axios.post('admin/account-request-document-templates/' + id +'/get-file-link', {
          collection: type,
          media_id: file.id,
        })
        .then(function (response) {
          if (response.data) {
            const url = response.data

            var fileURL = response.data;

            this.openFile(fileUrl);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    openFile(url){
      var fileLink = document.createElement('a');

      fileLink.href = url;
      fileLink.setAttribute('target', '_blank')
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async getDocumentTemplates(){
      const that = this

      await axios.get('admin/account-request-document-templates')
      .then(function (response) {
        that.document_templates = response.data

        for(let documentIndex = 0; documentIndex < that.document_templates.length; documentIndex++) {
          if(!that.document_templates[documentIndex].upload) {
            that.$set(that.document_templates[documentIndex], 'upload', { documents: [{ name: null }] });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      })
	  },
    async saveAll(){
      for(let documentIndex = 0; documentIndex < this.document_templates.length; documentIndex++) {
        await this.save(this.document_templates[documentIndex], documentIndex);
      }

      await this.getDocumentTemplates()
      await this.getAllDocuments()
    },
    async save(document, documentIndex){
      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/account-request-document-templates'

      if (document.hasOwnProperty('id')) {
        url += '/' + document.id
      }

      await axios.post(url, document)
      .then((response) => {
        this.processFiles(response.data.accountRequestDocumentTemplate.id, document, documentIndex)
      })
      .catch((errors) => {
        console.dir(errors)
      })
    },
    processFiles(id, document, documentIndex){
      let data = new FormData()
      const uploadKeys = Object.keys(this.document_templates[documentIndex].upload)

      for (let z = 0; z < uploadKeys.length; z++) {
        const key = uploadKeys[z]
        for (let i = 0; i < this.document_templates[documentIndex].upload[key].length; i++) {
          if (this.document_templates[documentIndex].upload[key][i].name !== null) {
            let f = {}
            if (this.document_templates[documentIndex].upload[key][i].type == 'server') {
              f = JSON.stringify(this.document_templates[documentIndex].upload[key][i])
            } else {
              data.append(key + '_names[' + i + ']', this.document_templates[documentIndex].upload[key][i].name)
              f = this.document_templates[documentIndex].upload[key][i].file
            }
            data.append(key + '[' + i + ']', f)
          }
        }
      }
      
      axios.post('admin/account-request-document-templates/' + id + '/files', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((response) => {
        this.close()
      })
      .catch((errors) => {
        console.dir(errors)
      })
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
      }
      .file-data{
        flex-grow: 1;
        .inpt {
          width: 100%;
          color: #000000;
        }
      }
      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
        .btn {
          padding: 0.5rem;
          background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
          border-radius: 6px;
          color: #fff;
          font-size: 13px;
        }
        .delete-btn{
          margin-left: auto;
          width: 24px;
          height: 24px;
          background-color: #B03636;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg{
            fill: #fff;
            max-width: 16px;
            max-height: 16px;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #333333;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
  textarea{
    height: 5rem;
  }
}
.gap-12{
  gap: 12px;
}
</style>
