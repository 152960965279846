<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Strategy Matches
      </div>
    </div>
    <div class="modal__body">
      <div class="box box_selectedAcc">
        <div class="header">
        </div>
        <div class="content">
          <div class="block">
            <div class="hdr">
            </div>
            <Table 
              :key="strategyMatchesKey" 
              :options="strategyMatches" 
              @sort="sortBy"
              @change-page="getStrategyMatches"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from './../elem/Table.vue'
import Close from '../img/Close.vue'

export default {
  components: {
    Table,
    Close
  },
  props: {
    options: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      strategyMatchesData: [],
      strategyMatchesKey: 0,
      strategyMatches: {
        objName: 'strategies',
        colsWidth: ['16.6%', '16.6%', '16.6%', '16.6%', '16.6%', '16.6%'],
        header: [
          { ttl: 'Country', info: null },
          { ttl: 'Type of license', info: null },
          { ttl: 'License number', info: null },
          { ttl: 'Business type', info: null },
          { ttl: 'Price', info: null, sort: 'price' },
          { ttl: 'Description', info: null },
        ],
        body: [],
        paginationData: null,
        sort: {
          name: '',
          order: 'asc',
          loading: false
        }
      },
      managers: [],
    };
  },
  async created(){
    await this.getManagers()
    await this.getStrategyMatches()
  },
  methods: {
    async getManagers () {
      const that = this
      await axios.get('admin/data/managers').then(function (response) {
        that.managers = response.data
      }).catch(function (error) {
        console.log(error);
      })
    },
    sortBy (data) {
      console.log(data);
      this.strategyMatches.sort.loading = true
      this.strategyMatches.sort.order = this.strategyMatches.sort.name === data.sort && this.strategyMatches.sort.order === 'asc'
        ? 'desc' : 'asc'
      this.strategyMatches.sort.name = data.sort
      this.getStrategyMatches(1)
    },
    async getStrategyMatches (page = 1) {
      const that = this

      let url = 'admin/strategies'

      if(this.options.id){
        url += '/get-matches/' + this.options.id
      }
      else{
        url += '/get-all-matches'
      }

      const sortParams = '&sort=' + this.strategyMatches.sort.name + '&order=' + this.strategyMatches.sort.order

      axios.get(url + '?page=' + page + sortParams).then(function (response) {
        that.strategyMatches.paginationData = response.data
        that.strategyMatches.body = []

        response.data.data.forEach(strategy => {
          strategy.matches.forEach(match => {
            that.strategyMatches.body.push([
              match.country ? match.country.name : '',
              match.license_type,
              match.id,
              match.business_type,
              (match.price.trim().length !== 0 && match.price) ? that.number_format(match.price, 2, '.', ' ') : '',
              match.description
            ])
          })

          if (that.strategyMatches.sort.name) that.strategyMatches.sort.loading = false
        })
      }).catch((errors) => {
        console.dir(errors)
      })
    },
    selectManager(el){
      const that = this
      
      axios.post('admin/strategies/' + el.el.strategy_business_offer.strategy_id + '/process-match', {
        business_offer_id: el.el.strategy_business_offer.business_offer_id,
        manager_id: el.el.strategy_business_offer.manager_id
      }).then(function(){

      }).catch((errors) => {
        console.dir(errors)
      })
    },
    getStrategyBusinessOffer(strategyId, businesOffer){
      for(let i = 0; i < businesOffer.strategies.length; i++){
        const strategyBusinessOffer = businesOffer.strategies[i];
        
        if(strategyBusinessOffer.pivot.strategy_id == strategyId){
          return strategyBusinessOffer.pivot
        }
      }

      return {strategy_id: strategyId, business_offer_id: businesOffer.id, manager_id: null}
    },
    getClientBudget(value){
      for(let i = 0; i < this.clientBudgets.length; i++){
        const el = this.clientBudgets[i];
        
        if(+el.value == +value){
          return el.name
        }
      }
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    // height: 25rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    max-height: 80vh;
    display: block;
    padding-bottom: 2rem;
    overflow: auto;
  }
}

.box {
  &_selectedAcc {
    width: 100%;
  }
}

.tbl {
  color: #ffffff;
}

.block_mt {
  margin-top: 20px;
}
.inner-modal__wrapper{
  position: fixed;
  top: -100%;
  left: -100%;
  right: -100%;
  bottom: -100%;
  background: rgba(0, 0, 0, 0.5);
  .modal__body{
    display: block;
  }

  .inner-modal{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #272E35;
    max-width: 100%;
    width: 520px;
    .ttl, .label{
      color: #fff
    }
    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;
      flex-wrap: wrap;

      .label {
        width: 100%;
        padding-right: .75rem;
        font-weight: bold;
      }

      .data {
        flex-grow: 1;
        width: 100%;

        .inpt {
          width: 100%;
          color: #fff;

          &__ta {
            height: 7rem;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          width: 30%;
          //width: calc(50% - .75rem);
          //margin: .75rem 0;
          //padding: 1rem 1.75rem;
          //background: #22252B;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #fff;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
}
.modal__wrapper{
  max-width: 95%;
}
</style>
