<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Balance Settings
      </div>
    </div>
    <div class="modal__body">
      <div class="form">
        <div class="form-group">
          <div class="row">
            <div class="col-label">
              Requests total|paid:
            </div>
            <div class="col">
              {{ customer.license_offers_balance }}
            </div>
            <div class="col">
              {{ customer.paid_license_offers_balance }}
            </div>
          </div>
          <div class="row">
            <div class="col-label">
              Ready licenses total|paid:
            </div>
            <div class="col">
              {{ customer.business_offers_balance }}
            </div>
            <div class="col">
              {{ customer.paid_business_offers_balance }}
            </div>
          </div>
          <div class="row">
            <div class="col-label">
              Legal services total|paid:
            </div>
            <div class="col">
              {{ customer.legal_services_balance }}
            </div>
            <div class="col">
              {{ customer.paid_legal_services_balance }}
            </div>
          </div>
          <div class="row">
            <div class="col-label">
              Total total|paid:
            </div>
            <div class="col">
              {{ customer.balance }}
            </div>
            <div class="col">
              {{ customer.paid_balance }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Payment deadline days</label>
          <input v-model="form.inputs.payment_deadline_days" type="number" class="form-control">
          <span v-show="form.errors.payment_deadline_days" class="invalid-feedback">{{ form.errors.payment_deadline_days }}</span>
        </div>
        <div class="form-group">
          <label>Payment deadline hours</label>
          <input v-model="form.inputs.payment_deadline_hours" type="number" class="form-control">
          <span v-show="form.errors.payment_deadline_hours" class="invalid-feedback">{{ form.errors.payment_deadline_hours }}</span>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Save data</div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      password: '',
      repeatPassword: '',
      passwordError: '',
      repeatPasswordError: '',
      form: {
        inputs: {
          payment_deadline_days: '',
          payment_deadline_hours: '',
        },
        errors: {
          payment_deadline_days: '',
          payment_deadline_hours: '',
        }
      },
      customer: this.options.customer
    };
  },
  created () {
    this.getBalanceSettings()
  },
  methods: {
    getBalanceSettings () {
      const that = this
      axios.get('admin/balance-settings/' + this.options.customerId)
        .then(function (response) {
          if (response.data.success) {
            that.form.inputs.payment_deadline_days = response.data.balance_settings.payment_deadline_days
            that.form.inputs.payment_deadline_hours = response.data.balance_settings.payment_deadline_hours
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    clearErrors() {
      this.form.errors.payment_deadline_days = ''
      this.form.errors.payment_deadline_hours = ''
    },
    async submit() {
      const that = this
      this.clearErrors()
      if (this.form.inputs.payment_deadline_days.length === 0) {
        this.form.errors.payment_deadline_days = 'Please enter deadline days'
        return
      } if (this.form.inputs.payment_deadline_hours.length === 0) {
        this.form.errors.payment_deadline_hours = 'Please enter deadline hours'
        return
      }
      axios.post('admin/balance-settings/' + this.options.customerId, {
        payment_deadline_days: this.form.inputs.payment_deadline_days,
        payment_deadline_hours: this.form.inputs.payment_deadline_hours,
      })
      .then(function (response) { 
        that.clearErrors()
        that.close()
        if (response.data.success) { 
          that.$noty.success('You successfully applied changes')
        } else {
          that.$noty.error('Error!')
        }
      })
      .catch((errors) => {
          console.dir(errors)
        })
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }
  .row{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    margin-bottom: 12px;
    .col-label{
      color: #ababab;
      flex: 0 0 calc(30% - 6px);
      max-width: calc(30% - 6px);
    }
    .col{
      flex: 0 0 calc(34% - 6px);
      max-width: calc(34% - 6px);
    }
  }
}
</style>
