<template>
	<div>
		<div class="box referral-link-box">
	        Referral Link: <a class="referral-link" :href="landingUrl + '?referral_code=' + provider.referral_code">{{ landingUrl + '?referral_code=' + provider.referral_code }}</a>
	        <div @click="copyCode" class="btn">Copy</div>
	        <input type="hidden" id="referral-link" :value="landingUrl + '?referral_code=' + provider.referral_code">
	      </div>
	    <div class="box referral-link-box">
	  		<div class="block">
	  			<div class="hdr">
	  				<Employment class="img" />
	  				<div class="ttl">Referrals</div>
	  			</div>
	  			<div class="referrals-table">
		  			<Table
			            class="bank-offer"
			            :options="referralsTableData"
			            @change-page="getReferrals"
			            :key="referralsTableKey"
			          />
			    </div>
	  		</div> 
	  	</div>
	</div>
</template>

<script>
import Employment from '@/components/img/Employment.vue'
import Table from '@/components/elem/Table.vue'

export default {
	components: {
		Employment,
		Table,
	},
	props: {
	    provider: {
	      type: Object,
	      required: true
	    }
	},
	data () {
		return {
			landingUrl: process.env.VUE_APP_LANDING_URL,
			initRequest: false,
			referralsTableKey: 0,
			landingUrl: process.env.VUE_APP_LANDING_URL,
			referralsTableData: {
			  objName: 'referralsTableData',
		      colsWidth: ['40%', '40%', '20%'],
		      header: [
		        { ttl: 'Email'},
		        { ttl: 'Fullname' },
		        { ttl: 'Level' },
		      ],
		      body: [],
		      paginationData: null,
		    },
		}
	},
	methods: {
		copyCode() {
          let codeToCopy = document.querySelector('#referral-link')
          codeToCopy.setAttribute('type', 'text')
          codeToCopy.select()
          try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            this.$noty.success('Link was copied ' + msg);
          } catch (err) {
            this.$noty.error('Oops, unable to copy');
          }
          codeToCopy.setAttribute('type', 'hidden')
          window.getSelection().removeAllRanges()
      },
      getReferrals (page = 1) {
      	 const that = this
		 axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/provider-referrals/' + this.provider.id + '?page=' + page + '&per_page=5')
	        .then(({ data }) => {
	          that.referralsTableData.paginationData = data
	          that.referralsTableData.body = []
	          data.data.forEach(el => {
	            that.referralsTableData.body.push([
	              el.email,
	              el.full_name,
	              (el.depth - that.provider.depth),
	            ])
	          })
	        })
	        .catch(err => console.dir(err))
      },
	},
	created () {
		if (this.provider && !this.initRequest) {
			this.getReferrals()
			this.initRequest = true
		}
	},
	watch: {
		provider: function(provider) {
			if (this.provider && !this.initRequest) {
				this.getReferrals()
				this.initRequest = true
			}
		}
	}
}
</script>

<style scoped>
.block {
    width: 100%;
}

.referral-link {
  color: #ffffff;
  margin-left: 10px;
}

.referral-link-box {
  padding: 2rem;
}

.referral-link-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.referral-link-box .hdr {
  display: flex;
    align-items: center;
    margin-left: 0.75rem;
    margin-bottom: 0.5rem;
}

.referral-link-box .btn {
  padding: 1rem 1.75rem;
    margin: 0.5rem;
    background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
    border-radius: 6px;
    line-height: 1.1;
    margin-left: 20px;
}

.referral-link-box .hdr .img {
  width: 1.75rem;
      height: 1.75rem;
      margin-right: 0.5rem;
}

.referrals-table {
  margin-top: 20px;
}
</style>