import { render, staticRenderFns } from "./DeleteModal.vue?vue&type=template&id=f88d029c&scoped=true&"
import script from "./DeleteModal.vue?vue&type=script&lang=js&"
export * from "./DeleteModal.vue?vue&type=script&lang=js&"
import style0 from "./DeleteModal.vue?vue&type=style&index=0&id=f88d029c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f88d029c",
  null
  
)

export default component.exports