<template>
  <overlay-scrollbars class="page page-personal" ref="os">
    <div class="box-container">
      <div class="transparent-box box_b-info">
        <h2 class="ttl">
          Tasks
        </h2>
        <div class="content">
          <div class="row person-info">
            <div>
              <div @click="createTask" class="btn create-account-btn">Create</div>
            </div>
            <div class="card">
              <div class="data">
                <div class="ttl">Deadline broked</div>
                <div class="desc" :class="{'breaked-deadline': breakedDeadline > 0}">
                  <span>
                    Count: {{ breakedDeadline }}
                  </span>
                  <span v-if="breakedDeadline > 0">
                    <Tmblr 
                      :active="showBreakedDeadline" 
                      :text="'Show'" 
                      @changeState="filterTasks" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row person-info filter">
            <div class="col">
              <input 
                name="search_query" 
                v-model="search_query" 
                class="inpt" 
                placeholder="Enter to search" 
                @change="getTasks"
                @keyup.enter="getTasks" 
              />
            </div>
            <div class="col">
              <select name="search_status" class="inpt" v-model="search_status" @change="getTasks">
                <option :value="null">Status</option>
                <option 
                  v-for="(status, i) in statuses" 
                  :value="status"
                >
                  {{ status }}
                </option>
              </select>
            </div>
            <div class="col">
              <select name="search_manager" class="inpt" v-model="search_manager" @change="getTasks">
                <option :value="null">Responsible manager</option>
                <option 
                  v-for="(manager, i) in managers" 
                  :value="manager.name"
                >
                  {{ manager.name }}
                </option>
              </select>
            </div>
            <div class="col">
              <select name="search_control_manager" class="inpt" v-model="search_control_manager" @change="getTasks">
                <option :value="null">Control manager</option>
                <option 
                  v-for="(manager, i) in managers" 
                  :value="manager.name"
                >
                  {{ manager.name }}
                </option>
              </select>
            </div>
            <div class="col">
              <div class="btn" @click="getTasks">
                Search
              </div>
            </div>
          </div>

          <div class="block">
            <Table
                @editItem="editItem"
                @deleteItem="deleteItem"
                @showComments="showComments"
                @toggleProperty="toggleProperty"
                :key="tasksTableKey"
                :options="tasksTableData"
                @change-page="getTasks"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="box-container">
      <div class="transparent-box box_b-info">
        <h2 class="ttl">
          Processes
        </h2>
        <br />
        <div class="content">
          <div class="row person-info">
            <div>
              <div @click="createProcess" class="btn create-account-btn">Create</div>
            </div>
          </div>
          <br />
          <div class="row person-info filter">
            <div class="col">
              <input 
                name="process_search_query" 
                v-model="process_search_query" 
                class="inpt" 
                placeholder="Enter to search" 
                @change="getProcesses"
                @keyup.enter="getProcesses" 
              />
            </div>
            <div class="col">
              <select name="search_manager" class="inpt" v-model="process_search_manager" @change="getProcesses">
                <option :value="null">Select manager</option>
                <option 
                  v-for="(manager, i) in managers" 
                  :value="manager.name"
                >
                  {{ manager.name }}
                </option>
              </select>
            </div>
            <div class="col">
              <div class="btn" @click="getTasks">
                Search
              </div>
            </div>
          </div>
    
          <div class="block">
            <Table
                @editItem="editProcessItem"
                @deleteItem="deleteProcessItem"
                @showProcessSteps="showProcessSteps"
                @toggleProperty="toggleProcessProperty"
                :key="processesTableKey"
                :options="processesTableData"
                @change-page="getProcesses"
            />
          </div>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>
  
<script>
  import Pen from '../components/img/Pen.vue'
  import User from '../components/img/User.vue'
  import Table from '../components/elem/Table.vue'
  import Pagination from 'laravel-vue-pagination'
  import Tmblr from '../components/elem/Tmblr.vue'
  
  export default {
    components: {
      Pen,
      User,
      Pagination,
      Table,
      Tmblr
    },
    data(){
        return {
            managers: [],
            tasksTableKey: 0,
            tasksTableData: {
                objName: 'tasks',
                colsWidth: ['5%', '7.5%', '7.5%', '7.5%', '7.5%', '7.5%', '7.5%', '7.5%', '7.5%', '7.5%', '7.5%', '7.5%', '7.5%', '5%'],
                header: [
                  { ttl: '#', info: null },
                  { ttl: 'Task', info: null },
                  { ttl: 'Client Name', info: null },
                  { ttl: 'Priority', info: null },
                  { ttl: 'Date of task', info: null},
                  { ttl: 'Task description', info: null},
                  { ttl: 'Status', info: null },
                  { ttl: 'Links', info: null },
                  { ttl: 'Responsible manager', info: null },
                  { ttl: 'Control Manager', info: null },
                  { ttl: 'Assistant', info: null },
                  { ttl: 'Closed at', info: null },
                  { ttl: 'Deadline at', info: null },
                  { ttl: '', info: null },
                ],
                body: [],
                paginationData: null,
                sort: {
                    name: '',
                    order: 'desc',
                    loading: false
                }
            },
            breakedDeadline: false,
            showBreakedDeadline: false,
            statuses: [
              'Active',
              'In Progress',
              'On Hold',
              'Close Win',
              'Close Lost'
            ],
            search_query: null,
            search_status: null,
            search_manager: null,
            search_control_manager: null,
            
            processesTableKey: 0,
            processesTableData: {
                objName: 'processes',
                colsWidth: ['10%', '35%', '15%', '15%', '15%', '10%'],
                header: [
                  { ttl: '#', info: null },
                  { ttl: 'Process', info: null },
                  { ttl: 'Document manager', info: null },
                  { ttl: 'Links', info: null},
                  { ttl: 'Steps', info: null},
                  { ttl: '', info: null },
                ],
                body: [],
                paginationData: null,
                sort: {
                    name: '',
                    order: 'desc',
                    loading: false
                }
            },
            process_search_query: null,
            process_search_manager: null,
        }
    },
    async created () {
      this.getTasks()
      this.getProcesses()
      await this.getManagers()
    },
    methods: {
      async getManagers () {
        const that = this
        await axios.get('admin/data/managers')
          .then(function (response) {
            that.managers = response.data
          })
          .catch(function (error) {
            console.log(error);
          })
      },
      filterTasks(){
        this.showBreakedDeadline = !this.showBreakedDeadline

        this.getTasks()
      },
      getTasks(page = 1){
        const that = this

        const basePath = process.env.VUE_APP_BACKEND_API_URL + 'admin/tasks?page=' + page + '&per_page=15'

        const sortParams = '&sort=' + this.tasksTableData.sort.name + '&order=' + this.tasksTableData.sort.order

        let filterParams = ''

        filterParams = this.showBreakedDeadline ? '&breaked_deadline=true' : ''

        filterParams += this.search_query ? '&search=' + this.search_query : ''
        
        filterParams += this.search_status ? '&status=' + this.search_status : ''

        filterParams += this.search_manager ? '&manager=' + this.search_manager : ''
        
        filterParams += this.search_control_manager ? '&control_manager=' + this.search_control_manager : ''

        axios.get(basePath + sortParams + filterParams)
          .then(function (response) {
            that.tasksTableData.paginationData = response.data.data

            that.tasksTableData.body = []

            that.breakedDeadline = response.data.breaked_deadline

            response.data.data.data.forEach(el => {
              that.tasksTableData.body.push([
                el.id,
                el.task,
                el.client_name,
                {type: 'priority', value: el.priority},
                that.getFormattedDate(el.tasked_at),
                el.task_description,
                {type: 'task-status', value: el.status,},
                {type: 'links', value: that.getLinksContent(el.task_links),},
                el.responsible_manager_name,
                el.control_manager_name,
                el.asystent_name,
                that.getFormattedDate(el.closed_at),
                {type: 'deadline_lite', ...that.getDeadlinePeriod(el.deadline_at)},
                { type: 'edit-delete-super-comments-toggle-can-actions', id: el.id, is_manager_can: el.is_manager_can }, 
              ])
            })

            if(that.tasksTableData.sort.name) that.tasksTableData.sort.loading = false
          })
          .catch((errors) => {
            console.dir(errors)
          })
      },
      getProcesses(page = 1){
        const that = this
      
        const basePath = process.env.VUE_APP_BACKEND_API_URL + 'admin/processes?page=' + page + '&per_page=15'
      
        let filterParams = ''
      
        filterParams += this.process_search_query ? '&search=' + this.process_search_query : ''
      
        filterParams += this.process_search_manager ? '&manager=' + this.process_search_manager : ''
      
        axios.get(basePath + filterParams)
          .then(function (response) {
            that.processesTableData.paginationData = response.data.data
      
            that.processesTableData.body = []
      
            response.data.data.data.forEach(el => {
              that.processesTableData.body.push([
                el.id,
                el.process,
                el.manager_name,
                {type: 'links', value: that.getLinksContent(el.process_links),},
                {type: 'show-process-steps', value: el.id},
                { type: 'edit-super-delete-super-toggle-can-actions', id: el.id, is_manager_can: el.is_manager_can }, 
              ])
            })
      
            if(that.processesTableData.sort.name) that.processesTableData.sort.loading = false
          })
          .catch((errors) => {
            console.dir(errors)
          })
      },
      getFormattedDate(value){
        if(value){
          const date = new Date(value);
          
          const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          });
          
          return formattedDate;
        }
      },
      getDeadlinePeriod(date){
        if(date != null){
          const now = new Date();
          const deadlineDate = new Date(date);

          const difference = deadlineDate - now;

          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

          return { days, hours };
        }
      },
      getLinksContent(arr){
        let links = []
        
        arr.forEach((item, i) => {
            links.push({
              'name': item.service_name,
              'link': item.link
            });
        })

        return links
      },
      deleteItem(obj){
        this.$store.commit('setModal', {
          template: 'delete',
          options: {
            id: obj.id,
            table: 'tasks',
            type: 'task',
            url: 'admin/tasks/' + obj.id,
            successfullMessage: 'You successfully deleted task',
          }
        })
      },
      editItem(obj){
        this.$store.commit('setModal', {
          template: 'task',
          options: {
            form: obj,
            id: obj.id,
            actionType: 'edit',
            table: 'tasks',
            objectType: 'task',
            successfullMessage: 'You successfully updated task',
          }
        })
      },
      showComments(obj){
        this.$store.commit('setModal', {
          template: 'task-comments',
          options: {
            form: obj,
            id: obj.id,
            actionType: 'show-comments',
            table: 'tasks',
            objectType: 'task',
            successfullMessage: 'You successfully add comments',
          }
        })
      },
      createTask(){
        this.$store.commit('setModal', {
          template: 'task',
          options: {
            actionType: 'create',
            table: 'tasks',
            objectType: 'task',
            successfullMessage: 'You successfully create task',
          }
        })
      },
      deleteProcessItem(obj){
        this.$store.commit('setModal', {
          template: 'delete',
          options: {
            id: obj.id,
            table: 'processes',
            type: 'process',
            url: 'admin/processes/' + obj.id,
            successfullMessage: 'You successfully deleted task',
          }
        })
      },
      editProcessItem(obj){
        this.$store.commit('setModal', {
          template: 'process',
          options: {
            form: obj,
            id: obj.id,
            actionType: 'edit',
            table: 'processes',
            objectType: 'process',
            successfullMessage: 'You successfully updated task',
          }
        })
      },
      showProcessSteps(obj){
        this.$store.commit('setModal', {
          template: 'process-steps',
          options: {
            form: obj,
            id: obj.id,
            actionType: 'show-steps',
            table: 'processes',
            objectType: 'process'
          }
        })
      },
      createProcess(){
        this.$store.commit('setModal', {
          template: 'process',
          options: {
            actionType: 'create',
            table: 'processes',
            objectType: 'process',
            successfullMessage: 'You successfully create task',
          }
        })
      },
      toggleProperty(obj){
        if(obj.id){
          let urlPart = '';
          
          if(obj.property == 'is_manager_can'){
            urlPart = 'toggle-can';
          }
          
          let that = this
          
          axios.post('admin/tasks/' + obj.id + '/' + urlPart)
          .then(function (response) {
            let taskPage = that.tasksTableData.paginationData.current_page
            
            that.getTasks(taskPage)
          })
          .catch(function (error) {
            console.log(error);
          })
        }
      },
      toggleProcessProperty(obj){
        if(obj.id){
          let urlPart = '';
          
          if(obj.property == 'is_manager_can'){
            urlPart = 'toggle-can';
          }
          
          let that = this
          
          axios.post('admin/processes/' + obj.id + '/' + urlPart)
          .then(function (response) {
            let processPage = that.processesTableData.paginationData.current_page
            
            that.getProcesses(processPage)
          })
          .catch(function (error) {
            console.log(error);
          })
        }
      },
    },
    watch: {
      '$store.state.deleteItemFromTableData': function(data) {
        let taskPage = this.tasksTableData.paginationData.current_page
        let processPage = this.processesTableData.paginationData.current_page

        this.getTasks(taskPage)
        this.getProcesses(processPage)
      },
      '$store.state.editItemFromTableData': function(data) {
        let taskPage = this.tasksTableData.paginationData.current_page
        let processPage = this.processesTableData.paginationData.current_page

        this.getTasks(taskPage)
        this.getProcesses(processPage)
      },
      '$store.state.createItemInTable': function(data) {
        let taskPage = this.tasksTableData.paginationData.current_page
        let processPage = this.processesTableData.paginationData.current_page

        this.getTasks(taskPage)
        this.getProcesses(processPage)
      },
      '$store.state.user': function(data){
        let taskPage = this.tasksTableData.paginationData.current_page
        let processPage = this.processesTableData.paginationData.current_page

        this.getTasks(taskPage)
        this.getProcesses(processPage)
      }
    }
  }
</script>
  
<style lang="scss" scoped>
  .page-personal {
    .box {
      &-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }
  
      &_rate {
        position: relative;
        height: 7rem;
        height: 11vh;
        margin-top: 6rem;
        margin-top: 7vh;
        margin-bottom: 4rem;
        margin-bottom: 6vh;
  
        .box-inside {
          width: 95%;
          height: 11rem;
          height: calc(100% + 6vh);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: linear-gradient(113.24deg, #1763fb 16.01%, #1763fb 106.71%);
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          border-radius: 14px;
          display: flex;
  
          .content {
            flex-grow: 1;
  
            .scale-box {
              width: 100%;
            }
          }
  
          .img {
            height: 14rem;
            height: calc(100% + 6vh);
            position: relative;
            top: 100%;
            transform: translateY(-96%);
            margin: auto 3rem;
            margin: auto 3vw;
          }
        }
      }
  
      &_my-profile {
        position: relative;
        width: 100%;
        padding-right: 4.25rem;
  
        .header {
          display: flex;
  
          .title {
            flex-grow: 1;
          }
  
          .tmblr {
            display: flex;
            align-items: center;
            padding: 0 .25rem;
  
            .txt {
              color: rgba(255, 255, 255, 0.5);
            }
  
            .btn {
              &-tmblr {
                position: relative;
                width: 1.5rem;
                height: .5rem;
                background-color: rgba(255, 255, 255, 0.12);
                border-radius: 2rem;
                margin: auto 1rem;
  
                &-l::before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translate(-50%, -50%);
                  width: 1rem;
                  height: 1rem;
                  border-radius: 50%;
                  background-color: #ffffff;
                }
  
                &-r::before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  right: 0;
                  transform: translate(50%, -50%);
                  width: 1rem;
                  height: 1rem;
                  border-radius: 50%;
                  background-color: #ffffff;
                }
              }
            }
  
            &-hide {
              .txt {
                color: #1763fb;
              }
  
              .btn-tmblr-r::before {
                background-color:  #1763fb;
              }
            }
          }
        }
  
        .content {
          padding: 0 0 1rem 0;
          padding: 0 0 1.5vh 0;
          display: flex;
  
          &.blur {
            filter: blur(7px);
          }
  
          .card {
            &-big {
              width: 100%;
              height: 11.5rem;
              display: flex;
              align-items: flex-end;
              background-image: url('/img/two_line.png');
              background-position: 0 0;
              background-repeat: no-repeat;
              background-size: contain;
              padding: 1.25rem 1.75rem;
  
              svg.img {
                width: 3.5rem;
                height: 4rem;
                margin-right: 1rem;
              }
  
              .data {
                flex-grow: 1;
  
                .ttl {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.5);
                  padding-bottom: .25rem;
                }
  
                .desc {
                  font-size: 1.25rem;
                  font-weight: 600;
                }
              }
            }
          }
  
          .col {
            &-1 {
              width: 27rem;
              width: 21vw;
              padding-right: .5rem;
            }
  
            &-2 {
              flex-grow: 1;
              padding-left: .5rem;
              padding-right: .5rem;
              display: flex;
  
              .subcol {
                padding: 0 .5rem;
  
                &-1 {
                  width: 30%;
                }
  
                &-2,
                &-3 {
                  width: 35%;
                }
              }
            }
          }
        }
      }
  
      &_b-info {
        flex-grow: 1;
        position: relative;
        // background-color: #272E35;
        margin-right: 2rem;
  
        .person-info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 12px;
          &.filter{
            margin-bottom: 12px;
            justify-content: flex-start !important;
          }
        }
  
        .header {
          padding-right: 4.25rem;
  
          .title {
            &::before {
              background-color: rgb(255 255 255 / 25%);
            }
          }
        }
  
        .content {
          .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
  
            .card {
              background: rgba(23, 99, 251, 0.1);
              width: 30%;
  
              &-min {
                width: 20%;
              }
            }
          }
        }
      }
  
      &_comp-logo {
        width: 23rem;
        position: relative;
        background-color: #272E35;
        overflow: hidden;
        padding-bottom: 1rem;
  
        .dots {
          position: absolute;
  
          &-top {
            top: -5px;
            right: 7px;
            width: 2rem;
            opacity: .5;
          }
  
          &-left {
            top: 6.5rem;
            left: 28px;
            width: 1.5rem;
            opacity: .5;
          }
  
          &-bottom {
            bottom: -12px;
            right: 1.75rem;
            width: 2.5rem;
            transform: rotate(90deg);
            opacity: 0.5;
          }
        }
  
        .header {
          .title {
            &::before {
              background-color: rgb(255 255 255 / 25%);
            }
          }
        }
  
        .logo {
          flex-grow: 1;
          width: 14rem;
          height: 14rem;
          margin: .25rem auto;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 50%;
          display: flex;
          align-items: flex-end;
          justify-content: space-around;
          position: relative;
          z-index: 1;
  
          .btn {
            width: 3.25rem;
            height: 3.25rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
  
            &_update {
              background-color: #1763fb;
  
              svg {
                width: 1.75rem;
                height: 1.75rem;
              }
            }
  
            &_remove {
              background-color: #B03636;
  
              svg {
                width: 1.25rem;
                height: 1.25rem;
              }
            }
          }
        }
      }
    }
  }
  
  .scale {
    position: relative;
    width: 100%;
    height: 1rem;
    margin: 1rem 0 3.5rem 0;
    border-radius: 3rem;
    background-color: rgba(255, 255, 255, 0.12);
  
    .progress {
      position: relative;
      height: 100%;
      border-radius: 3rem;
      background-color: #ffffff;
      background-image: url('/img/rate_el.png');
      background-position: 0 0;
      background-repeat: repeat-x;
      background-size: auto 100%;
  
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 5rem;
        background-color: #E2F4EC;
        background-image: url('/img/smile.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 65%;
      }
  
      .note {
        position: absolute;
        bottom: -3.25rem;
        right: 0;
        transform: translateX(50%);
        background-color: #094F4D;
        color: #ffffff;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 7px 1rem 6px;
        border-radius: 6px;
  
        &::before {
          content: '';
          position: absolute;
          top: -4px;
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
          width: .75rem;
          height: .75rem;
          background-color: #094F4D;
          border-radius: 2px;
        }
      }
    }
  }
  .btn{
    border-radius: 0.5rem; 
    padding: 12px 24px; 
    border: 1px solid #1763fb;
    background: #1763fb;
    display: inline-block;
  }
  .btn {
    &-edit {
      width: 2.75rem;
      height: 2.75rem;
      position: absolute;
      top: .75rem;
      right: .75rem;
      background: #22252B;
      border-radius: 6px;
      padding: .75rem;
      display: flex;
      align-items: center;
      justify-content: center;
  
      svg.img {
        width: 1rem;
        height: 1rem;
      }
    }
    &-save {
      width: 3.75rem;
      height: 2.75rem;
      position: absolute;
      top: .75rem;
      right: .75rem;
      background: #22252B;
      border-radius: 6px;
      padding: .75rem 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
  
      svg.img {
        width: 1rem;
        height: 1rem;
      }
    }
  }
  
  .form-control {
    width: 100%;
    height: 100%;
    color: #ffffff;
    background-color: #1C2126;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin: auto 0rem;
    border: 1px solid #2F363D;
    border-radius: .75rem;
    box-shadow: 0px 11px 23px rgba(0, 0, 0, 0.02);
    outline: none;
    -webkit-appearance: none;
  
    &::placeholder {
      color: #B9B9B9;
    }
  }
  
  .invalid-feedback {
      width: 100%;
      margin-top: .25rem;
      font-size: 80%;
      color: #dc3545;
  }
  
  .transparent-box {
    width: 100%;
    border-radius: 14px;
    margin: 1rem 0;
  }
  
  .excel-btn-wrapper {
    position: relative;
    height: 67px;
  }
  
  .excel-btn {
    border-radius: 0.5rem; 
    padding: 10px; 
    margin-left: 30px; 
    border: 1px solid #1763fb;
    display: inline-block;
    position:absolute;
    right: 15px;
    top: 0;
  }
  
  .create-account-btn {
    border-radius: 1rem;
    padding: 10px;
    background-color: #1763fb;
  }
  .breaked-deadline{
    color: red;
    display: flex;
    justify-content: space-between;
  }
  </style>
  